import { makeAutoObservable } from 'mobx';

export default class CacheStore {
    cacheTimestamp: { [key: string]: number | null } = {};

    constructor() {
        makeAutoObservable(this);
    }

    isCacheExpired = (key: string, cacheExpirationInSeconds: number) => {
        const now = new Date().getTime();
        if (!this.cacheTimestamp[key]) {
            this.cacheTimestamp[key] = now;
            return true;
        }

        return now - this.cacheTimestamp[key]! > cacheExpirationInSeconds * 1000;
    };

    updateCacheTimestamp = (key: string) => {
        this.cacheTimestamp[key] = new Date().getTime();
    };
}
