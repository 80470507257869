/**
 * Returns an abbreviated/concatinated string with the first
 * characters of each string
 * @param { string } fullName
 * @returns { string }
 */
export const getNameInitials = (fullName: string) => {
    // first extract the userid from the email if the fullname is an email
    fullName = getUserIdFromEmail(fullName).toUpperCase();

    // no need to iterate if the string is only 1 character or less
    if (fullName.length < 2) {
        // default to "Oddr User" if empty
        return fullName ? fullName : 'OU';
    }

    // the preferred order of splitting
    const charToSplit = ' .,_-+';

    for (let i = 0; i < charToSplit.length; i++) {
        const splitArray = fullName.split(charToSplit.charAt(i));
        if (splitArray.length > 1) {
            return splitArray.map((name) => name[0]).join('');
        }
    }

    // no special characters, just append the first two chars
    return fullName[0] + fullName[1];
};

export const getUserIdFromEmail = (email: string) => {
    const atIndex = email.indexOf('@');
    return atIndex !== -1 ? email.substring(0, atIndex) : email;
};

export const getAvatarColorHue = (fullName: string) => {
    let hash = 0;

    for (let i = 0; i < fullName.length; i++) {
        hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hash % 360; // get a 0 - 360 code that represents a unique color on the color wheel
};

export const getAvatarColor = (hue: number) => {
    const saturation = 30; // 0 = gray, 100 = color
    const lightness = 25; // 0 = black, 100 = white
    return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
};

export const getAvatarBgColor = (hue: number) => {
    const saturation = 30; // 0 = gray, 100 = color
    const lightness = 85; // 0 = black, 100 = white
    return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
};

/**
 * Tests whether the user is on a mobile browser
 * @returns { boolean }
 */
export const isUserAgentOnMobile = () => {
    const mobileAgentRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileAgentRegex.test(navigator.userAgent);
};
