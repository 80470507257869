import { makeAutoObservable, reaction } from 'mobx';
import agent from '../api/agent';
import { getExpirationTimestamp } from '../common/utils/datetime';
import { QueryMemo } from '../models/list/query';
import { GlobalLookup } from '../models/search';
import { OAuthTokenResponse, STORAGE_KEY, Token } from '../models/user';
import { store } from './store';

export default class CommonStore {
    token: Token | null = JSON.parse(localStorage.getItem(STORAGE_KEY.TOKEN) ?? 'null');
    appLoaded = false;
    queryMemo = new QueryMemo<GlobalLookup>();

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    localStorage.setItem(STORAGE_KEY.TOKEN, JSON.stringify(token));
                } else {
                    localStorage.removeItem(STORAGE_KEY.TOKEN);
                }
            }
        );
    }

    loadApp = async () => {
        // Save the user clicked link for redirection post login
        const clickedLink = location.pathname;
        const linksNotToBeRedirectedPostLogin = /login|sso/;
        if (!linksNotToBeRedirectedPostLogin.test(clickedLink)) {
            store.userStore.setRedirectUrl(clickedLink);
        }

        try {
            await store.tenantStore.loadTenant();
            if (!store.userStore.loggingIn && store.tenantStore.tenant && this.token) {
                await store.userStore.loadUser();
            }
        } catch (error) {
            console.log('Error while loading the app: ', error);
        } finally {
            this.setAppLoaded();
        }
    };

    setToken = (oAuthTokenResponse: OAuthTokenResponse | null) => {
        if (oAuthTokenResponse) {
            this.token = {
                access_token: oAuthTokenResponse.access_token,
                refresh_token: oAuthTokenResponse.refresh_token,
                access_token_expiration_ts: getExpirationTimestamp(oAuthTokenResponse.expires_in),
                refresh_token_expiration_ts: getExpirationTimestamp(
                    oAuthTokenResponse.refresh_expires_in
                )
            };
        } else {
            this.token = null;
        }
    };

    // It is possible that another tab has updated the token
    // In this case, get updated the token variable.
    storageListener = (event: StorageEvent) => {
        if (event.key === STORAGE_KEY.TOKEN) {
            if (event.newValue) {
                this.token = JSON.parse(event.newValue);
            } else {
                this.token = null;
            }
        }
    };

    setAppLoaded = () => {
        this.appLoaded = true;
    };

    globalQuerylookup = async (query: string, limit: number) => {
        try {
            const queryResults = this.queryMemo.getRecord(query);
            if (queryResults) {
                return queryResults;
            }

            const params = new URLSearchParams();
            params.append('queryText', query);
            params.append('limit', limit.toString());

            const lookupData = await agent.Search.lookup(params);
            this.queryMemo.addRecord(query, lookupData);
            return lookupData;
        } catch (error) {
            console.log(error);
            return null;
        }
    };
}
