import { EBillingStatus, Tag } from '../models/dictionary';
import { INVOICE_STATUS } from '../models/invoice';
import { KeyName } from '../models/keyValuePair';
import { requests } from './axiosAgent';

const Dictionaries = {
    BASE_URL: '/dictionaries',
    getClientTags: () => requests.get<Tag[]>(`${Dictionaries.BASE_URL}/clienttags`),
    getMatterTags: () => requests.get<Tag[]>(`${Dictionaries.BASE_URL}/mattertags`),
    getEbillingStatuses: () =>
        requests.get<EBillingStatus[]>(`${Dictionaries.BASE_URL}/ebillingstatuses`),
    createSingleEbillingStatus: (body: { name: string; invoiceStatus: INVOICE_STATUS }) =>
        requests.post<EBillingStatus>(`${Dictionaries.BASE_URL}/ebillingstatuses`, { ...body }),
    getInvoiceWriteOffReasons: () =>
        requests.get<KeyName[]>(`${Dictionaries.BASE_URL}/invoicewriteoffreasons`),
    getNoteTags: () => requests.get<Tag[]>(`${Dictionaries.BASE_URL}/notetags`),
    getOffices: () => requests.get<KeyName[]>(`${Dictionaries.BASE_URL}/offices`),
    getClientStatuses: () => requests.get<KeyName[]>(`${Dictionaries.BASE_URL}/clientstatuses`)
};

export default Dictionaries;
