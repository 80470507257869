import { CaretRightOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, DatePicker, Flex, Select, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loader from '../../../../app/common/components/Loader';
import { AppConfig } from '../../../../app/models/config/appConfig';
import { BULK_STATEMENT_SUBMISSION_WARNING } from '../../../../app/models/statement';
import { useStore } from '../../../../app/stores/store';
import useCache from '../../../shared/hooks/useCache';
import {
    getBulkMarkAsSentWarningMessage,
    getBulkSendWarningMessage
} from '../helpers/sendStatement';

const { Text } = Typography;

type ValidationData = {
    clientOrMatterIds: string[];
    bulkStatementSubmissionWarning: BULK_STATEMENT_SUBMISSION_WARNING;
};

type Props = {
    markAsSent: boolean;
    onValidation: (month: string) => Promise<ValidationData>;
    onSend: (month: string, clientOrMatterIds: string[], sentOn: string | null) => Promise<void>;
};

export default observer(function BulkStatementSubmissionModalBody(props: Props) {
    const { markAsSent, onValidation, onSend } = props;
    const {
        modalStore: { closeModal }
    } = useStore();
    const validationDataCache = useCache<ValidationData>();
    const [validatedIds, setValidatedIds] = useState<string[]>();
    const selectedStatementMonthRef = useRef<string | null>(null);
    const [warning, setWarning] = useState<BULK_STATEMENT_SUBMISSION_WARNING | null>(null);
    const [canSend, setCanSend] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [sentOn, setSentOn] = useState<Dayjs | null>(markAsSent ? dayjs() : null);

    const setWarningState = useCallback(
        (bulkStatementSubmissionWarning: BULK_STATEMENT_SUBMISSION_WARNING) => {
            setCanSend(
                bulkStatementSubmissionWarning === BULK_STATEMENT_SUBMISSION_WARNING.NO_WARNING ||
                    bulkStatementSubmissionWarning ===
                        BULK_STATEMENT_SUBMISSION_WARNING.CAN_SUBMIT_SOME_STATEMENTS
            );
            setWarning(
                bulkStatementSubmissionWarning === BULK_STATEMENT_SUBMISSION_WARNING.NO_WARNING
                    ? null
                    : bulkStatementSubmissionWarning
            );
        },
        []
    );

    // Memoizing the statementMOnths, since the value will never change
    const statementMonths = useMemo(() => {
        const currentMonth = dayjs().startOf('month');
        const current = {
            value: currentMonth.format('YYYY-MM-01'),
            label: currentMonth.format('MMM YYYY')
        };

        const prevMonth = currentMonth.subtract(1, 'month');
        const prev = {
            value: prevMonth.format('YYYY-MM-01'),
            label: prevMonth.format('MMM YYYY')
        };

        return { prev, current };
    }, []);

    const handleValidation = async () => {
        if (!selectedStatementMonthRef.current) {
            return;
        }
        let validationData = validationDataCache.get(selectedStatementMonthRef.current);
        if (!validationData) {
            setLoading(true);
            const validationData_ = await onValidation(selectedStatementMonthRef.current);
            validationData = validationData_;
            validationDataCache.add(selectedStatementMonthRef.current, validationData);
        }
        setLoading(false);
        setValidatedIds(validationData.clientOrMatterIds);
        setWarningState(validationData.bulkStatementSubmissionWarning);
    };

    useEffect(() => {
        selectedStatementMonthRef.current = statementMonths.current.value;
        handleValidation();
    }, []);

    const handleChangeMonth = async (month: string) => {
        selectedStatementMonthRef.current = month;
        handleValidation();
    };

    const handleOnOK = async () => {
        if (!selectedStatementMonthRef.current || !validatedIds) {
            return;
        }
        setLoading(true);
        await onSend(
            selectedStatementMonthRef.current,
            validatedIds,
            sentOn ? sentOn.format(AppConfig.isoDateFormat) : null
        );
        setLoading(false);
        closeModal();
    };

    const helpText = (
        <>
            To avoid sending Statements unintentionally, we automatically exclude:
            <ul>
                <li>Statements that have already been sent for the selected month</li>
                <li>Clients or Matters that are excluded from receiving Statements</li>
                <li>Clients or Matters indicated as &apos;Do Not Contact&apos;</li>
                <li>Clients or Matters that have no preloaded Statement for the selected month</li>
            </ul>
            To override, you can try sending a Statement for an individual Client or Matter.
        </>
    );

    const chooseMonthMessage = markAsSent
        ? 'Choose the month below for which you want to mark the statements as sent'
        : `Choose a month below to send preloaded statements.`;

    return (
        <Loader label='' spinning={loading}>
            <Flex vertical gap={12}>
                <Space direction='vertical'>
                    <Text>{chooseMonthMessage}</Text>
                    <Space>
                        <Select
                            defaultValue={statementMonths.current.value}
                            onChange={handleChangeMonth}
                            options={[statementMonths.prev, statementMonths.current]}
                        />
                    </Space>
                    {markAsSent && (
                        <Flex vertical gap={10}>
                            <Text>Select the date these statements were sent:</Text>
                            <Space>
                                <DatePicker
                                    defaultValue={sentOn}
                                    onChange={(_, dateString) => {
                                        if (typeof dateString === 'string') {
                                            setSentOn(dayjs(dateString));
                                        }
                                    }}
                                    style={{ width: 160 }}
                                    allowClear={false}
                                    format={AppConfig.dateMonthNameFormat}
                                    disabledDate={(current) => {
                                        const startOfSelectedMonth = dayjs(
                                            selectedStatementMonthRef.current
                                        ).startOf('month');
                                        const startOfCurrentMonth = dayjs().startOf('month');
                                        return (
                                            current.isBefore(startOfSelectedMonth) ||
                                            current.isAfter(dayjs()) ||
                                            (!current.isSame(startOfSelectedMonth, 'month') &&
                                                !current.isSame(startOfCurrentMonth, 'month'))
                                        );
                                    }}
                                />
                            </Space>
                        </Flex>
                    )}
                    {warning && (
                        <>
                            <Alert
                                type={'warning'}
                                description={
                                    markAsSent ? (
                                        <Text> {getBulkMarkAsSentWarningMessage(warning)}</Text>
                                    ) : (
                                        <Space direction='vertical'>
                                            {getBulkSendWarningMessage(warning)}
                                            <Collapse
                                                bordered={false}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    marginLeft: '-18px'
                                                }}
                                                expandIcon={({ isActive }) => (
                                                    <CaretRightOutlined
                                                        rotate={isActive ? 90 : 0}
                                                    />
                                                )}
                                                items={[
                                                    {
                                                        label: <Text strong>Learn why</Text>,
                                                        children: helpText
                                                    }
                                                ]}
                                            />
                                        </Space>
                                    )
                                }
                                banner
                                showIcon
                            />
                        </>
                    )}
                </Space>
                <Space style={{ alignSelf: 'flex-end', marginTop: 8 }}>
                    <Button onClick={closeModal}>Cancel</Button>

                    <Button
                        onClick={handleOnOK}
                        type='primary'
                        disabled={!canSend || (markAsSent && !sentOn)}
                    >
                        {markAsSent ? 'Mark As Sent' : 'Send'}
                    </Button>
                </Space>
            </Flex>
        </Loader>
    );
});
