import {
    AudioFilled,
    FileExcelFilled,
    FileFilled,
    FileImageFilled,
    FilePdfFilled,
    FilePptOutlined,
    FileTextFilled,
    FileWordFilled,
    FileZipFilled,
    VideoCameraFilled,
    MailFilled
} from '@ant-design/icons';
import mime from 'mime';

type Props = {
    fileName: string;
};

export default function FileIcon({ fileName }: Props) {
    const mimeType = mime.getType(fileName);
    if (mimeType) {
        switch (mimeType) {
            case 'application/pdf':
                return <FilePdfFilled />;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <FileWordFilled />;
            case 'text/csv':
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <FileExcelFilled />;
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.ms-powerpoint':
                return <FilePptOutlined />;
            case 'application/rtf':
                return <FileTextFilled />;
            case 'application/gzip':
            case 'application/zip':
            case 'application/x-7z-compressed':
            case 'application/x-bzip2':
            case 'application/x-bzip':
            case 'application/x-tar':
                return <FileZipFilled />;
            case 'message/rfc822':
            case 'message/x-emlx':
            case 'application/x-eml':
            case 'application/vnd.ms-outlook':
                return <MailFilled />;
            default:
                if (mimeType.startsWith('image/')) {
                    return <FileImageFilled />;
                }
                if (mimeType.startsWith('video/')) {
                    return <VideoCameraFilled />;
                }
                if (mimeType.startsWith('audio/')) {
                    return <AudioFilled />;
                }
                if (mimeType.startsWith('text/')) {
                    return <FileTextFilled />;
                }
        }
    }
    return <FileFilled />;
}
