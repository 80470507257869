export const downloadDocumentBlob = (
    documentBlob: Blob,
    documentName: string,
    openInNewTab?: boolean
) => {
    const documentBlobURL =
        typeof documentBlob === 'string' ? documentBlob : URL.createObjectURL(documentBlob);
    const documentBlobLink = document.createElement('a');
    documentBlobLink.href = documentBlobURL;
    if (!openInNewTab) {
        documentBlobLink.download = documentName;
    } else {
        documentBlobLink.target = '_blank';
        documentBlobLink.rel = 'noopener noreferrer';
    }
    documentBlobLink.click();
    setTimeout(() => {
        documentBlobLink.remove();
    }, 1000);
};

export const printDocumentBlob = (
    printableDocument: Blob | string,
    afterPrint?: (() => Promise<void>) | (() => void)
) => {
    let documentBlobURL = '';
    if (typeof printableDocument === 'string') {
        documentBlobURL = printableDocument;
    } else {
        documentBlobURL = URL.createObjectURL(
            new Blob([printableDocument!], { type: 'application/pdf' })
        );
    }
    let iframe = document.getElementById('oddr-pdf-iframe') as HTMLIFrameElement;

    if (iframe) {
        iframe.remove();
    }

    iframe = document.createElement('iframe') as HTMLIFrameElement;
    iframe.setAttribute('id', 'oddr-pdf-iframe');

    // This is important for Android Chrome
    if (iframe.hasAttribute('sandbox')) {
        iframe.setAttribute('sandbox', 'allow-modals');
    }
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = documentBlobURL;
    iframe.onload = function () {
        const { contentWindow } = iframe;
        if (contentWindow) {
            iframe.focus();
            setTimeout(function () {
                iframe.contentWindow!.print();
            }, 1000);
        }
    };
    if (!afterPrint) {
        return;
    }
    const afterPrintHandler = () => {
        // This will remove the previously added handler
        window.removeEventListener('focus', afterPrintHandler);
        afterPrint();
    };
    window.addEventListener('focus', afterPrintHandler);
};

export const getTruncatedFileName = (fileName: string, maxLength: number) => {
    const [name, extension] = fileName.split('.');
    let truncatedFileName = name;
    if (name.length > maxLength) {
        truncatedFileName = name.substring(0, maxLength - 6);
        truncatedFileName += '...';
        truncatedFileName += name.substring(name.length - 3);
    }
    return extension ? [truncatedFileName, extension].join('.') : truncatedFileName;
};

export const getExtension = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');

    // If there's no dot in the filename or it's the first character or if it's the last character, return an empty string
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return '';
    }

    return fileName.slice(lastDotIndex + 1);
};
