import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
    children?: JSX.Element;
    spinning?: boolean;
    label?: string;
    size?: 'small' | 'default' | 'large';
};

export default function Loader({
    children,
    spinning = false,
    label = 'Loading...',
    size = 'default'
}: Props) {
    return (
        <Spin spinning={spinning} indicator={<LoadingOutlined spin />} tip={label} size={size}>
            {children ?? <div style={{ height: '100%', width: '100%' }} />}
        </Spin>
    );
}
