import { Row, Col, Space, Typography } from 'antd';
import { ReactNode } from 'react';
import './styles.css';
import Loader from '../../app/common/components/Loader';
import TooltipIcon from '../../app/common/components/TooltipIcon';

const { Text } = Typography;

type Props = {
    title: ReactNode;
    infoTooltip?: string | ReactNode;
    actions?: ReactNode;
    children?: JSX.Element;
    childrenPadding?: string;
    showScrollBar?: boolean;
    loading?: boolean;
};

export default function MenuLayout(props: Props) {
    const {
        title,
        infoTooltip,
        actions,
        children,
        childrenPadding = '8px',
        loading = false,
        showScrollBar = true
    } = props;
    return (
        <Row justify='center' className='menu-layout'>
            <Col span={24} className='menu-layout__header'>
                <Row justify='space-between' style={{ width: '100%' }} align='middle'>
                    <Space>
                        {typeof title === 'string' ? <Text strong>{title}</Text> : title}
                        {infoTooltip && <TooltipIcon title={infoTooltip} />}
                    </Space>
                    {actions}
                </Row>
            </Col>
            <Col
                span={24}
                className='menu-layout__container modified-scroll-bar'
                style={{ padding: childrenPadding, overflow: showScrollBar ? 'auto' : 'hidden' }}
            >
                <Loader spinning={loading} label=''>
                    {children}
                </Loader>
            </Col>
        </Row>
    );
}
