import { BaseAxisProps } from 'recharts/types/util/types';
import { INVOICE_STATUS, INVOICE_SUB_GROUP_STATUS, INVOICE_TYPE } from './invoice';

export interface AmountAndCountByType {
    amount: number;
    count: number;
    invoiceType: string;
    invoiceTypeName: string;
}

export type PendingInvoiceByType = AmountAndCountByType;

export interface PendingInvoicesByBiller {
    amount: number;
    count: number;
    displayName: string;
    firstName: string;
    lastName: string;
    userId: string;
    valuesByType: AmountAndCountByType[];
}

export interface ARByType {
    amount: number;
    count: number;
    invoiceType: INVOICE_TYPE;
    invoiceTypeName: string;
    byStatus: {
        amount: number;
        count: number;
        invoiceStatusName: string;
        invoiceStatus: INVOICE_STATUS;
    }[];
}
export interface ARTotal {
    amount: number;
    count: number;
    byType: ARByType[];
}

export interface TooltipComponentDisplayFieldProps {
    id: string;
    title?: string;
    titleField?: string;
    totalCount?: number;
    fields: {
        label: string;
        dataKey: string;
        formatter?: (value: any) => string;
    }[];
}

export interface ChartLegendProps {
    fill: string;
    label: string;
}

export interface LabelComponentProps {
    label: string;
    color: string;
    fontColor?: string;
    size?: 'small' | 'default' | 'large';
}

export interface BarChartYAxisProps {
    dataKey: string;
    label?: string;
    fill: string;
    // stackId?: string;
    barId: string;
    barSize?: number;
    barRadius?: BarChartRadius;
    yAxisId?: string;
    orientation?: 'left' | 'right';
    width?: number;
    tickFormatter?: BaseAxisProps['tickFormatter'];
}

export interface BarChartXAxisProps {
    dataKey: string;
    label?: string;
    tickFormatter?: BaseAxisProps['tickFormatter'];
    xAxisLine?: boolean;
    xAxisId?: string;
}

export type BarChartRadius = [number, number, number, number];

export interface MonthlyInvoiceOrPayment {
    amount: number;
    count: number;
    month: number;
    monthName: string;
    year: number;
}

// Pie Chart

export interface PieProps {
    dataKey: string;
    nameKey: string;
    innerRadius?: number;
    outerRadius?: number;
    labelColor?: string;
    labelFontSize?: string;
}

export interface PieCenterTextProps {
    text: string;
    subText?: string;
    subTextFontSize?: string;
    fontSize?: string;
    color?: string;
    innerCircleColor?: string;
}

export interface PieTooltipDisplayFields {
    title?: string;
    id: string;
    fields: {
        label: string;
        dataKey: string;
    }[];
}

export interface AgedAR {
    startsOn: string;
    timePeriod: string;
    byType: AmountAndCountByType[];
}

export interface CurrentMonthBillerSubmissions {
    totalAmount: number;
    totalCount: number;
    submissionAmount: number;
    submissionCount: number;
    displayName: string;
    firstName: string;
    lastName: string;
    userId: string;
    byType: {
        totalAmount: number;
        totalCount: number;
        submissionAmount: number;
        submissionCount: number;
        invoiceType: string;
        invoiceTypeName: string;
    }[];
}

export interface ClientMetrics {
    ar31To60: number;
    ar61To90: number;
    ar91To120: number;
    ar0To30: number;
    ar121Plus: number;
    arOverdue: number;
    arTotal: number;
    avgDso: number;
    avgDaysToView: number | null;
    clientName: string;
    clientId: string;
}

export interface InvoiceMetricsByYear {
    avgDso: number;
    writeOffsAmount: number;
    year: number;
}

export interface InvoiceMetricsTotals {
    arAmount: number;
    arCount: number;
    outstandingAmount: number;
    outstandingCount: number;
    pendingAmount: number;
    pendingCount: number;
    overdueCount: number;
    overdueAmount: number;
}

interface InvoiceMetrics {
    arAmount: number;
    count: number;
    invoiceAmount: number;
    paidAmount: number;
}

export interface InvoiceMetricsAndStatus {
    metrics: InvoiceMetrics;
    status: INVOICE_SUB_GROUP_STATUS;
    statusName: string;
}

export interface InvoiceMetricsByStatus {
    byStatus: InvoiceMetricsAndStatus[];
    outstanding: InvoiceMetrics;
    overdue: InvoiceMetrics;
    paid: InvoiceMetrics;
    paidOnSince: string;
    pending: InvoiceMetrics;
}

export enum CLIENT_METRICS_SORT_ORDER {
    AR_OVERDUE = 'aroverdue',
    AVG_DSO = 'avgdso'
}

// dataCacheExpireTimeInSecs time is set to 1 hour
export const dataCacheExpireTimeInSecs = 1 * 60 * 60;
