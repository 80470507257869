import { Tooltip, TooltipProps } from 'antd';
import { ReactNode } from 'react';

type Props = {
    title: string | ReactNode;
    placement?: TooltipProps['placement'];
    mouseEnterDelay?: number;
    children?: React.ReactNode;
};
export default function TooltipComponent({
    title,
    children,
    mouseEnterDelay = 0.3,
    placement = 'topLeft'
}: Props) {
    return (
        <Tooltip mouseEnterDelay={mouseEnterDelay} title={title} placement={placement}>
            {children}
        </Tooltip>
    );
}
