export class QueryResult<T> {
    items: T[];
    constructor(responseData: T[]) {
        this.items = responseData;
    }
}

export class QueryMemo<T> {
    private memo: Map<string, T> = new Map<string, T>();
    addRecord = (key: string, value: T) => {
        this.memo.set(key, value);
    };
    clear = () => this.memo.clear();
    getRecord = (key: string) => this.memo.get(key);
}
