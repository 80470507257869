import {
    ClockCircleFilled,
    ClockCircleOutlined,
    DollarCircleFilled,
    DollarCircleOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    FileTextFilled,
    FileTextOutlined,
    HomeFilled,
    HomeOutlined,
    SettingFilled,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Badge, Image, Menu, Space, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { MenuProps } from 'antd/es/menu';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_KEY } from '../../common/constants/navigationKey';
import { ClientFilter } from '../../models/client';
import { ErrorCounts } from '../../models/error';
import { InvoiceFilter } from '../../models/invoice';
import { REMINDER_FILTER } from '../../models/reminder';
import { useStore } from '../../stores/store';
import './SideBar.css';

type MenuItem = Required<MenuProps>['items'][number];

type NeedsAttentionDotProps = {
    collapsed: boolean;
    errorCounts?: ErrorCounts;
};

const getMenuItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
): MenuItem => {
    return {
        key: key,
        icon: icon,
        children,
        label,
        type,
        style: { zIndex: 10 }
    } as MenuItem;
};

const getOnlyMineFilter = (onlyMine: boolean) => (onlyMine ? 'my' : 'all');

interface Props {
    collapsed: boolean;
}

export default observer(function SideBar({ collapsed }: Props) {
    const navigate = useNavigate();

    const {
        userStore: { user },
        invoiceStore: { invoiceFilters, resetPaginationParams: resetInvoiceListPaginationParams },
        uiStore: { selectedNavigationKey, uiPreferences },
        reminderStore: { reminderFilters },
        clientStore: { clientFilters },
        errorStore: { getErrorCounts, errorCounts },
        tenantStore: { tenant }
    } = useStore();

    useEffect(() => {
        getErrorCounts();
    }, []);

    if (!user) {
        return null;
    }

    const items: MenuItem[] = [
        user.capabilities.viewDashboard
            ? getMenuItem(
                  'Dashboard',
                  NAVIGATION_KEY.Dashboard,
                  selectedNavigationKey === NAVIGATION_KEY.Dashboard ? (
                      <HomeFilled />
                  ) : (
                      <HomeOutlined />
                  )
              )
            : null,
        user.capabilities.viewInvoices
            ? getMenuItem(
                  'Invoices',
                  NAVIGATION_KEY.Invoices,
                  selectedNavigationKey.startsWith(NAVIGATION_KEY.Invoices) ? (
                      <FileTextFilled />
                  ) : (
                      <FileTextOutlined />
                  ),
                  uiPreferences.consolidateInvoiceList
                      ? [
                            getMenuItem('Receivables', NAVIGATION_KEY.InvoicesConsolidated),
                            getMenuItem('Paid', NAVIGATION_KEY.InvoicesPaid)
                        ]
                      : [
                            getMenuItem('Pending', NAVIGATION_KEY.InvoicesPending),
                            getMenuItem('Outstanding', NAVIGATION_KEY.InvoicesOutstanding),
                            getMenuItem('Paid', NAVIGATION_KEY.InvoicesPaid)
                        ]
              )
            : null,
        user.capabilities.viewClients
            ? getMenuItem(
                  'Clients',
                  NAVIGATION_KEY.Clients,
                  selectedNavigationKey === NAVIGATION_KEY.Clients ? (
                      <UserOutlined />
                  ) : (
                      <UserOutlined />
                  )
              )
            : null,
        user.capabilities.viewReminders
            ? getMenuItem(
                  'Reminders',
                  NAVIGATION_KEY.Reminders,
                  selectedNavigationKey === NAVIGATION_KEY.Reminders ? (
                      <ClockCircleFilled />
                  ) : (
                      <ClockCircleOutlined />
                  )
              )
            : null,
        user.capabilities.canViewOnlinePayments
            ? getMenuItem(
                  'Online Payments',
                  NAVIGATION_KEY.Payments,
                  selectedNavigationKey === NAVIGATION_KEY.Payments ? (
                      <DollarCircleFilled />
                  ) : (
                      <DollarCircleOutlined />
                  )
              )
            : null,
        user.capabilities.viewNeedsAttention
            ? getMenuItem(
                  <Badge
                      size='small'
                      count={errorCounts?.totalCount}
                      style={{ fontSize: '10px' }}
                      overflowCount={999}
                      offset={[7, -2]}
                  >
                      <Typography.Text style={{ color: collapsed ? 'white' : '' }}>
                          Needs Attention
                      </Typography.Text>
                  </Badge>,
                  NAVIGATION_KEY.NeedsAttention,
                  selectedNavigationKey === NAVIGATION_KEY.NeedsAttention ? (
                      <Space size={1} align='center'>
                          <ExclamationCircleFilled />
                          <NeedsAttentionDot collapsed={collapsed} errorCounts={errorCounts} />
                      </Space>
                  ) : (
                      <Space size={1} align='center'>
                          <ExclamationCircleOutlined />
                          <NeedsAttentionDot collapsed={collapsed} errorCounts={errorCounts} />
                      </Space>
                  )
              )
            : null,
        user.capabilities.manageSystemSettings
            ? getMenuItem(
                  'Settings',
                  NAVIGATION_KEY.Settings,
                  selectedNavigationKey.startsWith(NAVIGATION_KEY.SettingsClientPortal) ? (
                      <SettingFilled />
                  ) : (
                      <SettingOutlined />
                  ),
                  [
                      getMenuItem('Client Portal', NAVIGATION_KEY.SettingsClientPortal)
                      //   getMenuItem('Users', NAVIGATION_KEY.SettingsUsers)
                  ]
              )
            : null
    ];

    const onMenuClick: MenuProps['onClick'] = (e) => {
        if (selectedNavigationKey !== e.key) {
            // When invoiceFilters change, we reset the pagination params
            resetInvoiceListPaginationParams();
        }
        const onlyMyInvoices = !!invoiceFilters.filters[InvoiceFilter.MY_INVOICES].length;
        const onlyMyClients = !!clientFilters.filters[ClientFilter.MY_CLIENTS].length;
        const onlyMyReminders = !!reminderFilters.filters[REMINDER_FILTER.MY_REMINDERS].length;
        switch (e.key) {
            case NAVIGATION_KEY.Dashboard:
                navigate('/');
                break;
            case NAVIGATION_KEY.InvoicesPending:
                navigate(`/invoices/pending/${getOnlyMineFilter(onlyMyInvoices)}`);
                break;
            case NAVIGATION_KEY.InvoicesOutstanding:
                navigate(`/invoices/outstanding/${getOnlyMineFilter(onlyMyInvoices)}`);
                break;
            case NAVIGATION_KEY.InvoicesPaid:
                navigate(`/invoices/paid/${getOnlyMineFilter(onlyMyInvoices)}`);
                break;
            case NAVIGATION_KEY.InvoicesConsolidated:
                navigate('/invoices/consolidated/my');
                break;
            case NAVIGATION_KEY.NeedsAttention:
                navigate('/needs-attention/all');
                break;
            case NAVIGATION_KEY.Reminders:
                navigate(`/reminders/${getOnlyMineFilter(onlyMyReminders)}`);
                break;
            case NAVIGATION_KEY.SettingsClientPortal:
                navigate('/settings/client-portal');
                break;

            case NAVIGATION_KEY.Clients:
                navigate(`/clients/${getOnlyMineFilter(onlyMyClients)}`);
                break;
            case NAVIGATION_KEY.Payments:
                navigate('/payments');
                break;
            // case NAVIGATION_KEY.SettingsUsers:
            //     navigate('/settings/users');
            //     break;
        }
    };

    return (
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className='firm-logo'>
                <Image
                    width={collapsed ? 48 : 88}
                    src={tenant!.logoUrl}
                    wrapperClassName={collapsed ? 'collapsed' : 'not-collapsed'}
                    alt='Firm Logo'
                    preview={false}
                />
            </div>
            <Menu
                onClick={onMenuClick}
                overflowedIndicator={false}
                theme='light'
                mode='inline'
                selectedKeys={[selectedNavigationKey]}
                items={items}
                style={{ marginTop: '15px', border: 'none' }}
                defaultOpenKeys={
                    (!collapsed && [NAVIGATION_KEY.Settings, NAVIGATION_KEY.Invoices]) || undefined
                }
            />
        </Sider>
    );
});

const NeedsAttentionDot = (props: NeedsAttentionDotProps) => {
    const { collapsed, errorCounts } = props;
    if (!collapsed || !errorCounts?.totalCount) {
        return null;
    }
    return <Badge size='small' dot={true} />;
};
