export class SortingParams {
    sortColumn: string | null;
    sortDirection: 'ascend' | 'descend' | undefined;
    readonly sortExpression: string;

    constructor(sortColumn = '', sortDirection: 'ascend' | 'descend' | undefined = undefined) {
        this.sortColumn = sortColumn;
        this.sortDirection = sortDirection;
        this.sortExpression = sortColumn
            ? sortDirection === 'descend'
                ? '-' + sortColumn
                : sortColumn
            : '';
    }
}
