import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { downloadDocumentBlob } from '../common/utils/file';
import { InvoiceURLSearchParamBuilder } from '../common/utils/invoice';
import { Notify } from '../common/utils/notify';
import { URLParams } from '../common/utils/urlParams';
import { ClientStatement } from '../models/client';
import { AppConfig } from '../models/config/appConfig';
import { INVOICE_STATE } from '../models/invoice';
import { PaginationParams } from '../models/list/pagination';
import { SortingParams } from '../models/list/sorting';
import {
    AssignedMatterTag,
    Matter,
    MatterActivity,
    MatterPatchProperties,
    MatterPayor
} from '../models/matter';
import { PAGE_KEY } from '../models/pageConfigs';
import { StatementActivity } from '../models/statement';
import { store } from './store';

export default class MatterStore {
    matter: Matter | null = null;
    loadingMatter = false;

    matterPayors: MatterPayor[] | null = null;
    loadingMatterPayors = false;

    matterStatementActivities: StatementActivity[] | null = null;
    loadingMatterStatementActivities = false;

    matterActivities: MatterActivity[] | null = null;
    loadingMatterActivities = false;

    matterStatements: ClientStatement[] | null = null;
    loadingMatterStatements = false;

    updatingMatter = false;

    loadingAssignedMatterTags = false;
    assignedMatterTags: AssignedMatterTag[] = [];
    updatingAssignedMatterTags = false;

    constructor() {
        makeAutoObservable(this);
    }

    urlParams = new URLParams([PAGE_KEY.MATTER_LIST]);

    getMatterProfileInvoiceStatePageKey = (invoiceState: INVOICE_STATE) =>
        `matterProfileInvoices!${invoiceState}`;

    getMatterProfileInvoicesUrlParams = (invoiceState: INVOICE_STATE) => {
        const params = new URLSearchParams();
        const pageKey = this.getMatterProfileInvoiceStatePageKey(invoiceState);
        const paginationParams = this.urlParams.getPaginationParams(pageKey);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(pageKey);
        if (sortingParams.sortExpression) {
            params.append('orderBy', sortingParams.sortExpression);
        }
        return params;
    };

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    loadMatter = async (clientId: string, matterId: string, showLoader = true) => {
        try {
            if (showLoader) {
                this.loadingMatter = true;
            }
            const matter = await agent.Matters.getMatter(clientId, matterId);
            runInAction(() => {
                this.matter = matter;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingMatter = false;
            });
        }
    };

    getMatter = async (clientId: string, matterId: string) => {
        try {
            this.loadingMatter = true;
            const matter = await agent.Matters.getMatter(clientId, matterId, new URLSearchParams());
            return matter;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingMatter = false;
            });
        }
    };

    updateBillToEmails = async (
        clientId: string,
        matterId: string,
        billToEmails: string[],
        provisional: boolean
    ) => {
        let updated = false;
        try {
            await agent.Matters.updateBillToEmails(clientId, matterId, billToEmails, provisional);
            updated = true;
        } catch (err) {
            console.log(err);
            Notify.error('Unable to update emails. Please try again.');
        }
        return updated;
    };

    updateStatementEmails = async (
        clientId: string,
        matterId: string,
        statementEmails: string[],
        provisional: boolean
    ) => {
        let updated = false;
        try {
            await agent.Matters.updateStatementEmails(
                clientId,
                matterId,
                statementEmails,
                provisional
            );
            updated = true;
        } catch (err) {
            console.log(err);
        }
        return updated;
    };

    loadMatterActivities = async (clientId: string, matterId: string) => {
        this.loadingMatterActivities = true;
        try {
            const matterActivities = await agent.Matters.getActivities(clientId, matterId);
            runInAction(() => {
                this.matterActivities = matterActivities;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterActivities = false));
        }
    };

    loadMatterStatementActivities = async (clientId: string, matterId: string) => {
        this.loadingMatterStatementActivities = true;
        try {
            const matterStatementActivities = await agent.Matters.getStatementActivities(
                clientId,
                matterId
            );
            runInAction(() => {
                this.matterStatementActivities = matterStatementActivities;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterStatementActivities = false));
        }
    };

    loadMatterPayors = async (clientId: string, matterId: string) => {
        this.loadingMatterPayors = true;
        try {
            const matterPayors = await agent.Matters.getPayors(clientId, matterId);
            runInAction(() => {
                this.matterPayors = matterPayors;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterPayors = false));
        }
    };

    updateDefaultInvoiceType = async (
        clientId: string,
        matterId: string,
        defaultInvoiceType: string | null,
        cascadeUpdate: boolean
    ) => {
        this.updatingMatter = true;
        try {
            await agent.Matters.updateDefaultInvoiceType(
                clientId,
                matterId,
                defaultInvoiceType,
                cascadeUpdate
            );
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.updatingMatter = false;
            });
        }
    };

    // For now just using the clientStatement API itself.
    // Filed CBH-1730 for this
    loadMatterStatements = async (clientId: string, matterId: string) => {
        this.loadingMatterStatements = true;
        try {
            const statements = await agent.Clients.getClientStatements(clientId);
            runInAction(() => {
                this.matterStatements = statements.filter(
                    (statement) => statement.matterId === matterId
                );
            });
            return this.matterStatements;
        } catch (err) {
            console.log(err);
            return null;
        } finally {
            runInAction(() => {
                this.loadingMatterStatements = false;
            });
        }
    };

    patchMatter = async (
        clientId: string,
        matterId: string,
        patchProperties: MatterPatchProperties
    ) => {
        this.updatingMatter = true;
        try {
            await agent.Matters.patchMatter(clientId, matterId, patchProperties);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.updatingMatter = false;
            });
        }
    };

    lookupMatters = async (clientId: string, queryText: string, limit = 50) => {
        try {
            const params = new URLSearchParams();
            params.append('queryText', queryText);
            params.append('limit', limit.toString());
            const matters = await agent.Matters.lookup(clientId, params);
            return matters;
        } catch (err) {
            console.log(err);
        }
    };

    loadAssignedMatterTags = async (matterId: string) => {
        this.loadingAssignedMatterTags = true;
        try {
            const assignedMatterTags = await agent.Matters.getAssignedMatterTags(matterId);
            runInAction(() => {
                this.assignedMatterTags = assignedMatterTags;
                if (this.matter) {
                    this.matter.tagNames = assignedMatterTags.map((tag) => tag.matterTag.name);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingAssignedMatterTags = false));
        }
    };

    updateAssignedMatterTags = async (matterId: string, tagKeys: string[]) => {
        this.updatingAssignedMatterTags = true;
        try {
            await agent.Matters.updateAssignedMatterTags(matterId, tagKeys);
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingAssignedMatterTags = false;
            });
        }
    };

    exportInvoicesToExcel = async (
        clientId: string,
        matterId: string,
        invoiceState: INVOICE_STATE
    ) => {
        try {
            const paramsBuilder = new InvoiceURLSearchParamBuilder(
                invoiceState as INVOICE_STATE
            ).addReversed(invoiceState === INVOICE_STATE.REVERSED);

            if (invoiceState !== INVOICE_STATE.REVERSED) {
                paramsBuilder.addPaidInFull().addSubmitted();
            }
            const exportedBlob = await agent.Matters.exportInvoicesToExcel(
                clientId,
                matterId,
                paramsBuilder.urlSearchParams
            );
            const fileName = `Matter-${matterId}-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(exportedBlob, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    clearMatterStatements = () => {
        this.matterStatements = null;
    };

    clearStore = () => {
        this.matter = null;
        this.matterPayors = null;
        this.matterActivities = null;
        this.matterStatementActivities = null;
        this.matterStatements = null;
        this.assignedMatterTags = [];
    };
}
