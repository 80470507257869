import { ActionFormCompoundItemProps } from '../ActionForm';
import EditEmailSubject from '../../../../../features/invoices/hooks/components/EditEmailSubject';

export default function ActionFormEmailSubject(props: ActionFormCompoundItemProps) {
    const { onChange } = props;
    const handleEmailSubjectChange = (emailSubject: string) => {
        onChange?.(emailSubject.length ? emailSubject : null);
    };
    return <EditEmailSubject onChange={handleEmailSubjectChange} />;
}
