import {
    AuditOutlined,
    CloseCircleFilled,
    FileTextOutlined,
    LoadingOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';
import { AutoComplete, Flex, Input, InputRef, Space, Spin, Typography } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientLookup } from '../models/client';
import { InvoiceLookup } from '../models/invoice';
import { MatterLookup } from '../models/matter';
import { useStore } from '../stores/store';
import { trim } from '../common/utils/string';

const { Text } = Typography;

const MAX_RESULTS = 10;

export type OptionItem = {
    label: ReactNode;
    options: {
        label: ReactNode;
        data: ClientLookup | InvoiceLookup | MatterLookup;
    }[];
};

export default observer(function GlobalSearchComponent() {
    const {
        commonStore: { globalQuerylookup }
    } = useStore();

    const [options, setOptions] = useState<OptionItem[]>([]);
    const [fetching, setFetching] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [size, setSize] = useState(false);
    const searchBoxRef = useRef<InputRef>(null);
    const clearRef = useRef<HTMLSpanElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const keyupListener = (e: KeyboardEvent) => {
            const target = e.target as Element;
            const tagName = target.tagName.toLowerCase();
            if (e.key !== '/' || e.ctrlKey || e.metaKey) {
                return;
            }
            // Not to work on other input places
            if (
                tagName === 'input' ||
                tagName === 'textarea' ||
                tagName === 'select' ||
                tagName === 'button' ||
                (tagName === 'div' && target.className === 'ql-editor') // RTF input
            ) {
                return;
            }
            e.preventDefault();
            searchBoxRef.current?.focus({ cursor: 'end' });
        };
        document.addEventListener('keyup', keyupListener);

        return () => {
            document.removeEventListener('keyup', keyupListener);
        };
    }, []);

    const onFocushandler = () => {
        setSize(true);
    };

    const onBlurhandler = () => {
        setSize(false);
    };

    const noResultsFoundContent = () => {
        if (fetching) {
            return <Spin indicator={<LoadingOutlined />} />;
        }

        if (searchTerm) {
            if (searchTerm.length < 3) {
                return 'Search term must be at least 3 characters long';
            } else {
                return 'No results found';
            }
        }

        return null;
    };

    const renderTitle = (title: string, icon: ReactElement, totalResults: number) => (
        <Flex justify='space-between' align='center'>
            <Space>
                {icon}
                <Text style={{ color: '#595959' }}>{title}</Text>
            </Space>
            {totalResults > MAX_RESULTS && (
                <Text
                    style={{ fontSize: '12px', color: '#595959' }}
                >{`showing top ${MAX_RESULTS} ${title}`}</Text>
            )}
        </Flex>
    );

    const handleSearch = useMemo(() => {
        const loadOptions = async (value: string) => {
            value = trim(value);
            setSearchTerm(value);

            if (value.length < 3) {
                return;
            }

            setFetching(true);

            // MAX_RESULTS + 1 will help us identify if there are more results and display
            // the max results label to end users.
            const results = await globalQuerylookup(value, MAX_RESULTS + 1);
            if (!results) {
                setOptions([]);
                setFetching(false);
                return [];
            }

            const newOptions: OptionItem[] = [];

            if (results.clients.length > 0) {
                newOptions.push({
                    label: renderTitle('Clients', <UserOutlined />, results.clients.length),
                    options: results.clients.slice(0, MAX_RESULTS).map((client) => ({
                        label: `${client.clientId} - ${client.name}`,
                        value: client.clientId,
                        data: client
                    }))
                });
            }

            if (results.matters.length > 0) {
                newOptions.push({
                    label: renderTitle('Matters', <AuditOutlined />, results.matters.length),
                    options: results.matters.slice(0, MAX_RESULTS).map((matter) => ({
                        label: `${matter.matterId} - ${matter.name}`,
                        value: matter.matterId,
                        data: matter
                    }))
                });
            }

            if (results.invoices.length > 0) {
                newOptions.push({
                    label: renderTitle('Invoices', <FileTextOutlined />, results.invoices.length),
                    options: results.invoices.slice(0, MAX_RESULTS).map((invoice) => ({
                        label: `${invoice.number} - ${invoice.matterName}`,
                        value: invoice.invoiceId,
                        data: invoice
                    }))
                });
            }

            setOptions(newOptions);
            setFetching(false);
        };

        return debounce(loadOptions, 500);
    }, []);

    const onSelect = (data: OptionItem['options'][number]['data']) => {
        if ('invoiceId' in data) {
            const invoiceData = data as InvoiceLookup;
            navigate(`/invoices/${invoiceData.invoiceId}`);
        } else if ('matterId' in data) {
            const matterData = data as MatterLookup;
            navigate(`/clients/${matterData.clientId}/matters/${matterData.matterId}`);
        } else if ('clientId' in data) {
            const clientData = data as ClientLookup;
            navigate(`/clients/${clientData.clientId}`);
        }
    };

    return (
        <AutoComplete
            options={options as unknown as OptionItem['options']}
            style={{ width: size ? 700 : 400 }}
            backfill
            onSearch={handleSearch}
            onFocus={onFocushandler}
            onBlur={onBlurhandler}
            onSelect={(_, option: OptionItem['options'][number]) => {
                if (option.data) {
                    onSelect(option.data);
                    clearRef.current?.click();
                    searchBoxRef.current?.blur();
                    setOptions([]);
                }
            }}
            notFoundContent={noResultsFoundContent()}
            popupMatchSelectWidth={size ? 700 : 400}
        >
            <Input
                ref={searchBoxRef}
                prefix={<SearchOutlined />}
                allowClear={{
                    clearIcon: (
                        <CloseCircleFilled
                            ref={clearRef}
                            onClick={() => {
                                setOptions([]);
                            }}
                        />
                    )
                }}
                title={`Use '/' to focus and search`}
                placeholder={`Search clients, matters and invoices`}
            />
        </AutoComplete>
    );
});
