import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { TimekeeperLookup } from '../models/timeKeeper';
import { QueryMemo } from '../models/list/query';

export default class TimekeeperStore {
    queryMemo = new QueryMemo<TimekeeperLookup[]>();

    constructor() {
        makeAutoObservable(this);
    }

    queryTimekeepers = async (query: string) => {
        const timekeepers = this.queryMemo.getRecord(query);
        if (timekeepers) {
            return timekeepers;
        }
        try {
            const params = new URLSearchParams();
            params.append('queryText', query);
            const result = await agent.Timekeepers.lookup(params);
            this.queryMemo.addRecord(query, result);
            return result;
        } catch (error) {
            console.log(error);
            return null;
        }
    };
}
