export class SearchParams {
    searchField: string | undefined;
    searchString: string | undefined;
    readonly searchExpression: string;

    constructor(
        searchField: string | undefined = undefined,
        searchString: string | undefined = undefined
    ) {
        this.searchField = searchField;
        this.searchString = searchString;
        this.searchExpression =
            this.searchField && this.searchString
                ? this.searchField + '==' + this.searchString
                : '';
    }
}
