import { ClientIdentifier } from './client';
import { InvoiceIdentifier } from './invoice';
import { MatterIdentifier } from './matter';
import { StatementIdentifier } from './statement';

export type Transaction = {
    transactionId: string;
    convenienceFee: number;
    referenceNumber: string;
    paymentMadeOn: Date;
    paymentMethodDescription: string;
    processOn: Date;
};

export type Payment = {
    amount: number;
    invoice: InvoiceIdentifier | null;
    client: ClientIdentifier;
    matter: MatterIdentifier | null;
    createdOn: Date;
    error: string | null;
    id: string;
    note: string | null;
    scheduledOn: Date | null;
    statement: StatementIdentifier | null;
    transaction: Transaction;
};

export enum PAYMENT_TYPE {
    NONE = 'None',
    CLIENT_PAY = 'ClientPay',
    PAYLOAD = 'Payload'
}

export enum PaymentFilter {
    CLIENTS = 'clientIds'
}

export interface PaymentFilters {
    [PaymentFilter.CLIENTS]: string[];
}
