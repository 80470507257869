import { useEffect } from 'react';
import EditEmailAddresses from '../../../../../features/invoices/hooks/components/EditEmailAddresses';
import { ActionFormCompoundItemProps } from '../ActionForm';

type Props = {
    emails: string[] | string | null;
    tooltipInfo: string;
    defaultEditMode: boolean;
} & ActionFormCompoundItemProps;

export default function ActionFormEmailAddresses(props: Props) {
    const { emails, tooltipInfo, onChange, defaultEditMode } = props;
    const handleEmailsChange = (emails: string[], isValid: boolean) => {
        onChange?.(isValid ? emails : null);
    };

    useEffect(() => {
        if (emails?.length) {
            onChange?.(emails);
        }
    }, [emails]);

    return (
        <EditEmailAddresses
            onChange={handleEmailsChange}
            emails={emails ?? []}
            tooltipInfo={tooltipInfo}
            defaultEditMode={defaultEditMode}
        />
    );
}
