import { Avatar } from 'antd';
import {
    getAvatarBgColor,
    getAvatarColor,
    getAvatarColorHue,
    getNameInitials
} from '../utils/user';
import { AvatarSize } from 'antd/lib/avatar/AvatarContext';

type Props = {
    userName: string;
    showBorder?: boolean;
    size?: AvatarSize | undefined;
};
export default function AvatarComponent({ userName, showBorder, size }: Props) {
    const initials = getNameInitials(userName);
    const hue = getAvatarColorHue(userName);
    const bgColor = getAvatarBgColor(hue);
    const color = getAvatarColor(hue);

    return (
        <Avatar
            style={{
                backgroundColor: bgColor,
                color: color,
                fontSize: '13px',
                fontWeight: '500',
                border: showBorder ? `1px solid ${color}` : 'none'
            }}
            size={size ?? 'default'}
            alt={userName}
        >
            {initials}
        </Avatar>
    );
}
