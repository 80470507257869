import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Flex,
    Form,
    InputNumber,
    Row,
    Select,
    Space
} from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import Loader from '../../../../app/common/components/Loader';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import RichTextEditor from '../../../../app/common/components/wysiwyg/RichTextEditor';
import UploadDoc from '../../../../app/common/uploadFile/Upload';
import { Notify } from '../../../../app/common/utils/notify';
import { isForeignCurrency } from '../../../../app/common/utils/tenant';
import { CC_USER, ClientStatement } from '../../../../app/models/client';
import { AppConfig } from '../../../../app/models/config/appConfig';
import { Currency, supportedUploadFileTypes } from '../../../../app/models/invoice';
import { QueryMemo } from '../../../../app/models/list/query';
import { EMAIL_TEMPLATE_TYPE, EmailTemplate } from '../../../../app/models/template';
import { useStore } from '../../../../app/stores/store';
import CcUsersCheckboxGroup from '../../../invoices/hooks/components/CcUsersCheckboxGroup';
import EditEmailAddresses from '../../../invoices/hooks/components/EditEmailAddresses';
import { getOneTimeExceptionEmailEditorTooltip } from '../../../invoices/hooks/helpers/submit';

export type Props = {
    clientId: string;
    statements: ClientStatement[];
    recipients: string[] | null;
    ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[];
    onSuccess?: () => Promise<any> | any;
    // This is an indication that a matter statement is being sent
    matterId?: string;
    billingTimekeeperName?: string;
    collectionTimekeeperName?: string;
    defaultMonth?: Date;
    needsAttention?: boolean;
    doNotContact?: boolean;
    currency?: Currency;
};

export default observer(function SendStatementModalBody(props: Props) {
    const {
        statements,
        defaultMonth,
        needsAttention,
        recipients,
        onSuccess,
        matterId,
        ccTo,
        billingTimekeeperName,
        collectionTimekeeperName,
        clientId,
        doNotContact,
        currency
    } = props;
    const {
        statementStore: {
            getStatementDocument,
            sendStatement,
            loadingStatementDocument,
            sendingStatement
        },
        modalStore: { closeModal },
        templateStore: { getEmailTemplates, clientReminderEmailTemplates, loadingEmailTemplates }
    } = useStore();
    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
    const [editEmailNote, setEditEmailNote] = useState(false);
    const [existingStatement, setExistingStatement] = useState<ClientStatement | null>(null);
    const [statementMonth, setStatementMonth] = useState<string | null>(null);
    const [statementDocument, setStatementDocument] = useState<File | null>(null);
    const [amount, setAmount] = useState<number | null>(0);
    const [foreignCurrencyAmount, setForeignCurrencyAmount] = useState<number | null>(0);
    const [ccUsers, setCcUsers] = useState<CC_USER[]>([]);
    const [overrideDnd, setOverrideDnd] = useState(false);
    const [overrideSubmit, setOverrideSubmit] = useState(false);
    const [emailMessage, setEmailMessage] = useState<string | null>(null);
    const isDocumentReUploaded = useRef(false);
    const cachedStatementDocuments = useRef(new QueryMemo<File | null>());
    const defaultMonth_ = defaultMonth ? dayjs(defaultMonth) : dayjs();
    const [emailRecipients, setEmailRecipients] = useState<string[] | null>(recipients);
    const foreignCurrency = isForeignCurrency(currency);

    useEffect(() => {
        if (clientReminderEmailTemplates === undefined) {
            getEmailTemplates(EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL);
        }
        handleDateChange(defaultMonth_.format('YYYY-MM'));
    }, []);

    useEffect(() => {
        if (clientReminderEmailTemplates?.length) {
            setSelectedTemplate(clientReminderEmailTemplates[0]);
        }
    }, [clientReminderEmailTemplates]);

    const handleDateChange = async (dateString: string | string[]) => {
        if (typeof dateString !== 'string') {
            return;
        }
        let file;

        const matchingStatement = statements.find(
            (statement) => dayjs(statement.statementMonth).format('YYYY-MM') === dateString
        );

        if (matchingStatement) {
            const document = cachedStatementDocuments.current.getRecord(
                matchingStatement.statementId
            );
            if (document) {
                setExistingStatement(matchingStatement);
                setStatementDocument(document);
            } else {
                const statementDocument = await getStatementDocument(matchingStatement.statementId);
                if (statementDocument) {
                    file = new File([statementDocument], matchingStatement.statementDocumentName, {
                        type: 'application/pdf'
                    });
                    setExistingStatement(matchingStatement);
                    setStatementDocument(file);
                    cachedStatementDocuments.current.addRecord(matchingStatement.statementId, file);
                }
            }
            setAmount(matchingStatement.amount);
            setForeignCurrencyAmount(matchingStatement?.foreignCurrency?.amount);
        } else {
            setExistingStatement(null);
            setAmount(0);
            setForeignCurrencyAmount(0);
            setStatementDocument(null);
        }
        setStatementMonth(dateString);

        // Resetting the flag, since the pre uploaded statement (if present)
        // will be loaded
        isDocumentReUploaded.current = false;
    };

    const handleDocumentUpdate = (files: UploadFile<any>[]) => {
        setStatementDocument(files.length ? (files[0] as RcFile) : null);

        // We set the flag to true to track whether to include the document in the
        // send statement call.
        isDocumentReUploaded.current = true;
    };

    const handleOnOK = async () => {
        if (
            statementDocument &&
            amount !== null &&
            statementMonth &&
            (foreignCurrency ? foreignCurrencyAmount !== null : true) &&
            emailRecipients
        ) {
            const shouldIncludeAmounts =
                existingStatement?.amount !== amount ||
                existingStatement.foreignCurrency.amount !== foreignCurrencyAmount;

            const success = await sendStatement(clientId, {
                statementId: existingStatement?.statementId ?? null,
                statementDocument: isDocumentReUploaded.current ? statementDocument : null,
                statementMonth,
                amount: shouldIncludeAmounts ? amount : null,
                ccUsers,
                emailMessage,
                emailRecipients: !isEqual(emailRecipients, recipients) ? emailRecipients : null,
                matterId: matterId ?? null,
                foreignCurrencyAmount:
                    foreignCurrency && shouldIncludeAmounts ? foreignCurrencyAmount : null
            });
            if (success) {
                Notify.success(`Statement sent successfully`, 'Success');
                onSuccess?.();
            }
        }
        closeModal();
    };

    // We don't allow sending the statement if no amount, month or document
    // is set. And also, if there is dnd enabled it should be overridden
    const disableSendButton =
        !(statementDocument && amount !== null && statementMonth) ||
        (doNotContact && !overrideDnd) ||
        (foreignCurrency && foreignCurrencyAmount === null) ||
        (emailRecipients && emailRecipients?.length < 1) ||
        (!!existingStatement?.canShare && !overrideSubmit);

    return (
        <Loader spinning={loadingEmailTemplates || loadingStatementDocument || sendingStatement}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Form layout='vertical' colon={false}>
                    <Row gutter={[12, 0]}>
                        <Col span={24}>
                            <Form.Item>
                                <EditEmailAddresses
                                    onChange={(emails) => setEmailRecipients(emails)}
                                    emails={recipients ?? ''}
                                    tooltipInfo={getOneTimeExceptionEmailEditorTooltip()}
                                    defaultEditMode={!!needsAttention || !recipients?.length}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[8, 0]}>
                                <Col span={foreignCurrency ? 8 : 12}>
                                    <Form.Item
                                        required
                                        label={<FormLabel label='Statement Month' />}
                                    >
                                        <DatePicker
                                            defaultValue={defaultMonth_}
                                            picker='month'
                                            onChange={(_, dateString) => {
                                                handleDateChange(dateString);
                                            }}
                                            style={{ width: '100%' }}
                                            placeholder=''
                                            disabled={needsAttention}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={foreignCurrency ? 8 : 12}>
                                    <Form.Item required label={<FormLabel label='Amount' />}>
                                        <InputNumber
                                            keyboard={false}
                                            precision={2}
                                            controls={false}
                                            value={amount}
                                            style={{ width: '100%' }}
                                            prefix={AppConfig.currencySymbol}
                                            onChange={(amount) => setAmount(amount)}
                                        />
                                    </Form.Item>
                                </Col>
                                {foreignCurrency && (
                                    <Col span={8}>
                                        <Form.Item
                                            required
                                            label={
                                                <FormLabel label={`Amount (${currency?.code})`} />
                                            }
                                        >
                                            <InputNumber
                                                keyboard={false}
                                                precision={2}
                                                controls={false}
                                                value={foreignCurrencyAmount}
                                                style={{ width: '100%' }}
                                                prefix={currency?.symbol}
                                                onChange={(amount) => {
                                                    setForeignCurrencyAmount(amount);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>

                    <Form.Item required label={<FormLabel label='Statement' />}>
                        <UploadDoc
                            allowedExtensions={supportedUploadFileTypes.statement}
                            multiSelect={false}
                            onSelect={handleDocumentUpdate}
                            type='button'
                            fileList={statementDocument ? [statementDocument] : []}
                            showDownload
                            showPreview
                        />
                    </Form.Item>
                    {editEmailNote ? (
                        <Form.Item label={<FormLabel label='Email note:' />}>
                            <Space direction='vertical' style={{ width: '100%' }}>
                                {!!clientReminderEmailTemplates?.length && (
                                    <Select
                                        options={clientReminderEmailTemplates.map((template) => ({
                                            value: template.name,
                                            template
                                        }))}
                                        onSelect={(_, option) => {
                                            setSelectedTemplate(option.template);
                                            setEmailMessage(option.template.value);
                                        }}
                                        value={selectedTemplate?.name}
                                    />
                                )}
                                <RichTextEditor
                                    key={`template-key-${selectedTemplate?.name}`}
                                    defaultValue={selectedTemplate?.value ?? ''}
                                    onChange={(comment) => setEmailMessage(comment)}
                                    autoFocus={false}
                                    placeholder='(leave blank to use system default)'
                                />
                            </Space>
                        </Form.Item>
                    ) : (
                        <Form.Item>
                            <Space direction='vertical' size={0}>
                                <FormLabel label='Email note:' />
                                <a onClick={() => setEditEmailNote(true)}>Customize</a>
                            </Space>
                        </Form.Item>
                    )}

                    <Form.Item>
                        <CcUsersCheckboxGroup
                            onChange={(ccUsers) => setCcUsers(ccUsers)}
                            billingTimekeeperName={billingTimekeeperName}
                            collectionTimekeeperName={collectionTimekeeperName}
                            ccTo={ccTo}
                            bulkAction={false}
                        />
                    </Form.Item>

                    {doNotContact && (
                        <Form.Item>
                            <Checkbox onChange={(e) => setOverrideDnd(e.target.checked)}>
                                Override the instructions to not contact the client for payments or
                                collections
                            </Checkbox>
                        </Form.Item>
                    )}

                    {existingStatement?.canShare && (
                        <Form.Item>
                            <Checkbox onChange={(e) => setOverrideSubmit(e.target.checked)}>
                                This Statement was previously sent to the Client. Please confirm
                                that you want to resend it.
                            </Checkbox>
                        </Form.Item>
                    )}
                    <Flex justify='flex-end'>
                        <Space>
                            <Button onClick={() => closeModal()}>Cancel</Button>
                            <Button
                                onClick={handleOnOK}
                                type='primary'
                                disabled={disableSendButton}
                            >
                                Send
                            </Button>
                        </Space>
                    </Flex>
                </Form>
            </Space>
        </Loader>
    );
});
