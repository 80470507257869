import { useEffect, useRef } from 'react';
import { Cache } from '../../../app/common/utils/cache';

export default function useCache<T>(initialValues?: Map<string, T>) {
    const ref = useRef<Cache<T>>(new Cache<T>(initialValues));

    useEffect(() => {
        return () => ref.current.clear();
    }, []);

    return ref.current;
}
