import { Result } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function UnAuthorized() {
    return (
        <Result
            status='warning'
            icon={<ExclamationCircleFilled />}
            title='Unauthorized'
            subTitle='Sorry, you are not authorized to access this page.'
            extra={<Link to={'/'}>Return to Dashboard</Link>}
        />
    );
}
