import RichTextEditor from '../../wysiwyg/RichTextEditor';
import { ActionFormCompoundItemProps } from '../ActionForm';

type Props = {
    defaultValue_?: string;
    placeholder?: string;
    maxLen?: number;
    autoFocus?: boolean;
} & ActionFormCompoundItemProps;

export default function ActionFormRichTextEditor(props: Props) {
    const { placeholder, maxLen = 1000, defaultValue_, autoFocus, onChange } = props;
    const handleCommentChange = (comment: string, rawTextLen: number) => {
        onChange?.(defaultValue_ ? comment : rawTextLen ? comment : null);
    };
    return (
        <RichTextEditor
            maxLen={maxLen}
            onChange={handleCommentChange}
            placeholder={placeholder}
            defaultValue={defaultValue_}
            autoFocus={autoFocus}
        />
    );
}
