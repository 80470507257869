import {
    BULK_STATEMENT_SUBMISSION_WARNING,
    StatementsSubmissionResult
} from '../../../../app/models/statement';

export const getBulkSendSuccessMessage = (markAsSent: boolean) => {
    return !markAsSent
        ? 'Successfully sent the statements.'
        : 'Successfully marked statements as sent.';
};

export const getBulkSendErrorMessage = (markAsSent: boolean) => {
    return !markAsSent ? 'Failed to send the statements.' : 'Failed to mark statements as sent.';
};

export const getBulkSendMessageWithCount = (
    markAsSent: boolean,
    statementSubmittedCount: number,
    totalCount: number,
    suffix: string
) => {
    return !markAsSent
        ? `Statements successfully sent for ${statementSubmittedCount} out of ${totalCount} ${suffix}`
        : `Statements successfully marked as sent for ${statementSubmittedCount} out of ${totalCount} ${suffix}`;
};

export const getBulkSendWarningMessage = (warning: BULK_STATEMENT_SUBMISSION_WARNING) => {
    switch (warning) {
        case BULK_STATEMENT_SUBMISSION_WARNING.CAN_SUBMIT_SOME_STATEMENTS:
            return 'Some Statements cannot be sent for the selected month';
        case BULK_STATEMENT_SUBMISSION_WARNING.CANNOT_SUBMIT_ANY_STATEMENTS:
            return 'No Statements can be sent for the selected month.';
        case BULK_STATEMENT_SUBMISSION_WARNING.UNABLE_TO_VALIDATE:
            return 'An unknown error occured. Please refresh the page and try again.';
    }
};

export const getBulkMarkAsSentWarningMessage = (warning: BULK_STATEMENT_SUBMISSION_WARNING) => {
    switch (warning) {
        case BULK_STATEMENT_SUBMISSION_WARNING.CAN_SUBMIT_SOME_STATEMENTS:
            return 'Some statements cannot be marked as sent because they are either missing or have already been sent for the selected month.';
        case BULK_STATEMENT_SUBMISSION_WARNING.CANNOT_SUBMIT_ANY_STATEMENTS:
            return 'No statements can be marked as sent because they are either missing or have already been sent for the selected month.';
        case BULK_STATEMENT_SUBMISSION_WARNING.UNABLE_TO_VALIDATE:
            return 'An unknown error occured. Please refresh the page and try again.';
    }
};

export const getBulkStatementSubmissionWarningForValidation = (
    statementSubmissionResult: StatementsSubmissionResult
) => {
    const results = statementSubmissionResult.results;
    const successCount = results.filter((r) => r.success).length;
    if (successCount === 0) {
        return BULK_STATEMENT_SUBMISSION_WARNING.CANNOT_SUBMIT_ANY_STATEMENTS;
    }
    if (successCount === results.length) {
        return BULK_STATEMENT_SUBMISSION_WARNING.NO_WARNING;
    }
    return BULK_STATEMENT_SUBMISSION_WARNING.CAN_SUBMIT_SOME_STATEMENTS;
};
