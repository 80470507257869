export default {
    DEFAULT_ROUTE: '/',
    LOGIN: '/login',
    SSO_CALLBACK: '/sso/callback',
    INVOICES: '/invoices',
    INVOICE_LIST: ':invoiceStateFilter/:myInvoicesFilter',
    INVOICE_DETAILS: ':invoiceId',
    INVOICE_WRITEOFF_APPROVE: ':invoiceId/writeoff/_approve',
    INVOICE_WRITEOFF_COMPLETE: ':invoiceId/writeoff/_complete',
    CLIENTS: '/clients',
    MY_CLIENT_LIST: 'my',
    ALL_CLIENT_LIST: 'all',
    CLIENT_PROFILE: ':clientId',
    MATTER_PROFILE: 'matters/:matterId',
    REMINDERS_LIST: '/reminders/:myRemindersFilter',
    SETTINGS: '/settings',
    SETTINGS_CLIENT_PORTAL: 'client-portal',
    SERVER_ERROR: '/server-error',
    NEEDS_ATTENTION: '/needs-attention/all',
    PAYMENTS: '/payments'
};

export const StaticRoutes = {
    INVOICE_LIST_PENDING: '/invoices/pending/all',
    INVOICE_LIST_OUTSTANDING: '/invoices/outstanding/all',
    INVOICE_LIST_CONSOLIDATED: '/invoices/consolidated/all'
};
