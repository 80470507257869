import { Input, Space } from 'antd';
import { useState } from 'react';
import FormLabel from '../../../../app/common/components/form/FormLabel';

type Props = {
    onChange: (emailSubject: string) => void;
};
export default function EditEmailSubject(props: Props) {
    const { onChange } = props;
    const [editEmailSubject, setEditEmailSubject] = useState(false);
    return (
        <Space direction='vertical' style={{ width: '100%' }} size={0}>
            <FormLabel label='Email subject:' />
            {editEmailSubject ? (
                <Input
                    placeholder='Enter email subject (leave blank to use system default)'
                    maxLength={256}
                    showCount
                    onChange={(e) => onChange(e.target.value)}
                />
            ) : (
                <a onClick={() => setEditEmailSubject(true)}>Customize</a>
            )}
        </Space>
    );
}
