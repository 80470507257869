import Auth from './auth';
import Charts from './charts';
import ClientConversations from './clientConversations';
import Clients from './clients';
import Dictionaries from './dictionaries';
import EmailTemplateValues from './emailTemplateValues';
import Errors from './errors';
import Identity from './identity';
import Invoices from './invoices';
import Matters from './matters';
import Notes from './notes';
import Payments from './payment';
import PaymentPlans from './paymentPlans';
import Payors from './payors';
import Reminders from './reminder';
import Search from './search';
import Statements from './statements';
import Tenants from './tenants';
import Timekeepers from './timekeepers';
import Users from './users';

const agent = {
    Auth,
    Charts,
    Identity,
    Invoices,
    Users,
    Tenants,
    Clients,
    ClientConversations,
    Statements,
    Matters,
    Notes,
    Reminders,
    Payors,
    Dictionaries,
    EmailTemplateValues,
    Errors,
    PaymentPlans,
    Timekeepers,
    Search,
    Payments
};

export const getErrorData = (error: any) => {
    return error.response.data;
};

export default agent;
