import { PaginationParams } from '../../models/list/pagination';
import { SearchParams } from '../../models/list/search';
import { SortingParams } from '../../models/list/sorting';

type BasicURLParams = {
    paginationParams: PaginationParams;
    sortingParams: SortingParams;
    searchParams: SearchParams;
};

export class URLParams {
    private urlParams = new Map<string, BasicURLParams>();
    private defaultPageSizes?: { [key: string]: number };

    // Pass the list of unique page keys for which
    // we want to track the url params separately
    constructor(pages: string[], defaultPageSizes?: { [key: string]: number }) {
        this.defaultPageSizes = defaultPageSizes;
        new Set(pages).forEach((page) =>
            this.urlParams.set(page, {
                paginationParams: new PaginationParams(undefined, defaultPageSizes?.[page]),
                sortingParams: new SortingParams(),
                searchParams: new SearchParams()
            })
        );
    }

    getPaginationParams = (page: string) => {
        return this.urlParams.get(page)?.paginationParams ?? new PaginationParams();
    };

    getSortingParams = (page: string) => {
        return this.urlParams.get(page)?.sortingParams ?? new SortingParams();
    };

    getSearchParams = (page: string) => {
        return this.urlParams.get(page)?.searchParams ?? new SearchParams();
    };

    setPaginationParams = (page: string, paginationParams: PaginationParams) => {
        const urlParams = this.urlParams.get(page);
        // This prevents accidental pageSize changes, that causes the UI page jumps
        if (this.defaultPageSizes?.[page]) {
            paginationParams.pageSize = this.defaultPageSizes[page];
        }
        if (urlParams) {
            this.urlParams.set(page, { ...urlParams, paginationParams });
        }
    };

    setSortingParams = (page: string, sortingParams: SortingParams) => {
        const urlParams = this.urlParams.get(page);
        if (urlParams) {
            this.urlParams.set(page, { ...urlParams, sortingParams });
        }
    };

    setSearchParams = (page: string, searchParams: SearchParams) => {
        const urlParams = this.urlParams.get(page);
        if (urlParams) {
            this.urlParams.set(page, { ...urlParams, searchParams });
        }
    };

    reinitializeParams = (pages: string[]) => {
        for (const key of this.urlParams.keys()) {
            if (pages.includes(key)) {
                this.urlParams.set(key, {
                    paginationParams: new PaginationParams(undefined, this.defaultPageSizes?.[key]),
                    sortingParams: new SortingParams(),
                    searchParams: new SearchParams()
                });
            }
        }
    };
}
