import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import {
    QUERYABLE_REMINDER_STATUS,
    ReminderStatusCount,
    Reminder,
    ReminderFilters,
    REMINDER_FILTER
} from '../models/reminder';
import { SearchParams } from '../models/list/search';
import { store } from './store';
import Filters from '../common/filter/filters';

export default class ReminderStore {
    private readonly FILTER_GROUP_ID = 'ReminderList';
    reminders: PaginatedResult<Reminder> | undefined;
    reminderStatusCount?: ReminderStatusCount[];
    loadingReminders = false;

    creatingReminder = false;
    updatingReminder = false;

    reminderStatusFilter: QUERYABLE_REMINDER_STATUS = QUERYABLE_REMINDER_STATUS.ALL;

    reminderFilters = new Filters<keyof ReminderFilters>(
        this.FILTER_GROUP_ID,
        {
            [REMINDER_FILTER.MY_REMINDERS]: [],
            [REMINDER_FILTER.EXCLUDE_AUTO_REMINDERS]: []
        },
        () => this.resetPaginationParams()
    );

    pagingParams = {
        [QUERYABLE_REMINDER_STATUS.DUE]: new PaginationParams(),
        [QUERYABLE_REMINDER_STATUS.OVERDUE]: new PaginationParams(),
        [QUERYABLE_REMINDER_STATUS.UPCOMING]: new PaginationParams(),
        [QUERYABLE_REMINDER_STATUS.ALL]: new PaginationParams()
    };

    searchParams = new SearchParams();

    constructor() {
        makeAutoObservable(this);
    }

    getRemindersListParams = () => {
        const status = this.reminderStatusFilter;
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams[status].pageNumber.toString());
        this.pagingParams[status].pageSize = store.userStore.pageSize;
        params.append('pageSize', this.pagingParams[status].pageSize.toString());

        if (this.searchParams.searchString) {
            params.append('queryText', this.searchParams.searchString!);
        }

        return params;
    };

    setSearchParams = (searchParams: SearchParams) => {
        this.searchParams = searchParams;
    };

    setMyRemindersFilter = (myReminders: boolean) => {
        this.reminderFilters.updateFilter(
            REMINDER_FILTER.MY_REMINDERS,
            myReminders ? ['true'] : []
        );
    };

    setExcludeAutoReminderFilter = (excludeAutoReminders: boolean) => {
        this.reminderFilters.updateFilter(
            REMINDER_FILTER.EXCLUDE_AUTO_REMINDERS,
            excludeAutoReminders ? ['true'] : []
        );
    };

    setPagingParams = (pagingParams: PaginationParams) => {
        this.pagingParams[this.reminderStatusFilter] = pagingParams;
    };

    setReminderStatusFilter = (status: QUERYABLE_REMINDER_STATUS) => {
        this.reminderStatusFilter = status;
    };

    resetPaginationParams = () => {
        this.pagingParams[this.reminderStatusFilter] = new PaginationParams();
    };

    createReminder = async (noteId: string, remindOn: string) => {
        this.creatingReminder = true;
        try {
            const reminder = await agent.Notes.createReminder(noteId, remindOn);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.creatingReminder = false;
            });
        }
    };

    loadRemindersAndStatusCounts = () => {
        this.loadReminderStatusCount();
        this.loadReminders();
    };

    loadReminders = async () => {
        this.loadingReminders = true;
        const params = this.getRemindersListParams();
        params.append('orderBy', 'remindOn');
        if (this.reminderFilters.filters[REMINDER_FILTER.MY_REMINDERS].length) {
            params.append('myReminders', 'true');
        }
        if (this.reminderStatusFilter !== QUERYABLE_REMINDER_STATUS.ALL) {
            params.append('statuses', this.reminderStatusFilter);
        }
        if (this.reminderFilters.filters[REMINDER_FILTER.EXCLUDE_AUTO_REMINDERS].length) {
            params.append('excludeAutoReminders', 'true');
        }

        try {
            const reminders = await agent.Reminders.getAll(params);
            runInAction(() => {
                this.reminders = reminders;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingReminders = false;
            });
        }
    };

    markReminderAsDone = async (reminderId: string) => {
        this.updatingReminder = true;
        try {
            const reminder = await agent.Reminders.do(reminderId);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    undoReminder = async (reminderId: string) => {
        this.updatingReminder = true;
        try {
            const reminder = await agent.Reminders.undo(reminderId);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    editReminder = async (reminderId: string, remindOn: string) => {
        this.updatingReminder = true;
        try {
            const reminder = await agent.Reminders.update(reminderId, remindOn);
            return reminder;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    deleteReminder = async (reminderId: string) => {
        this.updatingReminder = true;
        try {
            await agent.Reminders.delete(reminderId);
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingReminder = false;
            });
        }
    };

    loadReminderStatusCount = async () => {
        this.loadingReminders = true;
        const params = new URLSearchParams();
        if (this.reminderFilters.filters[REMINDER_FILTER.MY_REMINDERS].length) {
            params.append('myReminders', 'true');
        }
        if (this.reminderFilters.filters[REMINDER_FILTER.EXCLUDE_AUTO_REMINDERS].length) {
            params.append('excludeAutoReminders', 'true');
        }
        try {
            const reminderStatusCount = await agent.Reminders.count(params);
            runInAction(() => {
                this.reminderStatusCount = reminderStatusCount;
            });
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingReminders = false;
            });
        }
    };
}
