import {
    DownloadOutlined,
    FileTextOutlined,
    IssuesCloseOutlined,
    PrinterOutlined,
    SendOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { Badge, Button, Result, Segmented, Select, SelectProps, Space, Typography } from 'antd';
import { groupBy, truncate } from 'lodash';
import mime from 'mime';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';
import { getFormattedDate } from '../../../app/common/utils/datetime';
import { printDocumentBlob } from '../../../app/common/utils/file';
import { Client, ClientStatement } from '../../../app/models/client';
import { Matter } from '../../../app/models/matter';
import { useStore } from '../../../app/stores/store';
import InvoiceNeedsAttentionIndicator from '../../invoices/indicators/InvoiceNeedsAttentionIndicator';
import useStatementActions from '../../statements/hooks/useStatementActions';
import useCache from '../hooks/useCache';
import MenuLayout from '../MenuLayout';
import StatementViewer from './StatementViewer';
import './styles.css';

export enum STATEMENT_OWNER_TYPE {
    CLIENT = 'client',
    MATTER = 'matter'
}

type Props = {
    ownerType: STATEMENT_OWNER_TYPE;
} & (
    | {
          ownerType: STATEMENT_OWNER_TYPE.CLIENT;
          client: Client;
      }
    | { ownerType: STATEMENT_OWNER_TYPE.MATTER; matter: Matter }
);

// TODO: A better refctoring TBD in CBH-1730
export default observer(function Statements(props: Props) {
    const { ownerType } = props;
    const {
        statementStore: { getStatementDocument, loadingStatementDocument, getStatements, loading },
        matterStore: { getMatter }
    } = useStore();
    const {
        handleClearError,
        handleSendClientStatement,
        handleSendMatterStatement,
        handleShareStatement
    } = useStatementActions();
    const [statements, setStatements] = useState<ClientStatement[]>([]);
    const statementCache = useCache<ClientStatement>();
    const matterCache = useCache<Matter>();
    const statementBlobUrlCache = useCache<string>();
    const [currentStatementId, setCurrentStatementId] = useState<string | null>(null);
    const [currentStatementDocumentBlobUrl, setCurrentStatementDocumentBlobUrl] = useState<
        string | null
    >(null);
    const [statementType, setStatementType] = useState(
        ownerType === STATEMENT_OWNER_TYPE.CLIENT
            ? STATEMENT_OWNER_TYPE.CLIENT
            : STATEMENT_OWNER_TYPE.MATTER
    );

    const clientId =
        ownerType === STATEMENT_OWNER_TYPE.CLIENT
            ? props.client.clientId
            : props.matter.client.clientId;

    const canSendStatement =
        (props.ownerType === STATEMENT_OWNER_TYPE.CLIENT && props.client.canSendStatement) ||
        (props.ownerType === STATEMENT_OWNER_TYPE.MATTER && props.matter.canSendStatement);

    // Filter the respective statements based on the owner type
    const { clientStatements, matterStatements } = useMemo(() => {
        const clientStatements =
            statements.filter((statement) => statement.matterId === null) ?? [];
        const matterStatements =
            statements.filter(
                (statement) =>
                    statement.matterId !== null &&
                    (ownerType === STATEMENT_OWNER_TYPE.MATTER
                        ? statement.matterId === props.matter.matterId
                        : true)
            ) ?? [];
        return { clientStatements, matterStatements };
    }, [statements]);

    // Select options for ClientStatements and Matter Statements list
    const clientStatementSelectOptions = useMemo(() => {
        const options: SelectProps['options'] = clientStatements.map((statement) => ({
            value: statement.statementId,
            label: (
                <StatementSelectLabel
                    label={getFormattedDate(statement.statementMonth, 'MMM YYYY')}
                    statement={statement}
                />
            )
        }));

        return options;
    }, [statements]);

    const matterStatementOptions = useMemo(() => {
        let items: SelectProps['options'] = [];
        if (ownerType === STATEMENT_OWNER_TYPE.CLIENT) {
            items = Object.entries(
                groupBy(matterStatements, (statement) =>
                    getFormattedDate(statement.statementMonth, 'MMM YYYY')
                )
            ).map(([month, statements]) => ({
                label: month,
                options: statements
                    .sort((a, b) =>
                        !a.matterId ? 1 : !b.matterId ? -1 : a.matterId.localeCompare(b.matterId)
                    )
                    .map((statement) => ({
                        value: statement.statementId,
                        label: (
                            <StatementSelectLabel
                                label={
                                    statement.matterId +
                                    ' - ' +
                                    truncate(statement.matterName!, { length: 22 })
                                }
                                statement={statement}
                            />
                        )
                    }))
            }));
        } else {
            items = matterStatements.map((statement) => ({
                value: statement.statementId,
                label: (
                    <StatementSelectLabel
                        label={getFormattedDate(statement.statementMonth, 'MMM YYYY')}
                        statement={statement}
                    />
                )
            }));
        }
        return items;
    }, [statements]);

    const loadStatements_ = async () => {
        const getStatementParams =
            ownerType === STATEMENT_OWNER_TYPE.CLIENT
                ? { ownerType, clientId }
                : { ownerType, clientId, matterId: props.matter.matterId };
        const statements = await getStatements(getStatementParams);
        if (statements) {
            statementCache.reset(
                new Map(statements.map((statement) => [statement.statementId, statement]))
            );
            setStatements(statements);
        }
    };

    useEffect(() => {
        loadStatements_();
    }, []);

    useEffect(() => {
        if (currentStatementId) {
            const statement = statementCache.get(currentStatementId);
            if (statement) {
                loadDocumentAndUpdateState(statement);
            }
            return;
        }

        // Load the first viewable statement document
        if (statementType === STATEMENT_OWNER_TYPE.CLIENT && clientStatements.length) {
            const firstStatement = clientStatements[0];
            loadDocumentAndUpdateState(firstStatement);
        } else if (statementType === STATEMENT_OWNER_TYPE.MATTER && matterStatements.length) {
            const firstStatement = matterStatements[0];
            loadDocumentAndUpdateState(firstStatement);
        }
    }, [statements]);

    // When navigation between the statements, we fetch the document for preview
    // and update the current statementId and blobURL
    const loadDocumentAndUpdateState = async (statement: ClientStatement) => {
        const blobUrl = statementBlobUrlCache.get(statement.statementId);
        if (blobUrl) {
            setCurrentStatementDocumentBlobUrl(blobUrl);
        } else {
            const statementDocument = await getStatementDocument(statement.statementId);
            if (statementDocument) {
                const type = mime.getType(statement.statementDocumentName) ?? 'application/pdf';
                const blobUrl = URL.createObjectURL(new Blob([statementDocument], { type: type }));
                statementBlobUrlCache.add(statement.statementId, blobUrl);
                setCurrentStatementDocumentBlobUrl(blobUrl);
            } else {
                setCurrentStatementDocumentBlobUrl(null);
            }
        }
        setCurrentStatementId(statement.statementId);
    };

    const handleSendStatement = async (statement?: ClientStatement) => {
        const availableStatements = statement ? [statement] : [];
        if (
            statementType === STATEMENT_OWNER_TYPE.CLIENT &&
            ownerType === STATEMENT_OWNER_TYPE.CLIENT
        ) {
            handleSendClientStatement(
                props.client,
                availableStatements,
                statement?.statementMonth,
                !!statement?.error,
                loadStatements_
            );
        } else {
            const matter =
                ownerType === STATEMENT_OWNER_TYPE.MATTER
                    ? props.matter
                    : statement
                      ? matterCache.get(statement.matterId!) ??
                        (await getMatter(clientId, statement.matterId!))
                      : null;
            if (statement && matter) {
                matterCache.add(statement.matterId!, matter);
            } else if (statement && !matter) {
                return;
            }

            handleSendMatterStatement(
                matter!,
                availableStatements,
                statement?.statementMonth,
                !!statement?.error,
                loadStatements_
            );
        }
    };

    const getStatementActions = () => {
        if (!currentStatementId) {
            return null;
        }
        const statement = statementCache.get(currentStatementId);
        if (currentStatementDocumentBlobUrl && statement) {
            return (
                <Space>
                    <Button
                        icon={<PrinterOutlined />}
                        type='text'
                        title='Print'
                        onClick={() => printDocumentBlob(currentStatementDocumentBlobUrl)}
                    />
                    <Button
                        icon={<DownloadOutlined />}
                        type='text'
                        title='Download'
                        href={currentStatementDocumentBlobUrl}
                        download={statement.statementDocumentName}
                    />
                    {statement.canShare && (
                        <Button
                            icon={<ShareAltOutlined />}
                            type='default'
                            title='Share with Client'
                            onClick={() =>
                                handleShareStatement(currentStatementId, statement?.recipients)
                            }
                        >
                            Share
                        </Button>
                    )}
                    {canSendStatement && statement.canSend && (
                        <Button
                            icon={<SendOutlined />}
                            type='primary'
                            title='Send Statement to Client'
                            onClick={() => handleSendStatement(statement)}
                        >
                            Send
                        </Button>
                    )}

                    {statement.canClearError && (
                        <Button
                            icon={<IssuesCloseOutlined />}
                            type='primary'
                            title='Clear Error'
                            onClick={() => handleClearError(statement, loadStatements_)}
                        >
                            Clear Error
                        </Button>
                    )}
                </Space>
            );
        }
    };

    const handleStatementChange: SelectProps['onChange'] = (value) => {
        const clientStatement = statementCache.get(value);
        if (clientStatement) {
            loadDocumentAndUpdateState(clientStatement);
        } else {
            setCurrentStatementDocumentBlobUrl(null);
            setCurrentStatementId(null);
        }
    };

    const handleStatementTypeChange = (value: STATEMENT_OWNER_TYPE) => {
        // When we switch between the types, we do the easier thing and
        // set the current statement to first available statement in the respective types
        const firstStatement =
            value === STATEMENT_OWNER_TYPE.CLIENT ? clientStatements[0] : matterStatements[0];
        if (firstStatement) {
            loadDocumentAndUpdateState(firstStatement);
        } else {
            setCurrentStatementDocumentBlobUrl(null);
            setCurrentStatementId(null);
        }
        setStatementType(value);
    };

    const hasViewableStatement = currentStatementId && currentStatementDocumentBlobUrl;

    const noStatementMessage =
        statementType === STATEMENT_OWNER_TYPE.CLIENT
            ? 'No statements sent to the client from here yet'
            : 'No Matter statements sent to client yet';

    const showSendStatementButton =
        canSendStatement &&
        (ownerType === STATEMENT_OWNER_TYPE.CLIENT
            ? statementType === STATEMENT_OWNER_TYPE.CLIENT
            : true);

    return (
        <MenuLayout
            title={
                <Space>
                    {ownerType === STATEMENT_OWNER_TYPE.CLIENT ? (
                        <Segmented
                            options={[
                                {
                                    label: 'Client',
                                    value: STATEMENT_OWNER_TYPE.CLIENT
                                },
                                {
                                    label: 'Matter',
                                    value: STATEMENT_OWNER_TYPE.MATTER
                                }
                            ]}
                            onChange={handleStatementTypeChange}
                        />
                    ) : (
                        <Typography.Text>Statements</Typography.Text>
                    )}
                    {currentStatementId && (
                        <Select
                            options={
                                statementType === STATEMENT_OWNER_TYPE.CLIENT
                                    ? clientStatementSelectOptions
                                    : matterStatementOptions
                            }
                            style={{
                                width:
                                    statementType === STATEMENT_OWNER_TYPE.CLIENT ||
                                    ownerType === STATEMENT_OWNER_TYPE.MATTER
                                        ? 130
                                        : 300
                            }}
                            value={currentStatementId}
                            onChange={handleStatementChange}
                        />
                    )}
                </Space>
            }
            childrenPadding='0px'
            showScrollBar={false}
            loading={loadingStatementDocument || loading}
            actions={getStatementActions()}
        >
            {hasViewableStatement ? (
                <StatementViewer url={currentStatementDocumentBlobUrl} />
            ) : (
                <Result
                    icon={<FileTextOutlined style={{ color: '#bfbfbf' }} />}
                    subTitle={noStatementMessage}
                    extra={
                        showSendStatementButton && (
                            <Button
                                onClick={() => handleSendStatement()}
                                type='text'
                                icon={<SendOutlined />}
                            >
                                Send Statement
                            </Button>
                        )
                    }
                />
            )}
        </MenuLayout>
    );
});

const StatementSelectLabel = ({
    label,
    statement
}: {
    label: string;
    statement: ClientStatement;
}) => {
    return (
        <Space>
            <Badge
                dot={!!statement.canSend && !statement.error}
                color={ThemeColor.ColorInfo}
                offset={[5, 5]}
            >
                <TooltipComponent title={statement.matterName}>{label}</TooltipComponent>
            </Badge>
            <InvoiceNeedsAttentionIndicator error={statement.error} />
        </Space>
    );
};
