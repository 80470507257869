import { PaginatedResult } from '../models/list/pagination';
import { Statement, StatementsSubmissionResult } from '../models/statement';
import { requests } from './axiosAgent';

const Statements = {
    BASE_URL: '/statements',
    getStatementidentifierUrl: (statementId: string) => `${Statements.BASE_URL}/${statementId}`,
    list: (params?: URLSearchParams) =>
        requests.get<PaginatedResult<Statement>>(Statements.BASE_URL, { params }),
    sendStatement: (formData: FormData) =>
        requests.post<void>(`${Statements.BASE_URL}/_send`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
    share: (
        statementId: string,
        body: {
            ccCurrentUser: boolean | null;
            ccBillingTimekeeper: boolean | null;
            ccCollectionTimekeeper: boolean | null;
            emailMessage?: string | null;
            emailRecipients: string[] | null;
        }
    ) =>
        requests.post<void>(`${Statements.getStatementidentifierUrl(statementId)}/_share`, {
            ...body
        }),
    getDocument: (statementId: string) =>
        requests.get<Blob>(
            `${Statements.getStatementidentifierUrl(statementId)}/documents/statement`,
            {
                responseType: 'blob'
            }
        ),
    bulkSendStatements: (body: {
        checkOnly: boolean;
        clientIds: string[];
        includeMatterStatements: boolean | null;
        statementMonth: string;
        setStatusOnly: boolean;
        sentOn: string | null;
    }) =>
        requests.post<StatementsSubmissionResult>(`${Statements.BASE_URL}/_bulksend`, { ...body }),
    clearError: (statementIds: string[], tryMarkAsSent: boolean | null, sentOn: string | null) =>
        requests.post<void>(`${Statements.BASE_URL}/_clearerror`, {
            statementIds,
            tryMarkAsSent,
            sentOn
        })
};

export default Statements;
