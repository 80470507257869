import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useStore } from '../../stores/store';
import './ModalContainer.css';

export default observer(function ModalContainer() {
    const { modalStore } = useStore();
    const [processing, setProcessing] = useState<boolean>(false);
    const draggleRef = useRef<HTMLDivElement>(null);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (modalStore.open) {
            setModalPosition({ x: 0, y: 0 });
        }
    }, [modalStore.open]);

    return (
        <Modal
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    position={modalPosition}
                    onDrag={(_, ui) => {
                        setModalPosition({ x: ui.x, y: ui.y });
                    }}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            centered
            footer={modalStore?.modalProps?.footer}
            open={modalStore.open}
            onCancel={() => {
                if (modalStore.modalProps?.onCancel) {
                    modalStore.modalProps?.onCancel();
                }
                modalStore.closeModal();
            }}
            afterClose={() => {
                setProcessing(false);
            }}
            width={modalStore?.modalProps?.width || undefined}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    {modalStore.modalProps?.title}
                </div>
            }
            onOk={() => {
                setProcessing(true);
                modalStore.modalProps?.onOk?.();
            }}
            okType={modalStore.modalProps?.okType}
            okText={modalStore.modalProps?.okText ?? 'OK'}
            okButtonProps={{
                disabled: modalStore.isOkDisabled,
                danger: modalStore.modalProps?.isOkDanger,
                type: modalStore.modalProps?.okType ?? 'primary'
            }}
            cancelButtonProps={{ disabled: processing }}
            confirmLoading={processing}
            closeIcon={modalStore.modalProps?.closeIcon}
            maskClosable={!!modalStore.modalProps?.easyClose}
            keyboard={!!modalStore.modalProps?.easyClose}
            classNames={{ header: 'modal-header' }}
            // Since we are not utilizing the closed modal (atleast for now / will remain this way)
            // It is better we desctroy the modal completely else, the cleanup functions of the
            // component rendered in a modal will be delayed.
            destroyOnClose
        >
            {modalStore.modalProps?.verticalScroll ? (
                <div
                    className='modified-scroll-bar-hover'
                    style={{ maxHeight: '70vh', overflow: 'auto' }}
                >
                    {modalStore?.modalProps?.body}
                </div>
            ) : (
                modalStore?.modalProps?.body
            )}
        </Modal>
    );
});
