import { useEffect, useRef, useState } from 'react';
import { Highlight, PdfHighlighter, PdfLoader, Popup, IHighlight } from 'react-pdf-highlighter';
import './styles.css';
import { toJS } from 'mobx';
import Tip from './Tip';

export type Props = {
    url: string;
    annotations?: IHighlight[];
    allowAnnotations: boolean;
    onError: () => void;
    onAnnotation?: (updatedAnnotations: string) => Promise<void>;
};
export default function PdfAnnotator(props: Props) {
    const { url, annotations, allowAnnotations, onError, onAnnotation } = props;
    const [highlights, setHighlights] = useState<IHighlight[]>([]);
    /* eslint-disable-next-line */
    const scrollToRef = useRef((_: IHighlight) => {});

    const getHighlightById = (id: string) => {
        return highlights.find((highlight) => highlight.id === id);
    };

    const resetHash = () => {
        document.location.hash = '';
    };

    const scrollToHighlightFromHash = () => {
        const highlight = getHighlightById(parseIdFromHash());

        if (highlight) {
            scrollToRef.current(highlight);
        }
    };

    const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length);

    const addHighlight = async (highlight: IHighlight) => {
        const list = [{ ...highlight, time: new Date() }, ...highlights];
        setHighlights(list);
        await onAnnotation?.(JSON.stringify(list));
    };

    const getNextId = () => String(Math.random()).slice(2);

    useEffect(() => {
        setHighlights(toJS(annotations ?? []));
    }, [annotations]);

    const HighlightPopup = ({ comment }: { comment: IHighlight['comment'] }) =>
        comment?.text ? <div className='highlight__popup'>{comment?.text}</div> : null;

    return (
        <PdfLoader
            url={url}
            cMapUrl={'cmaps/'}
            cMapPacked={true}
            beforeLoad={<></>}
            onError={onError}
        >
            {(pdfDocument) => (
                <PdfHighlighter
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.altKey}
                    onScrollChange={() => {
                        resetHash();
                    }}
                    scrollRef={(scrollTo) => {
                        scrollToRef.current = scrollTo;
                        scrollToHighlightFromHash();
                    }}
                    onSelectionFinished={(
                        position,
                        content,
                        hideTipAndSelection,
                        transformSelection
                    ) =>
                        allowAnnotations ? (
                            <Tip
                                onOpen={transformSelection}
                                onConfirm={(comment) => {
                                    addHighlight({
                                        content,
                                        position,
                                        comment: { text: comment, emoji: '' },
                                        id: getNextId()
                                    });
                                    hideTipAndSelection();
                                }}
                            />
                        ) : null
                    }
                    highlightTransform={(highlight, index, setTip, hideTip, isScrolledTo) => {
                        return (
                            <Popup
                                popupContent={<HighlightPopup comment={highlight.comment} />}
                                onMouseOver={(popupContent) =>
                                    setTip(highlight, () => popupContent)
                                }
                                onMouseOut={hideTip}
                                key={index}
                            >
                                <Highlight
                                    isScrolledTo={!!isScrolledTo}
                                    position={highlight.position}
                                    comment={highlight.comment}
                                />
                            </Popup>
                        );
                    }}
                    highlights={highlights}
                />
            )}
        </PdfLoader>
    );
}
