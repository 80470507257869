import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { router } from '../../../index';
import EmptyPageLoader from '../../../app/common/components/EmptyPageLoader';
import { useStore } from '../../../app/stores/store';

export default observer(function SSORedirect() {
    const { userStore } = useStore();

    useEffect(() => {
        if (!userStore.isLoggedIn) {
            userStore
                .getSSOUrl()
                .then((ssoUrl) => {
                    location.assign(ssoUrl);
                })
                .catch((error) => {
                    console.log('Error while fetching SSO URL: ', error);
                    router.navigate('/server-error');
                });
        }
    }, []);
    return <EmptyPageLoader label='Loading...' />;
});
