import { ActionFormCompoundItemProps } from '../ActionForm';
import { CC_USER } from '../../../../models/client';
import CcUsersCheckboxGroup from '../../../../../features/invoices/hooks/components/CcUsersCheckboxGroup';

type Props = {
    bulkAction: boolean;
    ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[];
    billingTimekeeperName?: string | null;
    collectionTimekeeperName?: string | null;
} & ActionFormCompoundItemProps;

export default function ActionFormCcUsers(props: Props) {
    const { onChange } = props;
    const handleCCUsersChange = (ccUsers: CC_USER[]) => {
        onChange?.(ccUsers.length ? ccUsers : null);
    };
    return <CcUsersCheckboxGroup {...props} onChange={handleCCUsersChange} />;
}
