import { Checkbox, Space } from 'antd';
import TooltipIcon from '../../../../app/common/components/TooltipIcon';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import { PersonaString } from '../../../../app/common/constants/invoice';
import { CC_USER } from '../../../../app/models/client';

type Props = {
    onChange: (checkedValues: CC_USER[]) => void;
    bulkAction: boolean;
    ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[];
    billingTimekeeperName?: string | null;
    collectionTimekeeperName?: string | null;
};

export default function CcUsersCheckboxGroup(props: Props) {
    const { onChange, billingTimekeeperName, collectionTimekeeperName, ccTo } = props;
    const ccUsersCheckboxOptions = [{ label: 'Me', value: CC_USER.CURRENT_USER }];
    if (ccTo.includes(CC_USER.BILLER)) {
        ccUsersCheckboxOptions.push({ label: PersonaString.Biller, value: CC_USER.BILLER });
    }
    if (ccTo.includes(CC_USER.BILLING_TIMEKEEPER)) {
        ccUsersCheckboxOptions.push({
            label: billingTimekeeperName ?? PersonaString.BillingTimekeeper,
            value: CC_USER.BILLING_TIMEKEEPER
        });
    }
    if (ccTo.includes(CC_USER.COLLECTION_TIMEKEEPER)) {
        ccUsersCheckboxOptions.push({
            label: collectionTimekeeperName ?? PersonaString.CollectionTimekeeper,
            value: CC_USER.COLLECTION_TIMEKEEPER
        });
    }

    return (
        <Space direction='vertical' size={0}>
            <Space>
                <FormLabel label='Email copy:' />
                <TooltipIcon title={`The client's email won't include these recipients.`} />
            </Space>
            <Checkbox.Group
                onChange={(checkedValues) => onChange(checkedValues as CC_USER[])}
                options={ccUsersCheckboxOptions}
            />
        </Space>
    );
}
