export enum EMAIL_TEMPLATE_TYPE {
    CLIENT_REMINDER_EMAIL = 'ClientReminderEmail',
    INVOICE_SUBMISSION_EMAIL = 'InvoiceSubmissionEmail'
}

export interface EmailTemplate {
    templateType: EMAIL_TEMPLATE_TYPE;
    placeholderName: string;
    name: string;
    value: string | null;
}
