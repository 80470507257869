type Filter<T extends string> = Record<T, string[]>;

export default class Filters<Key extends string> {
    private STORAGE_KEY: string;
    filters: Filter<Key>;

    // Will be triggered when the filter values are updated
    onUpdateCallback?: () => void;

    constructor(groupId: string, defaultFilters: Filter<Key>, onUpdateCallback?: () => void) {
        this.STORAGE_KEY = `${groupId}Filter`;
        this.filters = defaultFilters;
        this.onUpdateCallback = onUpdateCallback;
        this.initialize();
    }

    private initialize() {
        const storedFilters = localStorage.getItem(this.STORAGE_KEY);
        if (storedFilters) {
            const filters = JSON.parse(storedFilters) ?? {};
            this.filters = { ...this.filters, ...filters };
        }
    }

    updateFilter(key: Key, values: string[], persist = true) {
        this.filters[key] = values;

        if (persist) {
            // Get the local storage object, since the app filters might contain,
            // values which we should not save locally.
            const savedFilters = localStorage.getItem(this.STORAGE_KEY) ?? '{}';
            if (savedFilters) {
                const filterValues = JSON.parse(savedFilters);
                filterValues[key] = values;
                localStorage.setItem(this.STORAGE_KEY, JSON.stringify(filterValues));
            }
        }
        this.onUpdateCallback?.();
    }

    clearAllFilters() {
        for (const key in this.filters) {
            this.filters[key] = [];
        }
        this.onUpdateCallback?.();
        localStorage.removeItem(this.STORAGE_KEY);
    }
}
