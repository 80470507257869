import { Typography } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { trim, trimRichTextNewLines } from '../../utils/string';
import './styles.css';

const { Text } = Typography;

const registerDivAsQuillBlockBlot = () => {
    try {
        const Quill = ReactQuill.Quill;
        const Block = Quill.import('blots/block');
        Block.tagName = 'DIV';
        Quill.register(Block);
    } catch (err) {
        console.log(`ReactQuill: Unable to register 'div' tag as block blot.`);
    }
};

// By default, Quill uses, 'p' tag as the Block blot. i.e the one that wraps
// the plain text content. We change it to 'div' as 'p' tag causes stylisting issues
// on the email clients.
registerDivAsQuillBlockBlot();

export type Props = {
    defaultValue?: string;
    // The trimmedRawTextLen gives you exact len of trimmed text, which
    // can be used for additional validation
    onChange?: (html: string, trimmedRawTextLen: number) => void;
    // Passing this will automatically show the counter.
    // We could however have another prop to indicate the visibility,
    // but that's unnecessary as in the application we are showing the
    // counter where ever there is character restriction.
    maxLen?: number;
    placeholder?: string;
    autoFocus?: boolean;
};

export default function RichTextEditor(props: Props) {
    const { defaultValue, onChange, maxLen, placeholder, autoFocus = true } = props;
    const quillRef = useRef<ReactQuill | null>(null);
    const [textLength, setTextLength] = useState<number>(0);

    useEffect(() => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            editor.on('text-change', () => {
                // Ignore the newline character added by the editor while
                // getting the count
                const unpreviligedTextLen = editor.getText().length - 1;
                if (maxLen && unpreviligedTextLen > maxLen) {
                    // If user tries to enter more than the maxLen or Ctrl+V's
                    // we will trim it to maxLen (just like antd Text area)
                    editor.deleteText(maxLen, unpreviligedTextLen - maxLen);
                }
                // Set the cursor to the last character
                editor.setSelection(editor.getLength(), 0);
                setTextLength(editor.getText().length - 1);
            });
            setTextLength(editor.getText().length - 1);
            if (autoFocus) {
                editor.setSelection(editor.getLength(), 0);
            }
        }

        return () => {
            quillRef.current?.destroyEditor();
        };
    }, []);

    useEffect(() => {
        if (defaultValue) {
            handleEdit(defaultValue);
        }
    }, [defaultValue]);

    const handleEdit = (value: string) => {
        const unpriviligedText = quillRef.current?.editor?.getText();
        onChange?.(
            trimRichTextNewLines(value),
            unpriviligedText ? trim(unpriviligedText).length : 0
        );
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        return false;
    };

    const { toolbarOptions, formats } = useMemo(() => {
        const toolbarOptions = [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }]
        ];
        const formats = ['bold', 'italic', 'underline', 'list'];
        return { toolbarOptions, formats };
    }, []);

    return (
        <div onDragOver={handleDragOver}>
            <ReactQuill
                theme='snow'
                className='rich-text__editor'
                defaultValue={defaultValue}
                onChange={(value) => handleEdit(value)}
                ref={quillRef}
                modules={{
                    toolbar: toolbarOptions
                }}
                preserveWhitespace
                placeholder={placeholder}
                formats={formats}
            />
            {maxLen && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Text type='secondary'>
                        {textLength} / {maxLen}
                    </Text>
                </div>
            )}
        </div>
    );
}
