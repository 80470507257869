import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export const getDaysSince = (date: Date | null | undefined) => {
    if (!date) {
        return '';
    }

    const daysSince = dayjs().startOf('day').diff(date, 'day');
    if (daysSince < 1) {
        return '';
    }
    return `${daysSince} ${daysSince === 1 ? 'day' : 'days'}`;
};

export const getFormattedDate = (date: Date | null | undefined, format: string) => {
    return date ? dayjs(date).format(format) : '';
};

// Return relative time in minimum day units.
// Never return relative time in seconds, minutes or hours.
export const getRelativeDay = (date: Date) => {
    const diffDays = dayjs(date).startOf('day').diff(dayjs().startOf('day'), 'day');

    if (diffDays === -1) {
        return 'Yesterday';
    } else if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return 'Tomorrow';
    }

    return getRelativeTime(date);
};

// TODO: In most cases, we are type casting the date properties retuned by backend as Date.
// But it is actually a string. We should fix these.
export const getRelativeTime = (date: Date | null | undefined | string, withoutSuffix = false) => {
    if (!date) {
        return '';
    }
    dayjs.extend(relativeTime);
    return dayjs().to(dayjs(date), withoutSuffix);
};

export const getNextWeekday = () => {
    const nextDate = dayjs().add(1, 'day'); // Start with tomorrow

    if (nextDate.day() === 6) {
        return nextDate.add(2, 'day'); // Saturday + 2
    } else if (nextDate.day() === 0) {
        return nextDate.add(1, 'day'); // Sunday + 1
    } else {
        return nextDate;
    }
};

export const isPastDueDate = (dueDate?: Date | string | null) => {
    const remindOn_ = dayjs(dueDate).startOf('day');
    const today = dayjs().startOf('day');
    return remindOn_.diff(today) < 0;
};

export const getExpirationInMs = (expirationTimestamp: number) => {
    return dayjs(expirationTimestamp).diff(dayjs());
};

export const getExpirationTimestamp = (seconds: number) => {
    return dayjs().add(seconds, 'second').valueOf();
};
