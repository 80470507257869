import { ExclamationCircleFilled, InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { ThemeColor } from '../constants/color';
import TooltipComponent from './TooltipComponent';

type Props = {
    title?: string | ReactNode;
    style?: React.CSSProperties | undefined;
    type?: 'error' | 'insight' | 'warning' | undefined; // more specific states can be added here based on intention.
};

export default function TooltipIcon({ title, style, type }: Props) {
    return (
        <TooltipComponent title={title}>
            {type === 'error' ? (
                <ExclamationCircleFilled
                    style={{
                        color: ThemeColor.ColorError,
                        ...style
                    }}
                />
            ) : type === 'warning' ? (
                <ExclamationCircleFilled
                    style={{
                        color: ThemeColor.ColorWarning,
                        ...style
                    }}
                />
            ) : type === 'insight' ? (
                <InfoCircleFilled
                    style={{
                        color: ThemeColor.ColorPrimary,
                        ...style
                    }}
                />
            ) : (
                <InfoCircleOutlined
                    style={{
                        color: ThemeColor.PrimaryGrey,
                        ...style
                    }}
                />
            )}
        </TooltipComponent>
    );
}
