export class Cache<T> {
    private memo: Map<string, T> = new Map<string, T>();
    constructor(values?: Map<string, T>) {
        if (values) {
            this.memo = values;
        }
    }
    add = (key: string, value: T) => {
        this.memo.set(key, value);
    };
    clear = () => this.memo.clear();
    get = (key: string) => this.memo.get(key);
    remove = (key: string) => this.memo.delete(key);
    entries = () => this.memo.entries();
    reset = (values: Map<string, T>) => (this.memo = values);
}
