import { EmailTemplate } from '../models/template';
import { requests } from './axiosAgent';

const EmailTemplateValues = {
    BASE_URL: '/emailtemplatevalues',
    getEmailTemplates: (params: URLSearchParams) =>
        requests.get<EmailTemplate[]>(EmailTemplateValues.BASE_URL, { params })
};

export default EmailTemplateValues;
