import { trim } from 'lodash';

export const splitEmails = (emails: string) => {
    // This will split the emails using [;] and [,] as separators
    const emailList = trim(emails, ';,').split(/[;,]/);
    const uniqueEmailList = new Set(emailList);
    return [...uniqueEmailList];
};

export const checkEmailValidity = (email: string) => {
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
};
