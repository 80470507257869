import { useEffect, useState } from 'react';
import PdfAnnotator from '../../../app/common/components/pdfAnnotator/PDFAnnotator';
import { Result } from 'antd';

type Props = {
    url?: string;
};

export default function StatementViewer(props: Props) {
    const [error, setError] = useState(false);
    const { url } = props;

    useEffect(() => {
        // Since the previous state is retained during re-render,
        // we have to unset the error when the url changes
        setError(false);
    }, [url]);

    if (!url) {
        return null;
    }

    return (
        <div id='statement-document__container' key={`statement-viewer-${url}`}>
            {error ? (
                <Result
                    status='warning'
                    title='Preview failed'
                    subTitle='Please download the document to view'
                />
            ) : (
                <PdfAnnotator url={url} allowAnnotations={false} onError={() => setError(true)} />
            )}
        </div>
    );
}
