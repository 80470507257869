import { INVOICE_ACTION_WARNING, PERSONA } from '../../models/invoice';

// These placeholders will be refactored when we introduce i18n
export const InvoiceActionWarningString = {
    [INVOICE_ACTION_WARNING.CANNOT_SUBMIT]:
        'One or more invoices cannot be submitted due to being either third-party payor invoices, missing the invoice document, or lacking recipient email addresses',
    [INVOICE_ACTION_WARNING.CANNOT_SEND_FOR_REVIEW]:
        'One or more invoices cannot be sent for review',
    [INVOICE_ACTION_WARNING.CANNOT_MARK_AS_SUBMITTED]:
        'One or more invoices are third-party payor invoices and cannot be marked as submitted',
    [INVOICE_ACTION_WARNING.CANNOT_COMPLETE_REVIEW]:
        'One or more invoices cannot be completed as reviewed'
} as const;

export const PersonaString = {
    [PERSONA.BILLING_TIMEKEEPER]: 'Billing Timekeeper',
    [PERSONA.COLLECTION_TIMEKEEEPER]: 'Collection Timekeeper',
    [PERSONA.BILLER]: 'Biller'
};
