import { ACTIVITY_EVENT_TYPE, Activity } from './activity';
import { Currency, INVOICE_TYPE } from './invoice';
import { KeyName } from './keyValuePair';
import { Note } from './note';
import { PaymentPlan } from './paymentPlan';
import { Person } from './person';
import { StatementForeignCurrency } from './statement';

export enum PROVISIONING_ACTIONS {
    ENABLE_ACCESS = 'EnableAccess',
    DISABLE_ACCESS = 'DisableAccess',
    RESEND_INVITE = 'ResendInvite'
}

export enum CLIENT_FILTER_BY_AR {
    AROTO30 = 'ar0To30',
    AR31TO60 = 'ar31To60',
    AR61TO90 = 'ar61To90',
    AR91TO120 = 'ar91To120',
    AR121PLUS = 'ar121Plus'
}

export type ClientTypeByAR = 'ar0To30' | 'ar31To60' | 'ar61To90' | 'ar91To120' | 'ar121Plus';

export interface ClientContact {
    contactType: KeyName;
    person: Person;
}

export interface Client {
    alertMessage: string | null;
    attachDocumentsToEmail: boolean;
    clientId: string;
    name: string;
    billToEmails: string[] | null;
    billerId: string | null;
    billerName: string | null;
    billToEmailsEditable: boolean;
    billingTimekeeperId: string | null;
    billingTimekeeperName: string | null;
    collectionTimekeeperId: string | null;
    collectionTimekeeperName: string | null;
    collectorId: string | null;
    collectorName: string | null;
    canSendStatement: boolean | null;
    canShareWithTimekeeper: boolean | null;
    computedStatementRecipients: string[] | null;
    canEdit: boolean | null;
    contacts: ClientContact[];
    coverCardFees: boolean;
    disallowCardPayment: boolean;
    currency: Currency;
    provisioningStatus: 'Active' | 'Pending' | 'Disabled';
    paymentPlan: PaymentPlan | null;
    contactEmail?: string;
    error: string | null;
    doNotContact: boolean;
    doNotSendStatement: boolean;
    defaultInvoiceType: INVOICE_TYPE | null;
    paginationInfo: {
        totalCount: number | null;
    };
    metrics: ClientMetrics | null;
    modifiedOn: string | null;
    activities: ClientActivity[];
    office: KeyName;
    payNowEnabled: boolean | null;
    status: KeyName;
    notes?: Note[];
    eBillingVendor: KeyName | null;
    eBillingVendorUrl: string | null;
    foreignCurrency: ClientForeignCurrency;
    statements: ClientStatement[];
    reminderDue: boolean;
    lastInvoicedOn: Date | null;
    lastPaidOn: Date | null;
    lastStatementSentOn: Date | null;
    lastActionOn: Date | null;
    lastActionName: string | null;
    wipAmount: number | null;
    wipAsOfOn: Date | null;
    feeArrangement: KeyName | null;
    billingInstructions: string | null;
    tagNames: string[];
    unappliedCash: number | null;
    trustAmount: number | null;
    paymentTermsInDays: number | null;
    statementEmails: string[] | null;
    totalBilledAmount: number;
    totalCollectedAmount: number;
    totalWorkAmount: number;
    writeOffAmount: number | null;
}

export type ClientIdentifier = {
    clientId: string;
    name: string;
};

export type CLIENT_EVENT_TYPE = ACTIVITY_EVENT_TYPE.EVENT | ACTIVITY_EVENT_TYPE.MODIFIED;

export type ClientActivity = Activity & {
    eventType: CLIENT_EVENT_TYPE;
};

export interface ClientStatement {
    amount: number;
    canDownload: boolean | null;
    canShare: boolean | null;
    canSend: boolean | null;
    canClearError: boolean | null;
    canMarkAsSent: boolean | null;
    createdById: string;
    createdOn: Date;
    error: string | null;
    foreignCurrency: StatementForeignCurrency;
    id: string;
    recipients: string[] | null;
    modifiedById: string;
    modifiedOn: Date;
    matterId: string | null;
    matterName: string | null;
    statementDocumentAttachmentsCount: number;
    statementDocumentName: string;
    statementId: string;
    statementMonth: Date;
    status: string;
    statusName: string;
}

export interface AssignedClientTag {
    clientTag: KeyName;
}

export interface ClientMetrics {
    avgDaysToView: number | null;
    hasDaysToViewAnomaly: boolean;
    collectionRealization: number;
    avgDso: number | null;
    hasDsoAnomaly: boolean;
    ar0To30: number;
    ar31To60: number;
    ar61To90: number;
    ar91To120: number;
    ar121Plus: number;
    arCurrent: number;
    arOverdue: number;
    arTotal: number;
    hasPaidOutOfOrderAnomaly: boolean;
    hasNotViewedAnomaly: boolean;
}

export enum CONVERSATION_EMAIL_BODY_TYPE {
    HTML = 'Html',
    PLAIN_TEXT = 'PlainText'
}

export interface ClientConversationEmailAddress {
    name: string;
    address: string;
}

export interface ClientConverstionMessageMetadata {
    messageId: string;
    subject: string;
    to: ClientConversationEmailAddress[];
    from: ClientConversationEmailAddress;
    replyTo: ClientConversationEmailAddress | null;
    attachmentNames: string[];
    emailSentOn: Date;
    truncatedBody: string;
    cc: ClientConversationEmailAddress[] | null;
    bcc: ClientConversationEmailAddress[] | null;
    emailBodyType: CONVERSATION_EMAIL_BODY_TYPE | null;
}

export interface ClientConversation {
    canDelete: boolean | null;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    id: string;
    modifiedOn: Date;
    latestMessage: ClientConversationMessage | null;
    messagesCount: number;
    trackingConversationId: string | null;
}

export interface ClientConversationMessage {
    canDelete: boolean | null;
    canDownload: boolean | null;
    canEdit: boolean | null;
    clientConversationId: string;
    comment: string | null;
    createdById: string;
    createdByName: string;
    createdOn: Date;
    id: string;
    messageFileName: string | null;
    metadata: ClientConverstionMessageMetadata;
    modifiedOn: Date;
}

export interface ClientLookup {
    clientId: string;
    name: string;
}

export interface ClientInvoiceMetrics {
    // no third-party (and no payor invoices)
    // no missing documents
    // no invoices assigned to timekeepers for review or submission
    submittableEpdfCount: number;

    // includes third-party (but no payor invoices)
    pendingEpdfCount: number;
}

export interface SendStatement {
    statementMonth: string;
    amount: number | null;
    matterId: string | null;
    statementId: string | null;
    statementDocument: File | null;
    ccUsers: CC_USER[] | null;
    emailMessage: string | null;
    emailRecipients: string[] | null;
    foreignCurrencyAmount: number | null;
}

export enum CC_USER {
    CURRENT_USER = 'ccCurrentUser',
    BILLING_TIMEKEEPER = 'ccBillingTimekeeper',
    COLLECTION_TIMEKEEPER = 'ccCollectionTimekeeper',
    BILLER = 'ccBiller'
}

export enum CLIENT_ACTIVITY_TAB {
    STATEMENTS = 'Statements',
    CLIENT = 'Client'
}

export type ClientProperty = 'Notes' | 'Activities' | 'Statements' | 'Contacts';

export enum ClientDnd {
    DO_NOT_CONTACT = 'doNotContact',
    DO_NOT_SEND_STATEMENT = 'doNotSendStatement'
}

export enum ClientFilterStatementReceived {
    STATEMENTS_RECEIVED_FOR_MONTH = 'hasReceivedStatementForMonth',
    STATEMENTS_NOT_RECEIVED_FOR_MONTH = 'hasNotReceivedStatementForMonth'
}

export enum CLIENT_ANOMALY {
    DAYS_TO_VIEW = 'daystoview',
    DSO = 'dso',
    PAID_OUT_OF_ORDER = 'paidoutoforder',
    NO_VIEWED_INVOICES = 'noviewedinvoices'
}

export enum ClientFilter {
    MY_CLIENTS = 'myClients',
    AR_BUCKETS = 'arbuckets',
    OVERDUE = 'overdue',
    ANOMALIES = 'anomalies',
    TAG_NAMES = 'tagNames',
    FOLLOWUP_PENDING = 'followuppending',
    CLIENT_DEFAULT_INVOICE_TYPES = 'clientDefaultInvoiceTypes',
    CLIENT_DND = 'clientDnd',
    BILLING_TIME_KEEPERS = 'billingTimekeeperIds',
    STATEMENTS_RECEIVED = 'statementsReceived',
    STATEMENTS_NOT_RECEIVED = 'statementNotReceived',
    ACTIVE_PAYMENT_PLAN = 'hasActivePaymentPlan',
    CLIENT_STATUS_KEYS = 'clientStatusKeys',
    OFFICE_KEYS = 'officeKeys'
}

export interface ClientFilters {
    [ClientFilter.MY_CLIENTS]: string[];
    [ClientFilter.AR_BUCKETS]: string[];
    [ClientFilter.OVERDUE]: boolean;
    [ClientFilter.ANOMALIES]: CLIENT_ANOMALY[];
    [ClientFilter.TAG_NAMES]: string[];
    [ClientFilter.FOLLOWUP_PENDING]: boolean;
    [ClientFilter.CLIENT_DEFAULT_INVOICE_TYPES]: string[];
    [ClientFilter.CLIENT_DND]: ClientDnd[];
    [ClientFilter.BILLING_TIME_KEEPERS]: string[];
    [ClientFilter.STATEMENTS_RECEIVED]: string[];
    [ClientFilter.STATEMENTS_NOT_RECEIVED]: string[];
    [ClientFilter.ACTIVE_PAYMENT_PLAN]: string[];
    [ClientFilter.CLIENT_STATUS_KEYS]: string[];
    [ClientFilter.OFFICE_KEYS]: string[];
}

export enum DocumentDetailsActionsForClients {
    ACTIVITY = 'activity',
    FINANCIALS = 'financials'
}

// Backend does allow patching of many other properties. But for now
// these are enough. The properties can be updated as required
export interface ClientPatchProperties {
    doNotContact?: boolean | null;
    doNotSendStatement?: boolean | null;
    alertMessage?: string | null;
    payNowEnabled?: boolean | null;
    coverCardFees?: boolean;
    disallowCardPayment?: boolean;
}

export interface ClientForeignCurrency {
    totalBilledAmount: number;
    totalCollectedAmount: number;
    totalWorkAmount: number;
    trustAmount: number | null;
    unappliedCash: number | null;
    wipAmount: number | null;
    writeOffAmount: number | null;
}
