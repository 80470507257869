import { TimekeeperLookup } from '../models/timeKeeper';
import { requests } from './axiosAgent';

const Timekeepers = {
    BASE_URL: '/timekeepers',
    lookup: (params: URLSearchParams) =>
        requests.get<TimekeeperLookup[]>(`${Timekeepers.BASE_URL}/lookup`, { params })
};

export default Timekeepers;
