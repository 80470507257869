import { PaginatedResult } from '../models/list/pagination';
import { Reminder, ReminderStatusCount } from '../models/reminder';
import { requests } from './axiosAgent';

const Reminders = {
    BASE_URL: '/reminders',
    getReminderIdentifierUrl: (reminderId: string) => `${Reminders.BASE_URL}/${reminderId}`,
    do: (reminderId: string) =>
        requests.post<Reminder>(`${Reminders.getReminderIdentifierUrl(reminderId)}/_do`),
    undo: (reminderId: string) =>
        requests.post<Reminder>(`${Reminders.getReminderIdentifierUrl(reminderId)}/_undo`),
    update: (reminderId: string, remindOn: string) =>
        requests.put<Reminder>(`${Reminders.getReminderIdentifierUrl(reminderId)}`, { remindOn }),
    getAll: (params: URLSearchParams) =>
        requests.get<PaginatedResult<Reminder>>(Reminders.BASE_URL, { params }),
    delete: (reminderId: string) =>
        requests.delete<void>(Reminders.getReminderIdentifierUrl(reminderId)),
    count: (params?: URLSearchParams) =>
        requests.get<ReminderStatusCount[]>(`${Reminders.BASE_URL}/_count`, { params })
};

export default Reminders;
