import _ from 'lodash';
import { isArrayNullOrEmpty } from './objectUtils';

/**
 * Trims leading whitespaces and newline characters from a string
 * @param { string } value
 * @returns { string }
 */
export const trim = (value: string) => {
    return _.trim(value, ' \n');
};

export const isNullOrEmpty = (value: string | null | undefined) => {
    return value === null || value === undefined || isEmpty(value);
};

export const isEmpty = (value: string) => {
    return trim(value).length == 0;
};

export const trimWithEllipsis = (value: string | null | undefined, maxLength: number) => {
    if (!value) {
        return '';
    }
    return value.length > maxLength ? value.substring(0, maxLength) + '...' : value;
};

// wysiwyg

// Trim the leading and trailing newlines (<p><br></p>) from the quill
// generated html. Currently we are trimming only new lines, we can probably trim the
// spaces as well, but that would an overkill, as the tags are no consitent like
// new line. As the trailing and ending spaces can be between different type of tags.
// We can probably revisit if required.
export const trimRichTextNewLines = (html: string) => {
    const leadingAndTrailingNewLinesRegex = new RegExp(`^(<p><br></p>)*|(<p><br></p>)*$`, 'g');
    return html.replace(leadingAndTrailingNewLinesRegex, '');
};

export const concat = (values: string[]) => {
    if (isArrayNullOrEmpty(values)) {
        return '';
    }
    if (values.length === 1) {
        return values[0];
    } else if (values.length === 2) {
        return `${values[0]} and ${values[1]}`;
    } else {
        const last = values.pop();
        return `${values.join(', ')}, and ${last}`;
    }
};
