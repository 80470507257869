import { ClientConversation, ClientConversationMessage } from '../models/client';
import { PaginatedResult } from '../models/list/pagination';
import { requests } from './axiosAgent';
import Clients from './clients';

const ClientConversations = {
    getClientConversationsBaseUrl: (clientId: string) =>
        `${Clients.getClientIdentifierUrl(clientId)}/conversations`,
    getClientConversationIdentifierUrl: (clientId: string, conversationId: string) =>
        `${ClientConversations.getClientConversationsBaseUrl(clientId)}/${conversationId}`,
    getClientConversationMessageIdentifierUrl: (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) =>
        `${ClientConversations.getClientConversationIdentifierUrl(clientId, conversationId)}/messages/${conversationMessageId}`,
    getConversations: (clientId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<ClientConversation>>(
            ClientConversations.getClientConversationsBaseUrl(clientId),
            {
                params
            }
        ),
    deleteConversation: (clientId: string, conversationId: string) =>
        requests.delete<void>(
            ClientConversations.getClientConversationIdentifierUrl(clientId, conversationId)
        ),
    getConversationMessages: (clientId: string, conversationId: string) =>
        requests.get<ClientConversationMessage[]>(
            `${ClientConversations.getClientConversationIdentifierUrl(clientId, conversationId)}/messages`
        ),
    createConversation: (clientId: string, formData: FormData) =>
        requests.post<void>(ClientConversations.getClientConversationsBaseUrl(clientId), formData),
    updateConversationMessage: (
        clientId: string,
        conversationId: string,
        conversationMessageId: string,
        comment: string
    ) =>
        requests.put<void>(
            ClientConversations.getClientConversationMessageIdentifierUrl(
                clientId,
                conversationId,
                conversationMessageId
            ),
            { comment }
        ),
    deleteConversationMessage: (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) =>
        requests.delete<void>(
            ClientConversations.getClientConversationMessageIdentifierUrl(
                clientId,
                conversationId,
                conversationMessageId
            )
        ),
    getConversationMessageEmail: (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) =>
        requests.get<Blob>(
            `${ClientConversations.getClientConversationMessageIdentifierUrl(clientId, conversationId, conversationMessageId)}/email`,
            {
                responseType: 'blob'
            }
        ),
    getConversationMessageEmailBody: (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) =>
        requests.get<string>(
            `${ClientConversations.getClientConversationMessageIdentifierUrl(clientId, conversationId, conversationMessageId)}/emailBody`
        ),
    getMailboxesLastSyncedOn: () => requests.get<string>(`/mailboxes/_lastsyncedon`)
};

export default ClientConversations;
