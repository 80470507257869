import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { downloadDocumentBlob } from '../common/utils/file';
import { CC_USER, ClientStatement, SendStatement } from '../models/client';
import { store } from './store';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SortingParams } from '../models/list/sorting';
import { SearchParams } from '../models/list/search';
import { PAGE_KEY } from '../models/pageConfigs';
import { URLParams } from '../common/utils/urlParams';
import { Statement } from '../models/statement';
import { STATEMENT_OWNER_TYPE } from '../../features/shared/statements/Statements';

type GetStatementsParams = {
    ownerType: STATEMENT_OWNER_TYPE;
} & (
    | {
          ownerType: STATEMENT_OWNER_TYPE.CLIENT;
          clientId: string;
      }
    | { ownerType: STATEMENT_OWNER_TYPE.MATTER; clientId: string; matterId: string }
);

export default class StatementStore {
    loading = false;
    statements: PaginatedResult<Statement> | undefined;
    loadingStatementDocument = false;
    sendingStatement = false;

    constructor() {
        makeAutoObservable(this);
    }

    urlParams = new URLParams([PAGE_KEY.STATEMENT_LIST], {
        [PAGE_KEY.STATEMENT_LIST]: 10
    });

    // TODO: We should have a class which manages the URL params.
    // and all the stores can extend the class.
    get StatementListUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(PAGE_KEY.STATEMENT_LIST);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.STATEMENT_LIST);
        if (sortingParams!.sortExpression.length > 0) {
            params.append('orderBy', sortingParams!.sortExpression);
        }

        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.STATEMENT_LIST);
        if (searchParams!.searchString) {
            params.append('queryText', searchParams!.searchString);
        }
        return params;
    }

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    setSearchParams = (pageKey: PAGE_KEY, searchParams: SearchParams) => {
        this.urlParams.setSearchParams(pageKey, searchParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    getStatementDocument = async (statementId: string) => {
        this.loadingStatementDocument = true;
        try {
            const statementDocument = await agent.Statements.getDocument(statementId);
            return statementDocument;
        } catch (err: any) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingStatementDocument = false;
            });
        }
    };

    downloadStatementDocument = async (statementId: string, documentName: string) => {
        try {
            const document = await agent.Statements.getDocument(statementId);
            downloadDocumentBlob(document, documentName);
        } catch (error: any) {
            console.log(error);
        }
    };

    shareStatement = async (
        number: string,
        ccUsers: CC_USER[] | null,
        emailRecipients: string[] | null
    ) => {
        try {
            this.loading = true;
            await agent.Statements.share(number, {
                ccCurrentUser: !!ccUsers?.includes(CC_USER.CURRENT_USER),
                ccBillingTimekeeper: !!ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER),
                ccCollectionTimekeeper: !!ccUsers?.includes(CC_USER.COLLECTION_TIMEKEEPER),
                emailRecipients
            });
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    sendStatement = async (clientId: string, statement: SendStatement) => {
        this.sendingStatement = true;
        const formData = new FormData();
        formData.append('ClientId', clientId);
        formData.append('statementMonth', statement.statementMonth);
        if (statement.statementId) {
            formData.append('statementId', statement.statementId);
        }
        if (statement.matterId) {
            formData.append('matterId', statement.matterId);
        }
        if (statement.statementDocument) {
            formData.append('document', statement.statementDocument);
        }
        if (statement.amount !== null) {
            formData.append('amount', statement.amount.toString());
        }
        if (statement.foreignCurrencyAmount !== null) {
            formData.append('foreignCurrency.Amount', statement.foreignCurrencyAmount.toString());
        }

        if (statement.emailMessage?.length) {
            formData.append('emailMessage', statement.emailMessage);
        }

        if (statement.emailRecipients) {
            statement.emailRecipients.forEach((recipient) => {
                formData.append('emailRecipients', recipient);
            });
        }
        if (statement.ccUsers?.includes(CC_USER.CURRENT_USER)) {
            formData.append(CC_USER.CURRENT_USER, 'true');
        }
        if (statement.ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER)) {
            formData.append(CC_USER.BILLING_TIMEKEEPER, 'true');
        }
        if (statement.ccUsers?.includes(CC_USER.COLLECTION_TIMEKEEPER)) {
            formData.append(CC_USER.COLLECTION_TIMEKEEPER, 'true');
        }
        try {
            await agent.Statements.sendStatement(formData);
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.sendingStatement = false;
            });
        }
    };

    bulkSendStatements = async (
        checkOnly: boolean,
        clientIds: string[],
        statementMonth: string,
        markAsSent: boolean,
        includeMatterStatements: boolean | null,
        sentOn: string | null
    ) => {
        let response;
        try {
            this.loading = true;
            response = await agent.Statements.bulkSendStatements({
                checkOnly,
                clientIds,
                includeMatterStatements,
                statementMonth,
                setStatusOnly: markAsSent,
                sentOn: sentOn
            });
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    loadStatements = async (errorsOnly?: boolean) => {
        store.errorStore.getErrorCounts();
        const urlParams = this.StatementListUrlParams;
        if (errorsOnly) {
            urlParams.append('errorsOnly', 'true');
        }
        try {
            this.loading = true;
            const statements = await agent.Statements.list(urlParams);
            runInAction(() => {
                this.statements = statements;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    clearError = async (
        statementIds: string[],
        markAsSent: boolean | null,
        sentOn: string | null
    ) => {
        this.loading = true;
        try {
            await agent.Statements.clearError(statementIds, markAsSent, sentOn);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    getStatements = async (params: GetStatementsParams) => {
        this.loading = true;
        const { ownerType, clientId } = params;
        let statements: ClientStatement[];
        try {
            switch (ownerType) {
                case STATEMENT_OWNER_TYPE.CLIENT:
                case STATEMENT_OWNER_TYPE.MATTER:
                    statements = await agent.Clients.getClientStatements(clientId);
                    break;

                // For now just using the clientStatement API itself.
                // Filed CBH-1730 for this
                // case STATEMENT_OWNER_TYPE.MATTER:
                //     profileStatements = await agent.Matters.getMatterStatements(
                //         clientId,
                //         params.matterId
                //     );
                //     break;
            }
            return statements;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}
