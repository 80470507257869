import { ExclamationCircleFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { trimWithEllipsis } from '../../../app/common/utils/string';
import { checkEmailValidity } from './helper';

type Props = {
    value: string;
    onClose: (email: string) => void;
    onDoubleClick: (value: string) => void;
};

export default function CustomEmailTagRender(props: Props) {
    const { value, onClose, onDoubleClick } = props;
    const MAX_LEN = 50;
    const isValidEmail = checkEmailValidity(value);

    return (
        <TooltipComponent title={!isValidEmail ? 'Invalid Email' : ''}>
            <Tag
                onClose={() => onClose(value)}
                closable
                style={{ margin: 2 }}
                icon={!isValidEmail ? <ExclamationCircleFilled /> : null}
                color={!isValidEmail ? 'error' : undefined}
                onDoubleClick={() => onDoubleClick(value)}
            >
                {trimWithEllipsis(value, MAX_LEN)}
            </Tag>
        </TooltipComponent>
    );
}
