import { Button, Row, Space } from 'antd';
import { useStore } from '../../../stores/store';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
    onOk: () => Promise<void>;
    okDisabled?: boolean;
    okText?: string;
};
export default function ActionFormFooter(props: Props) {
    const { onOk, okText, okDisabled } = props;
    const {
        modalStore: { closeModal }
    } = useStore();
    const [loader, setLoader] = useState(false);

    const handleOnOk = async () => {
        setLoader(true);
        await onOk();
    };

    const handleOnCancel = () => {
        closeModal();
    };

    return (
        <Row style={{ width: '100%' }} justify='end'>
            <Space>
                <Button onClick={handleOnCancel}>Cancel</Button>
                <Button
                    onClick={handleOnOk}
                    icon={loader && <LoadingOutlined spin />}
                    type='primary'
                    disabled={okDisabled || loader}
                >
                    {okText ?? 'Done'}
                </Button>
            </Space>
        </Row>
    );
}
