import UploadDoc, { Props as UploadDocProps } from '../../../uploadFile/Upload';
import { UploadFile } from 'antd';
import { ActionFormCompoundItemProps } from '../ActionForm';

type Props = Omit<UploadDocProps, 'onSelect'> & ActionFormCompoundItemProps;

export default function ActionFormUploadDoc(props: Props) {
    const { multiSelect, onChange } = props;
    const handleFileSelect = (data: UploadFile[]) => {
        onChange?.(data.length ? (multiSelect ? data : data[0]) : null);
    };
    return <UploadDoc onSelect={handleFileSelect} {...props} />;
}
