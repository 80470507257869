import { notification } from 'antd';
import { ReactNode } from 'react';

const DURATION = 5;

class Notification {
    constructor() {
        notification.config({
            maxCount: 2,
            placement: 'bottomRight'
        });
    }

    success = (description: string, message?: string, closeButton?: ReactNode) => {
        notification.success({
            message: message ?? 'Success',
            description: description,
            duration: DURATION,
            btn: closeButton
        });
    };

    info = (description: string, message?: string, closeButton?: ReactNode) => {
        notification.info({
            message: message ?? 'Info',
            description: description,
            duration: DURATION,
            btn: closeButton
        });
    };

    error = (description: string, message?: string, closeButton?: ReactNode) => {
        notification.error({
            message: message ?? 'Error',
            description: description,
            duration: DURATION,
            btn: closeButton
        });
    };

    warning = (description: string, message?: string, closeButton?: ReactNode) => {
        notification.warning({
            message: message ?? 'Warning',
            description: description,
            duration: DURATION,
            btn: closeButton
        });
    };
}

export const Notify = new Notification();
