import {
    AgedAR,
    ARTotal,
    ClientMetrics,
    CurrentMonthBillerSubmissions,
    InvoiceMetricsByStatus,
    InvoiceMetricsByYear,
    InvoiceMetricsTotals,
    MonthlyInvoiceOrPayment,
    PendingInvoiceByType,
    PendingInvoicesByBiller
} from '../models/dashboard';
import { requests } from './axiosAgent';

const Charts = {
    BASE_URL: '/charts',
    getPendingInvoicesByBiller: () =>
        requests.get<PendingInvoicesByBiller[]>(`${Charts.BASE_URL}/pendinginvoicesbybiller`),
    getPendingInvoicesByType: () =>
        requests.get<PendingInvoiceByType[]>(`${Charts.BASE_URL}/pendinginvoicesbytype`),
    getPaymentsDataByMonth: () =>
        requests.get<MonthlyInvoiceOrPayment[]>(`${Charts.BASE_URL}/recentpaymentsbymonth`),
    getMonthlyInvoices: () =>
        requests.get<MonthlyInvoiceOrPayment[]>(`${Charts.BASE_URL}/invoicedbymonth`),
    getMonthlyBacklog: () =>
        requests.get<MonthlyInvoiceOrPayment[]>(`${Charts.BASE_URL}/backlogbymonth`),
    getARByTotal: () => requests.get<ARTotal>(`${Charts.BASE_URL}/arbytype`),
    getAgedAR: () => requests.get<AgedAR[]>(`${Charts.BASE_URL}/agedar`),
    getClientMetrics: (params: URLSearchParams) =>
        requests.get<ClientMetrics[]>(`${Charts.BASE_URL}/clientmetrics`, { params }),
    getInvoiceMetricsByYear: () =>
        requests.get<InvoiceMetricsByYear[]>(`${Charts.BASE_URL}/invoicemetricsbyyear`),
    getInvoiceMetricsTotals: () =>
        requests.get<InvoiceMetricsTotals>(`${Charts.BASE_URL}/invoicemetricstotals`),
    getCurrentMonthBillerSubmissions: () =>
        requests.get<CurrentMonthBillerSubmissions[]>(
            `${Charts.BASE_URL}/currentmonthinvoicesubmissionsbybiller`
        ),
    getMyInvoiceMetricsByStatus: (params: URLSearchParams) =>
        requests.get<InvoiceMetricsByStatus>(`${Charts.BASE_URL}/myinvoicemetricsbystatus`, {
            params
        })
};

export default Charts;
