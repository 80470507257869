import { ButtonType } from 'antd/es/button';
import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

interface ModalProps {
    title?: string | ReactNode;
    body?: string | ReactNode;
    okText?: string | undefined;
    okType?: ButtonType;
    isOkDanger?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
    footer?: boolean | null;
    width?: string | number | undefined;
    closeIcon?: boolean | ReactNode;
    verticalScroll?: boolean;
    easyClose?: boolean;
}

export default class ModalStore {
    open = false;
    modalProps: ModalProps | null = null;
    isOkDisabled = false;

    constructor() {
        makeAutoObservable(this);
    }

    openModal = (modalProps: ModalProps) => {
        this.modalProps = { width: 560, ...modalProps };
        this.open = true;
    };

    closeModal = () => {
        this.open = false;
        this.modalProps = null;
        this.isOkDisabled = false;
    };

    updateIsOkDisabled = (value: boolean) => {
        this.isOkDisabled = value;
    };

    updateModalProps = (modalProps: ModalProps) => {
        this.modalProps = { ...this.modalProps, ...modalProps };
    };
}
