export interface PaginationInfo {
    pageNumber?: number;
    pageSize?: number;
    totalPages?: number;
    totalCount?: number;
    hasPreviousPage?: boolean;
    hasNextPage?: boolean;
    readonly pageStartAt?: number;
    readonly pageEndAt?: number;
}

export class PaginatedResult<T> {
    items: T[];
    paginationInfo: PaginationInfo;

    constructor(responseData: any) {
        this.items = responseData.items;
        this.paginationInfo = {
            pageNumber: responseData.pageNumber,
            pageSize: responseData.pageSize,
            totalCount: responseData.totalCount,
            totalPages: responseData.totalPages,
            hasPreviousPage: responseData.hasPreviousPage,
            hasNextPage: responseData.hasNextPage,
            pageStartAt: (responseData.pageNumber - 1) * responseData.pageSize + 1,
            pageEndAt: Math.min(
                (responseData.pageNumber - 1) * responseData.pageSize + responseData.pageSize,
                responseData.totalCount
            )
        };
    }
}

export class PaginationParams {
    pageNumber: number;
    pageSize: number;

    constructor(pageNumber = 1, pageSize = 25) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
    }
}
