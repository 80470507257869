export class AppConfig {
    static readonly currencySymbol: string = '$';
    // static readonly dateTimeFormat: string = 'MM-DD-YYYY h:mm a';
    static readonly dateFormat: string = 'MM-DD-YYYY';
    static readonly timeFormat: string = 'h:mm a';
    static readonly dayDateMonthNameFormat: string = 'dddd, MMM DD, YYYY';
    static readonly dateMonthNameFormat: string = 'MMM DD, YYYY';
    static readonly dateTimeMonthNameFormat: string = 'MMM DD, YYYY h:mm a';
    static readonly dateTimeSecondsMonthNameFormat: string = 'MMM DD, YYYY h:mm:ss a';
    static readonly monthYearFormat: string = 'MMM YYYY';
    static readonly locale: string = 'en-US'; // Will be updated when Internationalization is introduced
    static readonly currency: string = 'USD';

    //This is to be used when there are dayjs operations.
    //Some browsers do not support non ISO standard dates.
    //i.e dayjs(non-iso-date).format('formatString') will not work
    static readonly isoDateFormat: string = 'YYYY-MM-DD';
}
