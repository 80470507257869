import { ACTIVITY_EVENT_TYPE, Activity } from './activity';

export interface Statement {
    amount: number;
    activities: StatementActivity[];
    canDownload: boolean | null;
    canShare: boolean | null;
    canSend: boolean | null;
    canClearError: boolean | null;
    canMarkAsSent: boolean | null;
    createdById: string;
    createdOn: Date;
    error: string | null;
    foreignCurrency: StatementForeignCurrency;
    id: string;
    recipients: string[] | null;
    modifiedById: string;
    modifiedOn: Date;
    clientId: string;
    matterId: string | null;
    matterName: string | null;
    clientName: string;
    billingTimekeeperId: string;
    billingTimekeeperName: string;
    statementDocumentAttachmentsCount: number;
    statementDocumentName: string;
    statementId: string;
    statementMonth: Date;
    status: STATEMENT_STATUS;
    statusName: string;
}

export enum STATEMENT_STATUS {
    ReadyToSend = 'ReadyToSend',
    Submitted = 'Submitted',
    Delivered = 'Delivered',
    Viewed = 'Viewed'
}

export type STATEMENT_EVENT =
    | ACTIVITY_EVENT_TYPE.EVENT
    | ACTIVITY_EVENT_TYPE.STATUS_CHANGED
    | ACTIVITY_EVENT_TYPE.EXCEPTION
    | ACTIVITY_EVENT_TYPE.MODIFIED;

export type StatementActivity = Activity & {
    statementId: string;
    statusName: STATEMENT_STATUS;
    eventType: STATEMENT_EVENT;
};

export interface BulkStatementSubmissionActionResult {
    clientId: string;
    matterId: string | null;
    success: boolean;
    message: string | null;
}

export interface StatementsSubmissionResult {
    action: 'Check' | 'Submit';
    results: BulkStatementSubmissionActionResult[];
}

export enum BULK_STATEMENT_SUBMISSION_WARNING {
    CAN_SUBMIT_SOME_STATEMENTS = 'canSendSomeStatements',
    CANNOT_SUBMIT_ANY_STATEMENTS = 'cannotSubmitAnyStatements',
    UNABLE_TO_VALIDATE = 'unableToValidate',
    NO_WARNING = 'noWarning'
}

export interface StatementForeignCurrency {
    amount: number;
}

export type StatementIdentifier = {
    statementId: string;
    statementMonth: Date;
};
