import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import UnAuthorized from '../../../features/errors/UnAuthorized';
import withConditionAndRedirection from './withConditionAndRedirection';

type AuthorizedRouteProps = {
    isAuthorized: boolean;
    redirectTo?: string;
};

// Responsible for authorized access only.
export default function AuthorizedRoute(props: PropsWithChildren<AuthorizedRouteProps>) {
    const { children, isAuthorized, redirectTo } = props;
    const AuthorizedRoute_ = withConditionAndRedirection(
        () => <>{children || <Outlet />}</>,
        isAuthorized,
        redirectTo ? { redirectTo } : { fallbackUI: <UnAuthorized /> }
    );
    return <AuthorizedRoute_ />;
}
