export enum PAGE_KEY {
    INVOICE_DETAILS_NOTES = 'invoiceDetailsNotes',
    INVITED_CLIENT_LIST = 'invitedClientList',
    MATTER_PROFILE_NOTES = 'matterProfileNotes',
    MATTER_LIST = 'matterList',
    CLIENT_LIST = 'clientList',
    STATEMENT_LIST = 'statementList',
    CLIENT_PROFILE_INVOICES_PENDING = 'clientProfileInvoices!pending',
    CLIENT_PROFILE_INVOICES_OUTSTANDING = 'clientProfileInvoices!outstanding',
    CLIENT_PROFILE_INVOICES_PAID = 'clientProfileInvoices!paid',
    CLIENT_PROFILE_INVOICES_REVERSED = 'clientProfileInvoices!reversed',
    CLIENT_PROFILE_MATTERS = 'clientProfileMatters',
    CLIENT_PROFILE_NOTES = 'clientProfileNotes',
    CLIENT_PROFILE_CONVERSATIONS = 'clientProfileConversations',
    PAYMENT_LIST = 'paymentList'
}
