import { GlobalLookup } from '../models/search';
import { requests } from './axiosAgent';

const Search = {
    BASE_URL: '/search',
    lookup: (params: URLSearchParams) =>
        requests.get<GlobalLookup>(`${Search.BASE_URL}/lookupentities`, { params })
};

export default Search;
