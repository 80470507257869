import { Currency } from '../../models/invoice';
import { useStore } from '../../stores/store';

const getTenatAliasFromURL = () => {
    const currentURLHost = window.location.host;
    return currentURLHost.split('.')[0];
};

export const getBaseInvoiceServiceAPIURL = () => {
    return process.env.REACT_APP_INVOICE_SERVICE_API_URL?.replace(
        '{tenantAlias}',
        getTenatAliasFromURL()
    );
};

export const isForeignCurrency = (currency: Currency | null | undefined) => {
    const {
        tenantStore: { tenant }
    } = useStore();

    // This is really an exception but if either currency is not defined,
    // assume that its the tenant currency.
    if (!tenant || !tenant.currency || !currency) {
        return false;
    }

    return tenant.currency.code !== currency.code;
};
