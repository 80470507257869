import { Checkbox, Col, DatePicker, Flex, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { filter, map, uniq } from 'lodash';
import { useState } from 'react';
import ActionForm from '../../../app/common/components/actionForm/ActionForm';
import { Notify } from '../../../app/common/utils/notify';
import { CC_USER, Client, ClientStatement } from '../../../app/models/client';
import { AppConfig } from '../../../app/models/config/appConfig';
import { BulkAction } from '../../../app/models/invoice';
import { Matter } from '../../../app/models/matter';
import { BULK_STATEMENT_SUBMISSION_WARNING, Statement } from '../../../app/models/statement';
import { useStore } from '../../../app/stores/store';
import BulkStatementSubmissionModalBody from '../../clients/hooks/components/BulkStatementSubmissionModalBody';
import SendStatementModalBody from '../../clients/hooks/components/SendStatementModalBody';
import {
    getBulkSendErrorMessage,
    getBulkSendMessageWithCount,
    getBulkSendSuccessMessage,
    getBulkStatementSubmissionWarningForValidation
} from '../../clients/hooks/helpers/sendStatement';
import EditEmailAddresses from '../../invoices/hooks/components/EditEmailAddresses';
import { getOneTimeExceptionEmailEditorTooltip } from '../../invoices/hooks/helpers/submit';

const { Text } = Typography;

export default function useStatementActions() {
    const DefaultBulkAction: BulkAction = {
        canDisplay: false,
        canDo: true,
        warnings: []
    };
    const {
        modalStore: { openModal, closeModal },
        clientStore: { bulkSendMatterStatements },
        statementStore: { clearError, loadStatements, shareStatement, bulkSendStatements }
    } = useStore();

    const [selectedStatements, setSelectedStatements] = useState<Statement[]>([]);
    const [selectedStatementIds, setSelectedStatementIds] = useState<React.Key[]>([]);
    const [canClearError, setCanClearError] = useState(DefaultBulkAction);

    const getStatementCount = (statements: Statement[], canAction: keyof Statement) => {
        let canActionCount = 0;
        statements.forEach((statement) => {
            if (statement[canAction]) {
                canActionCount++;
            }
        });
        return { canActionCount };
    };

    const updateCanClearErrorAction = (statements: Statement[]) => {
        const { canActionCount: canClearErrorCount } = getStatementCount(
            statements,
            'canClearError'
        );

        setCanClearError({
            canDisplay: !!canClearErrorCount,
            canDo: true,
            warnings: []
        });
    };

    const handleRowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: Statement[]) => {
        setSelectedStatements(selectedRows);
        setSelectedStatementIds(selectedRowKeys);
        updateCanClearErrorAction(selectedRows);
    };

    const resetSelections = () => {
        setSelectedStatements([]);
        setSelectedStatementIds([]);
    };

    const handleSendClientStatement = async (
        client: Client,
        statements: ClientStatement[],
        defaultMonth?: Date,
        needsAttention?: boolean,
        onSuccess?: () => Promise<any> | any
    ) => {
        const getCcToClient = () => {
            const ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[] = [];
            if (client.billingTimekeeperId) {
                ccTo.push(CC_USER.BILLING_TIMEKEEPER);
            }
            if (
                client.collectionTimekeeperId &&
                client.billingTimekeeperId !== client.collectionTimekeeperId
            ) {
                ccTo.push(CC_USER.COLLECTION_TIMEKEEPER);
            }
            return ccTo;
        };

        openModal({
            title: 'Send Statement',
            body: (
                <SendStatementModalBody
                    clientId={client.clientId}
                    doNotContact={client.doNotContact}
                    statements={statements?.filter((statement) => !statement.matterId) ?? []}
                    defaultMonth={defaultMonth}
                    needsAttention={needsAttention}
                    recipients={client.computedStatementRecipients}
                    billingTimekeeperName={client.billingTimekeeperName ?? undefined}
                    collectionTimekeeperName={client.collectionTimekeeperName ?? undefined}
                    ccTo={getCcToClient()}
                    onSuccess={onSuccess}
                    currency={client.currency}
                />
            ),
            width: 650,
            footer: false
        });
    };

    const handleSendMatterStatement = async (
        matter: Matter,
        statements: ClientStatement[],
        defaultMonth?: Date,
        needsAttention?: boolean,
        onSuccess?: () => Promise<any> | any
    ) => {
        const getCcToMatter = () => {
            const ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[] = [];
            if (matter.billingTimekeeperId) {
                ccTo.push(CC_USER.BILLING_TIMEKEEPER);
            }
            if (
                matter.collectionTimekeeperId &&
                matter.billingTimekeeperId !== matter.collectionTimekeeperId
            ) {
                ccTo.push(CC_USER.COLLECTION_TIMEKEEPER);
            }
            return ccTo;
        };

        openModal({
            title: 'Send Matter Statement',
            body: (
                <SendStatementModalBody
                    clientId={matter.client.clientId}
                    matterId={matter.matterId}
                    doNotContact={matter.doNotContact}
                    statements={
                        statements?.filter((statement) => statement.matterId === matter.matterId) ??
                        []
                    }
                    defaultMonth={defaultMonth}
                    needsAttention={needsAttention}
                    recipients={matter?.computedStatementRecipients}
                    billingTimekeeperName={matter.billingTimekeeperName ?? undefined}
                    collectionTimekeeperName={matter.collectionTimekeeperName ?? undefined}
                    ccTo={getCcToMatter()}
                    // When a statement is sent, we would have stale statements,
                    // Hence we can just clear it.
                    onSuccess={onSuccess}
                    currency={matter.currency}
                />
            ),
            width: 650,
            footer: false
        });
    };

    const handleBulkSendStatement = (
        markAsSent: boolean,
        clientIds: string[],
        onFinish?: () => void
    ) => {
        const handleValidation = async (month: string) => {
            const response = await bulkSendStatements(
                true,
                clientIds,
                month,
                markAsSent,
                true,
                null
            );
            if (response) {
                const sendableClientIds = uniq(
                    map(filter(response.results, { success: true }), 'clientId')
                );
                const bulkStatementSubmissionWarning =
                    getBulkStatementSubmissionWarningForValidation(response);
                return { clientOrMatterIds: sendableClientIds, bulkStatementSubmissionWarning };
            } else {
                return {
                    clientOrMatterIds: [],
                    bulkStatementSubmissionWarning:
                        BULK_STATEMENT_SUBMISSION_WARNING.UNABLE_TO_VALIDATE
                };
            }
        };

        const handleSendStatements = async (
            month: string,
            validatedClientIds: string[],
            sentOn: string | null
        ) => {
            const success = await bulkSendStatements(
                false,
                validatedClientIds,
                month,
                markAsSent,
                true,
                sentOn
            );
            const successCount = success?.results.filter((result) => result.success).length;

            if (!successCount) {
                Notify.error(getBulkSendErrorMessage(markAsSent));
            } else if (clientIds.length !== validatedClientIds.length) {
                Notify.success(
                    getBulkSendMessageWithCount(
                        markAsSent,
                        validatedClientIds.length,
                        clientIds?.length,
                        'clients'
                    )
                );
            } else {
                Notify.success(getBulkSendSuccessMessage(markAsSent));
            }
            onFinish?.();
        };

        openModal({
            title: markAsSent ? 'Mark Statements as Sent' : 'Send Statements',
            body: (
                <BulkStatementSubmissionModalBody
                    onSend={handleSendStatements}
                    markAsSent={markAsSent}
                    onValidation={handleValidation}
                />
            ),
            footer: false
        });
    };

    const handleBulkSendMatterStatement = (
        clientId: string,
        markAsSent: boolean,
        matterIds: string[],
        onFinish?: () => void
    ) => {
        const handleValidation = async (month: string) => {
            const response = await bulkSendMatterStatements(
                clientId,
                true,
                matterIds,
                month,
                markAsSent
            );
            if (response) {
                const sendableMatterIds = uniq(
                    map(filter(response.results, { success: true }), 'matterId')
                ) as string[];
                const bulkStatementSubmissionWarning =
                    getBulkStatementSubmissionWarningForValidation(response);
                return { clientOrMatterIds: sendableMatterIds, bulkStatementSubmissionWarning };
            } else {
                return {
                    clientOrMatterIds: [],
                    bulkStatementSubmissionWarning:
                        BULK_STATEMENT_SUBMISSION_WARNING.UNABLE_TO_VALIDATE
                };
            }
        };

        const handleSendMatterStatements = async (month: string, validatedMatterIds: string[]) => {
            const success = await bulkSendMatterStatements(
                clientId,
                false,
                validatedMatterIds,
                month,
                markAsSent
            );

            const successCount = success?.results.filter((result) => result.success).length;

            if (!successCount) {
                Notify.error(getBulkSendErrorMessage(markAsSent));
            } else if (matterIds.length !== validatedMatterIds.length) {
                Notify.success(
                    getBulkSendMessageWithCount(
                        markAsSent,
                        validatedMatterIds.length,
                        matterIds?.length,
                        'matters'
                    )
                );
            } else {
                Notify.success(getBulkSendSuccessMessage(markAsSent));
            }
            onFinish?.();
        };

        openModal({
            title: markAsSent ? 'Mark Statements as Sent' : 'Send Statements',
            body: (
                <BulkStatementSubmissionModalBody
                    onSend={handleSendMatterStatements}
                    markAsSent={markAsSent}
                    onValidation={handleValidation}
                />
            ),
            footer: false
        });
    };

    const handleShareStatement = (
        statementId: string,
        recipients: string[] | null,
        needsAttention?: boolean
    ) => {
        const ModalBody = () => {
            const [emails, setEmails] = useState<string[] | null>(null);

            const handleOnOK = async () => {
                if (await shareStatement(statementId, null, emails)) {
                    Notify.success(
                        `Statement ${statementId} successfully shared with the client.`,
                        'Success'
                    );
                }

                closeModal();
            };

            return (
                <ActionForm okText='Share' onOk={handleOnOK}>
                    <ActionForm.Item>
                        <Text>Are you sure you want to share this statement with the client?</Text>
                    </ActionForm.Item>
                    <Col span={24}>
                        <ActionForm.Item>
                            <EditEmailAddresses
                                onChange={(emails) => {
                                    setEmails(emails);
                                }}
                                emails={recipients ?? ''}
                                tooltipInfo={getOneTimeExceptionEmailEditorTooltip()}
                                defaultEditMode={!!needsAttention || !recipients?.length}
                            />
                        </ActionForm.Item>
                    </Col>
                </ActionForm>
            );
        };

        openModal({
            title: `Share Statement`,
            body: <ModalBody />,
            footer: false
        });
    };

    const handleBulkClearError = async (
        statements: Statement[],
        onFinish?: () => Promise<any> | any
    ) => {
        let refreshPage = false;
        const clearableStatements = statements.filter((s) => s.canClearError);
        const clearAndMarkAsSendableStatements = statements.filter(
            (s) => s.canClearError && s.canMarkAsSent
        );

        const ModalBody = () => {
            const [markAsSent, setMarkAsSent] = useState(true);
            const [sentOn, setSentOn] = useState(dayjs());

            const handleOnOk = async () => {
                // If there are no actionable statements just abort.
                // This behavior should be improved as we futher use this 'silently ignoring
                // unactionable item' behavior across the application
                if (
                    (markAsSent && !clearAndMarkAsSendableStatements.length) ||
                    (!markAsSent && !clearableStatements.length)
                ) {
                    return;
                }
                const errorCleared = await clearError(
                    markAsSent
                        ? clearAndMarkAsSendableStatements.map((i) => i.statementId)
                        : clearableStatements.map((i) => i.statementId),
                    markAsSent,
                    sentOn && markAsSent ? sentOn.format(AppConfig.isoDateFormat) : null
                );

                if (errorCleared && markAsSent) {
                    refreshPage = true;
                    Notify.success(
                        `Successfully cleared errors for ${clearAndMarkAsSendableStatements.length} statement(s) and marked statement(s) as sent.`
                    );
                } else if (errorCleared) {
                    refreshPage = true;
                    Notify.success(
                        `Successfully cleared errors for ${clearableStatements.length} statement(s)`
                    );
                } else {
                    Notify.error(`An error occurred while clearing the error. Please try again.`);
                }
                onFinish?.();
                closeModal();
                if (refreshPage) {
                    // Used only in errors page
                    loadStatements(true);
                }
            };

            return (
                <ActionForm onOk={handleOnOk} okText='Clear'>
                    <Text>
                        Are you sure you want to clear the errors for the selected statements?
                    </Text>

                    <ActionForm.Item>
                        <Flex vertical gap={10}>
                            <Text>
                                If these statements are already sent to the Client, you can also
                                mark them as sent by checking the option below.
                            </Text>
                            <Checkbox
                                checked={markAsSent}
                                onChange={(e) => {
                                    setMarkAsSent(e.target.checked);
                                    setSentOn(dayjs());
                                }}
                            >
                                Mark as Sent
                            </Checkbox>
                            {markAsSent && (
                                <>
                                    <Text>Select the date these statements were sent:</Text>
                                    <Space>
                                        <DatePicker
                                            defaultValue={sentOn}
                                            onChange={(_, dateString) => {
                                                if (typeof dateString === 'string') {
                                                    setSentOn(dayjs(dateString));
                                                }
                                            }}
                                            style={{ width: 160 }}
                                            allowClear={false}
                                            format={AppConfig.dateMonthNameFormat}
                                            disabledDate={(current: Dayjs) => {
                                                return current && current.isAfter(dayjs(), 'day');
                                            }}
                                        />
                                    </Space>
                                </>
                            )}
                        </Flex>
                    </ActionForm.Item>
                </ActionForm>
            );
        };

        openModal({
            title: 'Clear Errors',
            body: <ModalBody />,
            okText: 'Save',
            footer: false,
            onCancel: closeModal
        });
    };

    const handleClearError = async (
        statement: Statement | ClientStatement,
        onSuccess?: () => Promise<any> | any
    ) => {
        const ModalBody = () => {
            const [markAsSent, setMarkAsSent] = useState(true);
            const [sentOn, setSentOn] = useState(dayjs());

            let refreshPage = false;
            const handleOnOK = async () => {
                const errorCleared = await clearError(
                    [statement.statementId],
                    markAsSent,
                    sentOn && markAsSent ? sentOn.format(AppConfig.isoDateFormat) : null
                );
                if (errorCleared && markAsSent) {
                    Notify.success(
                        `Successfully cleared error for ${statement.statementId} and marked it as sent.`
                    );
                    refreshPage = true;
                } else if (errorCleared) {
                    Notify.success(`Successfully cleared error for ${statement.statementId}.`);
                    refreshPage = true;
                } else {
                    Notify.error(`An error occurred while clearing the error. Please try again.`);
                }

                if (refreshPage) {
                    await onSuccess?.();
                }
                closeModal();
            };

            return (
                <ActionForm okText='Clear' onOk={handleOnOK}>
                    <Text>Are you sure you want to clear the error for this statement?</Text>
                    {statement.canMarkAsSent && (
                        <>
                            <Text>
                                If this statement is already sent to the Client, you can also mark
                                it as sent by checking the option below.
                            </Text>
                            <ActionForm.Item>
                                <Flex vertical gap={10}>
                                    <Checkbox
                                        checked={markAsSent}
                                        onChange={(e) => {
                                            setMarkAsSent(e.target.checked);
                                            setSentOn(dayjs());
                                        }}
                                    >
                                        Mark as Sent
                                    </Checkbox>
                                    {markAsSent && (
                                        <>
                                            <Text>Select the date this statement was sent:</Text>
                                            <Space>
                                                <DatePicker
                                                    defaultValue={sentOn}
                                                    onChange={(_, dateString) => {
                                                        if (typeof dateString === 'string') {
                                                            setSentOn(dayjs(dateString));
                                                        }
                                                    }}
                                                    style={{ width: 160 }}
                                                    allowClear={false}
                                                    disabledDate={(current) => {
                                                        return (
                                                            current.isBefore(
                                                                dayjs(
                                                                    statement.statementMonth
                                                                ).startOf('day'),
                                                                'day'
                                                            ) ||
                                                            current.isAfter(
                                                                dayjs().endOf('day'),
                                                                'day'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Space>
                                        </>
                                    )}
                                </Flex>
                            </ActionForm.Item>
                        </>
                    )}
                </ActionForm>
            );
        };

        openModal({
            title: 'Clear Error',
            body: <ModalBody />,
            footer: false,
            onCancel: closeModal
        });
    };

    return {
        handleSendClientStatement,
        handleBulkSendStatement,
        handleShareStatement,
        handleClearError,
        handleBulkClearError,
        handleRowSelectionChange,
        selectedStatementIds,
        setSelectedStatementIds,
        selectedStatements,
        setSelectedStatements,
        setCanClearError,
        canClearError,
        resetSelections,
        handleSendMatterStatement,
        handleBulkSendMatterStatement
    };
}
