import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { EBillingStatus, Tag } from '../models/dictionary';
import { INVOICE_STATUS } from '../models/invoice';
import { KeyName } from '../models/keyValuePair';

export default class DictionaryStore {
    offices?: KeyName[];
    loadingOffices = false;

    clientStatuses?: KeyName[];
    loadingClientStatuses = false;

    clientTags?: Tag[];
    loadingClientTags = false;

    matterTags?: Tag[];
    loadingMatterTags = false;

    eBillingStatuses?: EBillingStatus[];
    loadingEBillingStatuses = false;

    writeOffReasons?: KeyName[];
    loadingWriteOffReasons = false;

    noteTags?: Tag[];
    loadingNoteTags = false;

    creatingEbillingStatus = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadClientStatuses = async () => {
        this.loadingClientStatuses = true;
        try {
            const clientStatuses = await agent.Dictionaries.getClientStatuses();
            runInAction(() => {
                this.clientStatuses = clientStatuses;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingClientStatuses = false;
            });
        }
    };

    loadOffices = async () => {
        this.loadingOffices = true;
        try {
            const offices = await agent.Dictionaries.getOffices();
            runInAction(() => {
                this.offices = offices;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingOffices = false;
            });
        }
    };

    loadClientTags = async () => {
        this.loadingClientTags = true;
        try {
            const clientTags = await agent.Dictionaries.getClientTags();
            runInAction(() => {
                this.clientTags = clientTags;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingClientTags = false));
        }
    };

    loadMatterTags = async () => {
        this.loadingMatterTags = true;
        try {
            const matterTags = await agent.Dictionaries.getMatterTags();
            runInAction(() => {
                this.matterTags = matterTags;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterTags = false));
        }
    };

    loadNoteTags = async () => {
        this.loadingNoteTags = true;
        try {
            const noteTags = await agent.Dictionaries.getNoteTags();
            runInAction(() => {
                this.noteTags = noteTags;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingNoteTags = false;
            });
        }
    };

    loadEbillingStatuses = async () => {
        this.loadingEBillingStatuses = true;
        try {
            const eBillingStatuses = await agent.Dictionaries.getEbillingStatuses();
            runInAction(() => {
                this.eBillingStatuses = eBillingStatuses;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingEBillingStatuses = false));
        }
    };

    loadWriteOffReasons = async () => {
        this.loadingWriteOffReasons = true;
        try {
            const writeOffReasons = await agent.Dictionaries.getInvoiceWriteOffReasons();
            runInAction(() => {
                this.writeOffReasons = writeOffReasons;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingWriteOffReasons = false;
            });
        }
    };

    createEbillingStatus = async (name: string, invoiceStatus: INVOICE_STATUS) => {
        this.creatingEbillingStatus = true;
        try {
            const eBillingStatus = await agent.Dictionaries.createSingleEbillingStatus({
                name,
                invoiceStatus
            });
            return eBillingStatus.key;
        } catch (err) {
            console.log(err);
            return undefined;
        } finally {
            runInAction(() => (this.creatingEbillingStatus = false));
        }
    };
}
