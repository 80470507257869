import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import RoutePaths from '../RoutePaths';
import withConditionAndRedirection from './withConditionAndRedirection';

export type UnAuthenticatedOnlyRouteProps = {
    isAuthenticated: boolean;
};

// Redirects the user to Default Route if the user is authenticated
export default function UnAuthenticatedOnlyRoute(
    props: PropsWithChildren<UnAuthenticatedOnlyRouteProps>
) {
    const { children, isAuthenticated } = props;
    const UnAuthenticatedOnlyRoute_ = withConditionAndRedirection(
        () => <>{children || <Outlet />}</>,
        !isAuthenticated,
        { redirectTo: RoutePaths.DEFAULT_ROUTE, replace: true }
    );
    return <UnAuthenticatedOnlyRoute_ />;
}
