import { ClientFilters } from './client';
import { InvoiceFilters } from './invoice';

export interface LoggedInUser {
    userId: string;
    displayName: string;
    image?: string;
    roles: string[];
    permissions: PERMISSION[];
    capabilities: Capabilities;
    isTimekeeper?: boolean;
}

export interface User {
    userId: string;
    billerId: string;
    billingManagerId: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    permissions: PERMISSION[];
    secretaryId: string;
}

export enum AUTH_GRANT_TYPE {
    PASSWORD = 'password',
    AUTHORIZATION_CODE = 'authorization_code',
    CLIENT_CREDENTIALS = 'client_credentials',
    REFRESH_TOKEN = 'refresh_token'
}

/**
 * This conforms to the backend service TokenRequestParams which can be
 * used to get the access token in three ways i.e using threes grant types
 */
export interface OAuthTokenRequestProps {
    // The name of the fields conform to oidc convention.
    code?: string;
    client_id?: string;
    client_secret?: string;
    grant_type: AUTH_GRANT_TYPE;
    refresh_token?: string;
    username?: string;
    password?: string;
}

export interface OAuthTokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string | null;
    refresh_expires_in: number;
    userName: string;
    userId: string;
}

export interface Token {
    access_token: string;
    refresh_token: string | null;
    access_token_expiration_ts: number;
    refresh_token_expiration_ts: number;
}

export interface Claim {
    key: string;
    value: string;
}

export interface UserPreferences {
    siderCollapsed?: boolean;
    pageSize?: number;
    invoiceListFilters?: InvoiceFilters;
    clientListFilters?: ClientFilters;
}

export interface Capabilities {
    manageClientMatterSettings: boolean;
    manageSystemSettings: boolean;
    viewDashboard: boolean;
    viewInvoices: boolean;
    manageInvoices: boolean;
    viewClients: boolean;
    viewPayments: boolean;
    viewReminders: boolean;
    manageTags: boolean;
    viewNeedsAttention: boolean;
    canSyncConversations: boolean;
    canManageConversations: boolean;
    canViewOnlinePayments: boolean;
}

export enum ROLE {
    ADMIN = 'admin',
    BILLING_MANAGER = 'billingManager',
    BILLER = 'biller',
    SECRETARY = 'secretary',
    TIME_KEEPER = 'timekeeper',
    COLLECTOR = 'collector',
    EBILLING_SERVICES = 'eBillingServices'
}

export enum PERMISSION {
    CONVERSATION_MANAGER = 'ConversationManager'
}

export enum STORAGE_KEY {
    ODDR_USER_PREFERENCES = 'oddrUserPreferences',
    TOKEN = 'elixirJSON',
    REDIRECT_URL = 'redirectURL',
    DONT_SHOW_BULK_ACTIONS_ENHANCEMENT_TOUR = 'dontShowBulkActionsEnhancementTour'
}

export type BillerLookup = {
    billerName: string;
    userId: string;
};
