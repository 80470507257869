import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from '../stores/store';
import './App.css';
import EmptyPageLoader from '../common/components/EmptyPageLoader';
import AppRoutes from '../routing/AppRoutes';
import ModalContainer from '../common/modals/ModalContainer';

export default observer(function App() {
    const { commonStore } = useStore();

    useEffect(() => {
        commonStore.loadApp();
        addEventListener('storage', commonStore.storageListener);
        return () => {
            removeEventListener('storage', commonStore.storageListener);
        };
    }, []);

    if (!commonStore.appLoaded) {
        return <EmptyPageLoader label={'Loading...'} />;
    }

    return (
        <>
            <ModalContainer />
            <AppRoutes />
        </>
    );
});
