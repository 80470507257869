import { Outlet } from 'react-router-dom';
import SSORedirect from '../../../features/login/sso/SSORedirect';
import { PropsWithChildren } from 'react';
import RoutePaths from '../RoutePaths';
import withConditionAndRedirection from './withConditionAndRedirection';

export type AuthenticatedRouteProps = {
    isAuthenticated: boolean;
    idPConfigured: boolean;
};

// Responsible for authenticated acccess only. If not authenticated, will redirect
// the user for auth flow.
export default function AuthenticatedRoute(props: PropsWithChildren<AuthenticatedRouteProps>) {
    const { children, isAuthenticated, idPConfigured } = props;
    const AuthenticatedRoute_ = withConditionAndRedirection(
        () => <>{children || <Outlet />}</>,
        isAuthenticated,
        idPConfigured
            ? { fallbackUI: <SSORedirect /> }
            : { redirectTo: RoutePaths.LOGIN, replace: true }
    );
    return <AuthenticatedRoute_ />;
}
