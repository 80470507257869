import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { SearchSelectItem } from '../common/components/searchSelect/SearchSelect';
import Filters from '../common/filter/filters';
import { downloadDocumentBlob } from '../common/utils/file';
import { InvoiceURLSearchParamBuilder } from '../common/utils/invoice';
import { Notify } from '../common/utils/notify';
import { isArrayNullOrEmpty } from '../common/utils/objectUtils';
import { URLParams } from '../common/utils/urlParams';
import {
    AssignedClientTag,
    CC_USER,
    Client,
    ClientActivity,
    ClientFilter,
    ClientFilterStatementReceived,
    ClientFilters,
    ClientInvoiceMetrics,
    ClientLookup,
    ClientPatchProperties,
    ClientProperty,
    PROVISIONING_ACTIONS
} from '../models/client';
import { AppConfig } from '../models/config/appConfig';
import { INVOICE_STATE } from '../models/invoice';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { QueryMemo, QueryResult } from '../models/list/query';
import { SearchParams } from '../models/list/search';
import { SortingParams } from '../models/list/sorting';
import { Matter } from '../models/matter';
import { PAGE_KEY } from '../models/pageConfigs';
import { CreateOrUpdatePaymentPlan } from '../models/paymentPlan';
import { Statement, StatementActivity } from '../models/statement';
import { store } from './store';

// TODO: The management of client profile is not optimal currently.
// Every time we load the client will all the properties, which is
// unnecessary. Matter profile has an improved design, where only related
// matted property is loaded on demand. We need to do the same for client profile.
// Since it is an involved task, created a task: https://oddr.atlassian.net/browse/CBH-1676
export default class ClientStore {
    private readonly FILTER_GROUP_ID = 'ClientList';
    loadingClients = false;
    loadingAllClients = false;
    allClients: PaginatedResult<Client> | undefined;
    fetchingClientData = false;
    invitedClients: PaginatedResult<Client> | undefined;
    openInviteModal = false;
    invitingClients = false;
    loading = false;
    clientInviteList: Client[] = [];
    queryMemo = new QueryMemo<ClientLookup[]>();
    client?: Client;
    updatingClient = false;

    matters?: PaginatedResult<Matter>;
    loadingMatters = false;

    loadingClientStatementActivities = false;
    clientStatementActivities: StatementActivity[] = [];

    //These are mainly for non statement client activities.
    loadingClientActivities = false;
    clientActivities: ClientActivity[] = [];

    loadingClientTags = false;
    assignedClientTags: AssignedClientTag[] = [];
    updatingAssignedClientTags = false;

    clientStatements: Statement[] = [];
    loadingStatements = false;

    creatingOrUpdatingPaymentPlan = false;
    completingPaymentPlan = false;
    loadingPaymentLink = false;

    urlParams = new URLParams(
        [
            PAGE_KEY.INVITED_CLIENT_LIST,
            PAGE_KEY.CLIENT_LIST,
            PAGE_KEY.CLIENT_PROFILE_INVOICES_OUTSTANDING,
            PAGE_KEY.CLIENT_PROFILE_INVOICES_PENDING,
            PAGE_KEY.CLIENT_PROFILE_INVOICES_PAID,
            PAGE_KEY.CLIENT_PROFILE_MATTERS,
            PAGE_KEY.CLIENT_PROFILE_NOTES
        ],
        {
            [PAGE_KEY.CLIENT_PROFILE_INVOICES_OUTSTANDING]: 10,
            [PAGE_KEY.CLIENT_PROFILE_MATTERS]: 10,
            [PAGE_KEY.CLIENT_PROFILE_INVOICES_PAID]: 10,
            [PAGE_KEY.CLIENT_PROFILE_INVOICES_PENDING]: 10
        }
    );

    clientFilters = new Filters<keyof ClientFilters>(
        this.FILTER_GROUP_ID,
        {
            [ClientFilter.MY_CLIENTS]: [],
            [ClientFilter.AR_BUCKETS]: [],
            [ClientFilter.OVERDUE]: [],
            [ClientFilter.ANOMALIES]: [],
            [ClientFilter.TAG_NAMES]: [],
            [ClientFilter.FOLLOWUP_PENDING]: [],
            [ClientFilter.CLIENT_DEFAULT_INVOICE_TYPES]: [],
            [ClientFilter.CLIENT_DND]: [],
            [ClientFilter.BILLING_TIME_KEEPERS]: [],
            [ClientFilter.STATEMENTS_RECEIVED]: [],
            [ClientFilter.STATEMENTS_NOT_RECEIVED]: [],
            [ClientFilter.ACTIVE_PAYMENT_PLAN]: [],
            [ClientFilter.CLIENT_STATUS_KEYS]: [],
            [ClientFilter.OFFICE_KEYS]: []
        },
        () => this.resetClientListPaginationParams()
    );

    // TODO: Payor related, will be moved eventually
    updatingPayor = false;

    // This is used for the clientId lookup filter default selections
    clientIdsFilterDefaultSelections?: SearchSelectItem[];

    constructor() {
        makeAutoObservable(this);
    }

    setMyClientsFilter = (myClients: boolean) => {
        this.clientFilters.updateFilter(ClientFilter.MY_CLIENTS, myClients ? ['true'] : []);
    };

    handleClientPortalAction = async (actionType: string, client: Client) => {
        switch (actionType) {
            case PROVISIONING_ACTIONS.ENABLE_ACCESS: {
                await this.enableAccess(client.clientId);
                break;
            }
            case PROVISIONING_ACTIONS.DISABLE_ACCESS: {
                await this.disableAccess(client.clientId);
                break;
            }
            case PROVISIONING_ACTIONS.RESEND_INVITE: {
                await this.resendInvite(client.clientId);
                break;
            }
        }
    };

    get invitedClientListUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(PAGE_KEY.INVITED_CLIENT_LIST);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.INVITED_CLIENT_LIST);
        if (sortingParams!.sortExpression) {
            params.append('orderBy', sortingParams!.sortExpression);
        }

        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.INVITED_CLIENT_LIST);
        if (searchParams!.searchString) {
            params.append('filter', searchParams!.searchString);
        }
        return params;
    }

    get clientListUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(PAGE_KEY.CLIENT_LIST);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.CLIENT_LIST);
        if (sortingParams!.sortExpression.length > 0) {
            params.append('orderBy', sortingParams!.sortExpression);
        }

        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.CLIENT_LIST);
        if (searchParams!.searchString) {
            params.append('queryText', searchParams!.searchString);
        }

        if (this.clientFilters.filters[ClientFilter.MY_CLIENTS].length) {
            params.append('myClients', 'true');
        }
        return params;
    }

    get clientProfileMattersUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(
            PAGE_KEY.CLIENT_PROFILE_MATTERS
        );
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.CLIENT_PROFILE_MATTERS);
        if (sortingParams.sortExpression) {
            params.append('orderBy', sortingParams.sortExpression);
        }
        return params;
    }

    get clientStatusAggregatesUrlParams() {
        const params = new URLSearchParams();
        return params;
    }

    getClientProfileInvoiceStatePageKey = (invoiceState: INVOICE_STATE) =>
        `clientProfileInvoices!${invoiceState}`;

    getclientProfileInvoicesUrlParams = (invoiceState: INVOICE_STATE) => {
        const params = new URLSearchParams();
        const pageKey = this.getClientProfileInvoiceStatePageKey(invoiceState);
        const paginationParams = this.urlParams.getPaginationParams(pageKey);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(pageKey);
        if (sortingParams.sortExpression) {
            params.append('orderBy', sortingParams.sortExpression);
        }
        return params;
    };

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    setSearchParams = (pageKey: PAGE_KEY, searchParams: SearchParams) => {
        this.urlParams.setSearchParams(pageKey, searchParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    resetClientListPaginationParams = () => {
        this.setPaginationParams(PAGE_KEY.CLIENT_LIST, new PaginationParams());
    };

    toggleInviteModal = () => {
        runInAction(() => {
            this.openInviteModal = !this.openInviteModal;
        });
    };

    updateClientIdsFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        this.clientIdsFilterDefaultSelections = selections;
    };

    loadInvitedClients = async () => {
        this.loading = true;
        try {
            this.loadingClients = true;
            const urlParams = this.invitedClientListUrlParams;
            urlParams.append('filter', 'provisioningStatus!=NotInvited');
            const clients = await agent.Clients.invitedList(urlParams);
            runInAction(() => {
                this.invitedClients = clients;
                this.loadingClients = false;
            });
        } catch (error: any) {
            this.loadingClients = false;
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private getClientListUrlParams = () => {
        const urlParams = this.clientListUrlParams;
        const filters = this.clientFilters.filters;
        filters[ClientFilter.AR_BUCKETS].forEach((arbucket) =>
            urlParams.append('arbuckets', arbucket)
        );
        filters[ClientFilter.TAG_NAMES].forEach((selectedTag) =>
            urlParams.append('tagNames', selectedTag)
        );
        filters[ClientFilter.ANOMALIES].forEach((anomaly) =>
            urlParams.append('anomalies', anomaly)
        );
        if (filters[ClientFilter.OVERDUE].length > 0) {
            urlParams.append('arbuckets', 'overdue');
        }
        if (filters[ClientFilter.FOLLOWUP_PENDING].length > 0) {
            urlParams.append('followuppending', 'true');
        }
        if (filters[ClientFilter.ACTIVE_PAYMENT_PLAN].length > 0) {
            urlParams.append('hasActivePaymentPlan', 'true');
        }
        filters[ClientFilter.BILLING_TIME_KEEPERS].forEach((selectedTimeKeeper) =>
            urlParams.append('billingTimekeeperIds', selectedTimeKeeper)
        );
        filters[ClientFilter.CLIENT_DEFAULT_INVOICE_TYPES].forEach((type) =>
            urlParams.append('clientDefaultInvoiceTypes', type)
        );
        filters[ClientFilter.CLIENT_DND].forEach((dnd) => {
            urlParams.append(dnd, 'true');
        });
        filters[ClientFilter.STATEMENTS_NOT_RECEIVED].forEach((month) => {
            urlParams.append(
                ClientFilterStatementReceived.STATEMENTS_NOT_RECEIVED_FOR_MONTH,
                month
            );
        });
        filters[ClientFilter.CLIENT_STATUS_KEYS].forEach((clientStatusKey) => {
            urlParams.append(ClientFilter.CLIENT_STATUS_KEYS, clientStatusKey);
        });
        filters[ClientFilter.OFFICE_KEYS].forEach((officeKey) => {
            urlParams.append(ClientFilter.OFFICE_KEYS, officeKey);
        });
        return urlParams;
    };

    loadAllClients = async () => {
        this.loadingAllClients = true;
        try {
            const clients = await agent.Clients.getAllClients(this.getClientListUrlParams());
            runInAction(() => {
                this.allClients = clients;
                this.loadingAllClients = false;
            });
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingAllClients = false;
            });
        }
    };

    queryClients = async (query: string) => {
        try {
            const queryClient = this.queryMemo.getRecord(query);
            if (queryClient) {
                return new QueryResult(queryClient);
            }
            const params = new URLSearchParams();
            params.append('queryText', query);
            const result = await agent.Clients.lookup(params);
            this.queryMemo.addRecord(query, result);
            return new QueryResult(result);
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    loadClient = async (clientId: string, properties?: ClientProperty[], showLoader = true) => {
        let client: Client | undefined;
        const urlParams = new URLSearchParams();
        properties?.forEach((property) => urlParams.append('properties', property));
        if (showLoader) {
            this.loading = true;
        }
        try {
            client = await agent.Clients.get(clientId, urlParams);
            runInAction(() => {
                this.client = client;
                if (client && client.activities) {
                    this.clientActivities = client.activities;
                } else {
                    this.clientActivities = [];
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            if (showLoader) {
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
        return client;
    };

    getClient = async (clientId: string) => {
        try {
            return await agent.Clients.get(clientId);
        } catch (error) {
            console.log(error);
        }
    };

    addClientToInviteList = async (clientId: string) => {
        this.fetchingClientData = true;
        try {
            const alreadyAdded = this.clientInviteList.some(
                (client) => client.clientId === clientId
            );
            if (alreadyAdded) {
                return;
            }
            const clientData = await agent.Clients.get(clientId);
            runInAction(() => {
                this.clientInviteList = [...this.clientInviteList, clientData];
                store.modalStore.updateIsOkDisabled(!this.clientInviteList.length);
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.fetchingClientData = false;
            });
        }
    };

    removeClientFromInviteList = (clientId: string) => {
        this.clientInviteList = this.clientInviteList.filter(
            (client) => client.clientId != clientId
        );
        store.modalStore.updateIsOkDisabled(!this.clientInviteList.length);
    };

    clearClientInviteList = () => {
        this.clientInviteList = [];
    };

    sendInvites = async () => {
        this.loading = true;
        this.invitingClients = true;
        let invitesSent = 0;
        for (let i = 0; i < this.clientInviteList.length; ++i) {
            try {
                const params = new URLSearchParams();
                params.append('rerequest', 'false');
                await agent.Clients.invite(this.clientInviteList[i].clientId, params);
                runInAction(() => (invitesSent = invitesSent + 1));
            } catch (error: any) {
                console.log(error);
                break;
            }
        }
        if (invitesSent > 0) {
            Notify.success(`${invitesSent} invitation(s) sent successfully`);
        }

        runInAction(() => {
            this.clearClientInviteList();
            this.invitingClients = false;
            this.loading = false;
        });
        this.toggleInviteModal();
    };

    resendInvite = async (clientId: string) => {
        this.loading = true;
        try {
            const params = new URLSearchParams();
            params.append('rerequest', 'true');
            await agent.Clients.invite(clientId, params);
            Notify.success('Invitation resent successfully');
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    disableAccess = async (clientId: string) => {
        this.loading = true;
        try {
            await agent.Clients.disablePortalAccess(clientId);
            Notify.success(`Client's portal access disabled successfully`);
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    enableAccess = async (clientId: string) => {
        this.loading = true;
        try {
            await agent.Clients.enablePortalAccess(clientId);
            Notify.success(`Client's portal access enabled successfully`);
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    getClientInvoiceMetrics = async (clientId: string) => {
        this.loading = true;
        let clientInvoiceMetrics: ClientInvoiceMetrics | undefined;
        try {
            const clientInvoiceMetrics_ = await agent.Clients.getClientInvoiceMetrics(clientId);
            clientInvoiceMetrics = clientInvoiceMetrics_;
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
        return clientInvoiceMetrics;
    };

    loadClientMatters = async (clientId: string) => {
        const urlParams = this.clientProfileMattersUrlParams;
        try {
            this.loadingMatters = true;
            const matters = await agent.Clients.getMatters(clientId, urlParams);
            runInAction(() => {
                this.matters = matters;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingMatters = false;
            });
        }
    };

    shareClientWithTimekeeper = async (clientId: string, comment: string, ccUsers: CC_USER[]) => {
        let clientShared = false;
        try {
            await agent.Clients.shareClientWithTimekeeper(clientId, {
                comment,
                shareWithBillingTimekeeper: ccUsers.includes(CC_USER.BILLING_TIMEKEEPER),
                shareWithCollectionTimekeeper: ccUsers.includes(CC_USER.COLLECTION_TIMEKEEPER)
            });
            clientShared = true;
        } catch (err) {
            console.log(err);
        }
        return clientShared;
    };

    submitInvoices = async (
        clientId: string,
        emailSubject: string | null,
        emailMessage: string | null,
        ccCurrentUser?: boolean,
        ccBillingTimekeeper?: boolean
    ) => {
        this.loading = true;
        let invoicesSubmitted = false;
        try {
            await agent.Clients.submitInvoices(clientId, {
                emailMessage,
                emailSubject,
                ccCurrentUser,
                ccBillingTimekeeper
            });
            invoicesSubmitted = true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
        return invoicesSubmitted;
    };

    getClientStatementActivities = async (clientId: string) => {
        try {
            this.loadingClientStatementActivities = true;
            const data = await agent.Clients.getStatementActivities(clientId);

            // refresh the activities only if the existing array is empty or if the most recent
            // activity has a newer timestamp than the most recent activity already cached.
            if (
                isArrayNullOrEmpty(this.clientStatementActivities) ||
                (data && this.clientStatementActivities[0].createdOn !== data[0]?.createdOn)
            ) {
                runInAction(() => {
                    this.clientStatementActivities = data;
                });
            }
        } catch (error) {
            console.log(error);
            Notify.error('An error occurred during loading the activities. Please try again.');
        } finally {
            runInAction(() => {
                this.loadingClientStatementActivities = false;
            });
        }
    };

    getClientActivities = async (clientId: string) => {
        this.loadingClientActivities = true;
        try {
            const clientActivities = await agent.Clients.getClientActivities(clientId);
            runInAction(() => {
                this.clientActivities = clientActivities;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingClientActivities = false));
        }
    };

    getAssignedClientTags = async (clientId: string) => {
        this.loadingClientTags = true;
        try {
            const assignedClientTags = await agent.Clients.getAssignedClientTags(clientId);
            runInAction(() => {
                this.assignedClientTags = assignedClientTags;
                if (this.client) {
                    this.client.tagNames = assignedClientTags.map((tag) => tag.clientTag.name);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingClientTags = false));
        }
    };

    updateAssignedClientTags = async (clientId: string, tagKeys: string[]) => {
        this.updatingAssignedClientTags = true;
        try {
            await agent.Clients.updateAssignedClientTags(clientId, tagKeys);
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingAssignedClientTags = false;
            });
        }
    };

    updateAttachDocumentsToEmail = async (clientId: string, attachDocumentsToEmail: boolean) => {
        this.updatingClient = true;
        try {
            await agent.Clients.updateAttachDocumentsToEmail(clientId, attachDocumentsToEmail);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.updatingClient = false;
            });
        }
    };

    updateBillToEmails = async (
        clientId: string,
        billToEmails: string[],
        updateCurrentClient = false,
        provisional: boolean
    ) => {
        this.updatingClient = true;
        let updated = false;
        try {
            await agent.Clients.updateBillToEmails(clientId, billToEmails, provisional);
            updated = true;
            if (updateCurrentClient) {
                runInAction(() => {
                    if (this.client) {
                        // This prevents an extra API call to load the client
                        // TODO: Consider not using instore management? An extra API call doesn't hurt
                        // If we consider removing, we have to refactor such logics in other places such as
                        // attachments, comments
                        this.client.billToEmails = billToEmails;
                        this.client.contactEmail = billToEmails[0];
                    }
                });
            }
        } catch (err) {
            console.log(err);
            Notify.error('Unable to update emails. Please try again.');
        } finally {
            runInAction(() => {
                this.updatingClient = false;
            });
        }
        return updated;
    };

    // TODO: Might not be the correct place for this, but for now this is fine.
    // When we refactor for payor specific changes, we can move this
    updatePayorEmails = async (payorId: string, emails: string[]) => {
        this.updatingPayor = true;
        let updated = false;
        try {
            await agent.Payors.updateEmails(payorId, emails, true);
            updated = true;
        } catch (err) {
            console.log(err);
            Notify.error('Unable to update emails. Please try again.');
        } finally {
            runInAction(() => {
                this.updatingPayor = false;
            });
        }
        return updated;
    };

    getClientStatements = async (clientId: string) => {
        this.loadingStatements = true;
        try {
            const statements = await agent.Clients.getClientStatements(clientId);
            return statements;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingStatements = false));
        }
    };

    updateDefaultInvoiceType = async (
        clientId: string,
        defaultInvoiceType: string | null,
        cascadeUpdate: boolean
    ) => {
        this.updatingClient = true;
        try {
            await agent.Clients.updateDefaultInvoiceType(
                clientId,
                defaultInvoiceType,
                cascadeUpdate
            );
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.updatingClient = false;
            });
        }
    };

    updateStatementEmails = async (
        clientId: string,
        statementemails: string[],
        provisional: boolean
    ) => {
        let updated = false;
        try {
            await agent.Clients.updateStatementEmails(clientId, statementemails, provisional);
            updated = true;
        } catch (err) {
            console.log(err);
        }
        return updated;
    };

    createPaymentPlan = async (
        clientId: string,
        createOrUpdatePaymentPlan: CreateOrUpdatePaymentPlan
    ) => {
        this.creatingOrUpdatingPaymentPlan = true;
        try {
            await agent.Clients.createPaymentPlan(clientId, createOrUpdatePaymentPlan);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.creatingOrUpdatingPaymentPlan = false;
            });
        }
    };

    updatePaymentPlan = async (
        paymentPlanId: string,
        createOrUpdatePaymentPlan: CreateOrUpdatePaymentPlan
    ) => {
        this.creatingOrUpdatingPaymentPlan = true;
        try {
            await agent.PaymentPlans.updatePaymentPlan(paymentPlanId, createOrUpdatePaymentPlan);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.creatingOrUpdatingPaymentPlan = false;
            });
        }
    };

    completePaymentPlan = async (paymentPlanId: string, comment: string | null) => {
        this.completingPaymentPlan = true;
        try {
            await agent.PaymentPlans.completePaymentPlan(paymentPlanId, comment);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.completingPaymentPlan = false;
            });
        }
    };

    patchClient = async (clientId: string, patchProperties: ClientPatchProperties) => {
        this.updatingClient = true;
        try {
            await agent.Clients.patchClient(clientId, patchProperties);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.updatingClient = false;
            });
        }
    };

    bulkSendMatterStatements = async (
        clientId: string,
        checkOnly: boolean,
        matterIds: string[],
        statementMonth: string,
        markAsSent: boolean
    ) => {
        let response;
        try {
            response = await agent.Clients.bulkSendStatements(clientId, {
                checkOnly,
                matterIds,
                includeMatterStatements: null,
                excludeClientStatement: true,
                statementMonth,
                setStatusOnly: markAsSent
            });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    getClientPaymentlink = async (clientId: string) => {
        this.loadingPaymentLink = true;
        try {
            const paymentLink = await agent.Clients.getClientPaymentlink(clientId);
            return paymentLink;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingPaymentLink = false;
            });
        }
    };

    exportClientsToExcel = async () => {
        try {
            const exportedBlob = await agent.Clients.exportClientsToExcel(
                this.getClientListUrlParams()
            );
            const fileName = `Clients-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(exportedBlob, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    exportMattersToExcel = async (clientId: string) => {
        try {
            const exportedBlob = await agent.Clients.exportMattersToExcel(
                clientId,
                this.clientProfileMattersUrlParams
            );
            const fileName = `Matters-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(exportedBlob, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    exportInvoicesToExcel = async (clientId: string, invoiceState: INVOICE_STATE) => {
        try {
            const paramsBuilder = new InvoiceURLSearchParamBuilder(
                invoiceState as INVOICE_STATE
            ).addReversed(invoiceState === INVOICE_STATE.REVERSED);

            if (invoiceState !== INVOICE_STATE.REVERSED) {
                paramsBuilder.addPaidInFull().addSubmitted();
            }
            const exportedBlob = await agent.Clients.exportInvoicesToExcel(
                clientId,
                paramsBuilder.urlSearchParams
            );
            const fileName = `Client-${clientId}-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(exportedBlob, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    clearClientData = () => {
        this.client = undefined;
        this.matters = undefined;
        this.urlParams.reinitializeParams([
            PAGE_KEY.CLIENT_PROFILE_INVOICES_OUTSTANDING,
            PAGE_KEY.CLIENT_PROFILE_INVOICES_PAID,
            PAGE_KEY.CLIENT_PROFILE_INVOICES_PENDING,
            PAGE_KEY.CLIENT_PROFILE_MATTERS
        ]);
        this.clientActivities = [];
        this.clientStatementActivities = [];
        this.assignedClientTags = [];
    };
}
