import { makeAutoObservable, runInAction } from 'mobx';
import { store } from './store';
import agent from '../api/agent';
import { ErrorCounts } from '../models/error';

export default class ErrorStore {
    loadingErrorCounts = false;
    errorCounts?: ErrorCounts;

    constructor() {
        makeAutoObservable(this);
    }

    getErrorCounts = async () => {
        this.loadingErrorCounts = true;
        const cacheKey = 'errorCounts';
        try {
            if (store.cacheStore.isCacheExpired(cacheKey, 10)) {
                const errorCounts = await agent.Errors.getErrorCounts();
                runInAction(() => {
                    this.errorCounts = errorCounts;
                    store.cacheStore.updateCacheTimestamp(cacheKey);
                });
            }
            return this.errorCounts;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loadingErrorCounts = false));
        }
    };
}
