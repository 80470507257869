import { Layout } from 'antd';
import NavHeader from '../header/NavHeader';
import SideBar from '../sideBar/SideBar';
import { useStore } from '../../stores/store';
import { Suspense, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import EmptyPageLoader from '../../common/components/EmptyPageLoader';
const { Content, Header } = Layout;

// This layout is used for Logged in experience
export default function MainLayout() {
    const { userStore } = useStore();
    const [collapsed, setCollapsed] = useState(userStore.userPreferences.siderCollapsed!);

    const handleCollapsibleIconClick = () => {
        setCollapsed(!collapsed);
        userStore.setUserPreferences({ siderCollapsed: !collapsed });
    };

    return (
        <Layout>
            <Header style={{ padding: 0 }}>
                <NavHeader />
            </Header>
            <Layout>
                <SideBar collapsed={collapsed} />
                <Content>
                    <div className='site-container'>
                        {collapsed ? (
                            <MenuUnfoldOutlined
                                className='collapse'
                                onClick={handleCollapsibleIconClick}
                                style={{ fontSize: '12px', zIndex: 20 }}
                            />
                        ) : (
                            <MenuFoldOutlined
                                className='collapse'
                                onClick={handleCollapsibleIconClick}
                                style={{ fontSize: '12px', zIndex: 20 }}
                            />
                        )}
                        <Suspense fallback={<EmptyPageLoader label='' />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </Layout>
            <div className='site-footer'>© Oddr, Inc. All rights reserved.</div>
        </Layout>
    );
}
