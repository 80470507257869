import { ConfigProvider, Select } from 'antd';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { trim } from '../../../app/common/utils/string';
import CustomEmailTagRender from './CustomEmailTagRender';
import { checkEmailValidity, splitEmails } from './helper';

type Props = {
    emails?: string | string[];
    onChange: (emailList: string[], isValid: boolean) => void;
};

export default function EmailListInput(props: Props) {
    const { emails, onChange } = props;
    const [emailList, setEmailList] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const MAX_EMAIL_COUNT = 15;

    // These keys trigger creation of an email tag in the EmailListInput
    const TAG_CREATION_KEY_CODES = ['Enter', 'Semicolon', 'Comma'];

    useEffect(() => {
        if (emails) {
            let emailList = emails;
            if (typeof emailList === 'string') {
                emailList = splitEmails(emailList);
            }
            emailList = uniq(emailList);
            handleEmailChange(emailList);
        }
    }, []);

    const handleEmailChange = (emailListDelta: string[]) => {
        // If the entered email list is more than 15, we truncate it to 15
        if (emailList.length > MAX_EMAIL_COUNT) {
            emailListDelta = emailList.slice(0, MAX_EMAIL_COUNT);
        }

        // We only trigger onChange only if the entered email is new
        if (!emailList.includes(searchValue)) {
            setEmailList(emailListDelta);
            onChange(
                emailListDelta,
                emailListDelta.every((email) => checkEmailValidity(email))
            );
        }

        setSearchValue('');
    };

    const handleEmailTagDelete = (email: string) => {
        const currentEmail = searchValue;
        const remainingEmails = emailList.filter((email_) => email_ !== email);
        handleEmailChange(remainingEmails);
        setSearchValue(currentEmail);
    };

    const handleDoubleClick = (email: string) => {
        const remainingEmails = emailList.filter((email_) => email_ !== email);
        if (searchValue.length) {
            remainingEmails.push(searchValue);
        }
        handleEmailChange(remainingEmails);
        setSearchValue(email);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent) => {
        if (TAG_CREATION_KEY_CODES.includes(e.code) && !trim(searchValue).length) {
            e.stopPropagation();
            setSearchValue('');
        }
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Select: {
                        fontSizeLG: 14
                    }
                }
            }}
        >
            <Select
                mode='tags'
                autoClearSearchValue={false}
                notFoundContent={null}
                value={emailList}
                suffixIcon={null}
                searchValue={searchValue}
                onSearch={(value) => setSearchValue(value)}
                onChange={handleEmailChange}
                onInputKeyDown={handleInputKeyDown}
                placeholder='Email Addresses (max 15)'
                style={{ width: '100%' }}
                tokenSeparators={[';', ',']}
                tagRender={(props) => (
                    <CustomEmailTagRender
                        {...props}
                        onClose={handleEmailTagDelete}
                        onDoubleClick={handleDoubleClick}
                    />
                )}
                maxTagTextLength={32}
                allowClear
                size='large'
                dropdownStyle={{ display: 'none' }}
            />
        </ConfigProvider>
    );
}
