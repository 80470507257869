import { observer } from 'mobx-react-lite';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';

interface Props {
    error: string | null | undefined;
}

export default observer(function InvoiceNeedsAttentionIndicator({ error }: Props) {
    if (!error) {
        return null;
    }

    return (
        <TooltipComponent title={error}>
            <ExclamationCircleFilled style={{ color: ThemeColor.ColorError }} />
        </TooltipComponent>
    );
});
