import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import Filters from '../common/filter/filters';
import { URLParams } from '../common/utils/urlParams';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import { SortingParams } from '../models/list/sorting';
import { PAGE_KEY } from '../models/pageConfigs';
import { Payment, PaymentFilter, PaymentFilters } from '../models/payment';
import { store } from './store';

export default class PaymentStore {
    private readonly FILTER_GROUP_ID = 'PaymentList';
    loadingPayments = false;
    payments: PaginatedResult<Payment> | undefined;

    urlParams = new URLParams([PAGE_KEY.PAYMENT_LIST], {
        [PAGE_KEY.PAYMENT_LIST]: 10
    });

    paymentFilters = new Filters<keyof PaymentFilters>(
        this.FILTER_GROUP_ID,
        { [PaymentFilter.CLIENTS]: [] },
        () => this.resetPaymentListPaginationParams()
    );

    constructor() {
        makeAutoObservable(this);
    }

    get PaymentListUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(PAGE_KEY.PAYMENT_LIST);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.PAYMENT_LIST);
        if (sortingParams!.sortExpression.length > 0) {
            params.append('orderBy', sortingParams!.sortExpression);
        }

        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.PAYMENT_LIST);
        if (searchParams!.searchString) {
            params.append('queryText', searchParams!.searchString);
        }
        return params;
    }

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    setSearchParams = (pageKey: PAGE_KEY, searchParams: SearchParams) => {
        this.urlParams.setSearchParams(pageKey, searchParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    resetPaymentListPaginationParams = () => {
        this.setPaginationParams(PAGE_KEY.PAYMENT_LIST, new PaginationParams());
    };

    loadPayments = async () => {
        this.loadingPayments = true;
        try {
            const urlParams = this.PaymentListUrlParams;
            const filters = this.paymentFilters.filters;
            filters[PaymentFilter.CLIENTS].forEach((selectedClient) =>
                urlParams.append('clientIds', selectedClient)
            );
            const payments = await agent.Payments.getPayments(urlParams);
            runInAction(() => {
                this.payments = payments;
                this.loadingPayments = false;
            });
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingPayments = false;
            });
        }
    };
}
