import { useState } from 'react';
import { ArrowUpOutlined, PlusCircleFilled } from '@ant-design/icons/lib';
import { Button, ConfigProvider, Divider, Input, Space } from 'antd';
import { trim } from '../../utils/string';

type Props = {
    onConfirm: (comment: string) => void;
    onOpen: () => void;
};
export default function Tip(props: Props) {
    const { onConfirm, onOpen } = props;
    const [showTextArea, setShowTextArea] = useState(false);
    const [comment, setComment] = useState<string>('');

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorBgContainer: '#FFF8B6',
                        colorPrimaryActive: '#FFF8B6',
                        colorPrimary: '#FFF8B6',
                        colorBorder: '#FFF8B6',
                        colorPrimaryHover: '#FFF8B6',
                        controlOutline: 'none',
                        fontSize: 14
                    },
                    Button: {
                        colorPrimary: '#1677FF',
                        colorPrimaryHover: '#1677FF'
                    }
                }
            }}
        >
            {showTextArea ? (
                <Space
                    align='end'
                    direction='vertical'
                    size={4}
                    style={{ backgroundColor: '#fff8b6', padding: 4 }}
                >
                    <Input.TextArea
                        placeholder='Add your note...'
                        ref={(ref) =>
                            ref?.focus({
                                preventScroll: true
                            })
                        }
                        maxLength={256}
                        showCount
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        style={{ width: 200, marginBottom: 18 }}
                        styles={{ textarea: { minHeight: 45, marginTop: 4 } }}
                        bordered={false}
                        classNames={{ textarea: 'modified-scroll-bar' }}
                    />
                    <Divider style={{ margin: 0, width: 200 }} />
                    <Button
                        type='primary'
                        shape='circle'
                        size='small'
                        icon={<ArrowUpOutlined />}
                        disabled={!trim(comment).length}
                        onClick={() => onConfirm(trim(comment))}
                    />
                </Space>
            ) : (
                <div
                    className='tip__compact'
                    onClick={() => {
                        onOpen();
                        setShowTextArea(true);
                    }}
                >
                    <Space>
                        <PlusCircleFilled />
                        Click here to annotate
                    </Space>
                </div>
            )}
        </ConfigProvider>
    );
}
