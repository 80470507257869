import { observer } from 'mobx-react-lite';
import './NavHeader.css';
import { CaretDownOutlined, LogoutOutlined } from '@ant-design/icons';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Image from 'antd/es/image';
import { useStore } from '../../stores/store';
import { useNavigate } from 'react-router-dom';
import AvatarComponent from '../../common/components/AvatarComponent';
import { Col, Row, Typography } from 'antd';
import TooltipComponent from '../../common/components/TooltipComponent';
import { ThemeColor } from '../../common/constants/color';
import GlobalSearchComponent from '../GlobalSearchComponent';

type Props = {
    simpleHeader?: boolean;
};

export default observer(function NavHeader(props: Props) {
    const { simpleHeader } = props;
    const {
        userStore,
        uiStore: { uiPreferences },
        tenantStore
    } = useStore();

    const { Text } = Typography;

    const navigate = useNavigate();

    const handleMenuClick = () => {
        userStore.logout(true);
    };

    const menu = {
        onClick: handleMenuClick,
        items: [
            {
                label: 'Sign Out',
                key: 'logout',
                icon: <LogoutOutlined />
            }
        ]
    };

    if (!userStore.isLoggedIn) {
        return null;
    }

    return (
        <Row justify='space-between' className='nav-header'>
            <Col span={4}>
                <Image
                    src='/assets/logo-white.png'
                    preview={false}
                    wrapperClassName='header-oddr-logo'
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(uiPreferences.homePage)}
                    alt='Oddr Logo'
                />
                {tenantStore.tenant?.platformTestModeEnabled && (
                    <TooltipComponent title='The system is in test mode, and no emails will be sent to actual clients or users.'>
                        <Text style={{ marginLeft: '16px', color: ThemeColor.ColorWarning }}>
                            Test Mode
                        </Text>
                    </TooltipComponent>
                )}
            </Col>
            {!simpleHeader && (
                <>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        span={16}
                    >
                        <GlobalSearchComponent />
                    </Col>
                    <Col
                        span={4}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Space size={1} style={{ marginLeft: '20px' }}>
                            <AvatarComponent userName={userStore.user!.displayName} showBorder />
                            <Dropdown menu={menu}>
                                <Button
                                    className='user-button'
                                    icon={<CaretDownOutlined />}
                                    iconPosition='end'
                                >
                                    {userStore?.user?.displayName}
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                </>
            )}
        </Row>
    );
});
