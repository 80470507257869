import {
    DocumentStatusAggregates,
    Invoice,
    InvoiceActivity,
    InvoiceComment,
    InvoiceDocumentAttachment,
    InvoiceLookup,
    InvoiceStatus,
    WriteOff
} from '../models/invoice';
import { PaginatedResult } from '../models/list/pagination';
import { Note } from '../models/note';
import { requests } from './axiosAgent';

const Invoices = {
    BASE_URL: '/invoices',
    getInvoiceIdentifierUrl: (invoiceId: string) => `${Invoices.BASE_URL}/${invoiceId}`,
    getInvoiceAttachmentsBaseUrl: (invoiceId: string) =>
        `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/attachments`,
    getInvoiceAttachmentsIdentifierUrl: (invoiceId: string, attachmentId: string) =>
        `${Invoices.getInvoiceAttachmentsBaseUrl(invoiceId)}/${attachmentId}`,
    getInvoiceNotesBaseUrl: (invoiceId: string) =>
        `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/notes`,
    getInvoiceAnnotationsBaseUrl: (invoiceId: string) =>
        `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/documents/invoice/annotations`,
    list: (params: URLSearchParams) =>
        requests.get<PaginatedResult<Invoice>>(Invoices.BASE_URL, { params }),
    invoiceLookup: (params?: URLSearchParams) =>
        requests.get<InvoiceLookup[]>(`${Invoices.BASE_URL}/lookup`, { params }),
    get: (invoiceId: string, params?: URLSearchParams) =>
        requests.get<Invoice>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}`, { params }),
    getErrorCounts: (params?: URLSearchParams) =>
        requests.get<any>(`${Invoices.BASE_URL}/_count`, { params }),
    submit: (
        invoiceId: string,
        body: {
            emailSubject: string | null;
            emailMessage: string | null;
            ccCurrentUser: boolean | null;
            ccBillingTimekeeper: boolean | null;
            emailRecipients: string[] | null;
            ccBiller: boolean | null;
        },
        params?: URLSearchParams
    ) =>
        requests.post<void>(
            `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_submit`,
            { ...body },
            { params }
        ),
    bulksubmit: (
        body: {
            invoiceIds: string[];
            ccCurrentUser: boolean | null;
            ccBillingTimekeeper: boolean | null;
        },
        params?: URLSearchParams
    ) => requests.post<void>(`${Invoices.BASE_URL}/_bulksubmit`, { ...body }, { params }),
    share: (
        invoiceId: string,
        body: {
            emailSubject: string | null;
            emailMessage: string | null;
            emailRecipients: string[] | null;
            ccBillingTimekeeper: boolean | null;
            ccCurrentUser: boolean | null;
            ccBiller: boolean | null;
        }
    ) => requests.post<void>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_share`, { ...body }),
    bulkShare: (body: {
        ccBillingTimekeeper: boolean | null;
        ccCurrentUser: boolean | null;
        invoiceIds: string[];
    }) => requests.post<void>(`${Invoices.BASE_URL}/_bulkshare`, { ...body }),
    sendInvoiceToReview: (invoiceId: string, comment: string | null) =>
        requests.post<void>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_sendforreview`, {
            comment
        }),
    sendForSubmission: (invoiceId: string, comment: string | null) =>
        requests.post<void>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_sendforsubmission`, {
            comment
        }),
    completeReview: (invoiceId: string, comment: string | null) =>
        requests.post<void>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_completeReview`, {
            comment
        }),
    getDocument: (invoiceId: string, params?: URLSearchParams) =>
        requests.get<Blob>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/documents/invoice`, {
            responseType: 'blob',
            params: params
        }),
    getInvoiceActivities: (invoiceId: string) =>
        requests.get<InvoiceActivity[]>(
            `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/activities`
        ),
    getAnnotations: (invoiceId: string) =>
        requests.get<string>(Invoices.getInvoiceAnnotationsBaseUrl(invoiceId)),
    updateAnnotations: (invoiceId: string, content: string) =>
        requests.post<string>(Invoices.getInvoiceAnnotationsBaseUrl(invoiceId), content, {
            headers: {
                'Content-Type': 'text/plain'
            }
        }),
    getAggregates: (params: URLSearchParams) =>
        requests.get<DocumentStatusAggregates>(`${Invoices.BASE_URL}/aggregatesByStatus`, {
            params
        }),
    uploadInvoiceDocument: (invoiceId: string, formData: FormData) =>
        requests.post(
            `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/documents/invoice`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ),
    createAttachment: (invoiceId: string, formData: FormData) =>
        requests.post<InvoiceDocumentAttachment>(
            Invoices.getInvoiceAttachmentsBaseUrl(invoiceId),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ),
    getAttachments: (invoiceId: string, params: URLSearchParams) =>
        requests.get<InvoiceDocumentAttachment[]>(
            Invoices.getInvoiceAttachmentsBaseUrl(invoiceId),
            {
                params
            }
        ),
    deleteAttachment: (invoiceId: string, attachmentId: string) =>
        requests.delete<InvoiceDocumentAttachment>(
            Invoices.getInvoiceAttachmentsIdentifierUrl(invoiceId, attachmentId)
        ),
    getAttachmentContent: (invoiceId: string, attachmentId: string) =>
        requests.get<Blob>(
            `${Invoices.getInvoiceAttachmentsIdentifierUrl(invoiceId, attachmentId)}/content`,
            {
                responseType: 'blob'
            }
        ),
    updateAttachment: (invoiceId: string, attachmentId: string, description: string) =>
        requests.put<InvoiceDocumentAttachment>(
            Invoices.getInvoiceAttachmentsIdentifierUrl(invoiceId, attachmentId),
            {
                description
            }
        ),
    deleteComment: (invoiceId: string, commentId: string) =>
        requests.delete<InvoiceComment>(
            `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/comments/${commentId}`
        ),
    updateComment: (invoiceId: string, commentId: string, content: string) =>
        requests.put<InvoiceComment>(
            `${Invoices.getInvoiceIdentifierUrl(invoiceId)}/comments/${commentId}`,
            {
                content
            }
        ),
    createComment: (invoiceId: string, content: string) =>
        requests.post<InvoiceComment>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/comments`, {
            content
        }),
    createNote: (invoiceId: string, content: string, tagKeys: string[]) =>
        requests.post<Note>(Invoices.getInvoiceNotesBaseUrl(invoiceId), {
            content,
            tagKeys
        }),
    getNotes: (invoiceId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<Note>>(Invoices.getInvoiceNotesBaseUrl(invoiceId), { params }),
    requestWriteOff: (
        invoiceId: string,
        amount: number,
        comment: string | null,
        invoiceWriteOffReasonKey: string | null
    ) =>
        requests.post(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_requestwriteoff`, {
            amount,
            comment,
            invoiceWriteOffReasonKey
        }),
    completeWriteOff: (invoiceId: string, approved: boolean, comment: string | null) =>
        requests.post(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/_completewriteoff`, {
            approved,
            comment
        }),
    getWriteOff: (invoiceId: string) =>
        requests.get<WriteOff>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/writeoff`),
    updateEbillingStatus: (invoiceId: string, eBillingStatusKey: string) =>
        requests.put<void>(`${Invoices.getInvoiceIdentifierUrl(invoiceId)}/ebillingstatus`, {
            eBillingStatusKey
        }),
    getInvoiceStatues: () => requests.get<InvoiceStatus[]>(`${Invoices.BASE_URL}/_statuses`),
    clearError: (invoiceIds: string[]) =>
        requests.post<void>(`${Invoices.BASE_URL}/_clearerror`, { invoiceIds }),
    exportInvoicesToExcel: (params: URLSearchParams) =>
        requests.get<Blob>(`${Invoices.BASE_URL}/_export`, {
            responseType: 'blob',
            params: params
        })
};

export default Invoices;
