import Loader from './Loader';

type Props = {
    label?: string;
};

export default function EmptyPageLoader({ label }: Props) {
    return (
        <Loader spinning size='large' label={label ?? 'Loading...'}>
            {/* This empty div makes the loader to be centered */}
            <div style={{ height: '100vh', width: '100%' }} />
        </Loader>
    );
}
