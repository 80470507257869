import { FunctionComponent, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import EmptyPageLoader from '../../common/components/EmptyPageLoader';

type ConditionFallback = {
    redirectTo?: string;
    replace?: boolean;
    fallbackUI?: React.ReactNode;
} & (
    | {
          redirectTo: string;
          replace?: boolean;
      }
    | {
          fallbackUI: React.ReactNode;
      }
);

// HOC that routes or redirects the user to fallback
export default function withConditionAndRedirection(
    Component: FunctionComponent,
    condition: boolean,
    fallback?: ConditionFallback
) {
    const ConditionalComponent = (props: any) => {
        return (
            <Suspense fallback={<EmptyPageLoader label='' />}>
                {condition ? (
                    <Component {...props} />
                ) : fallback?.redirectTo ? (
                    <Navigate to={fallback.redirectTo} replace={fallback.replace} />
                ) : (
                    fallback?.fallbackUI
                )}
            </Suspense>
        );
    };
    return ConditionalComponent;
}
