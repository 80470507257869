import {
    DeleteOutlined,
    DownloadOutlined,
    ExportOutlined,
    InboxOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Button, Col, Flex, Row, Space, Typography, Upload } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import mime from 'mime';
import { ReactNode, useEffect, useState } from 'react';
import FileIcon from '../components/FileIcon';
import { ThemeColor } from '../constants/color';
import { downloadDocumentBlob, getExtension } from '../utils/file';
import './styles.css';

export type Props = {
    multiSelect: boolean;
    onSelect: (value: UploadFile[]) => void;
    allowedExtensions?: string[];
    maxFileSizeAllowedMB?: number;
    title?: string;
    message?: ReactNode;
    showUploadList?: boolean;
    fileList?: File[];
    type?: 'button' | 'dragger';
    disabled?: boolean;
    showDownload?: boolean;
    showPreview?: boolean;
};

export default function UploadDoc(props: Props) {
    const {
        onSelect,
        multiSelect,
        title,
        message,
        allowedExtensions = ['pdf'],
        maxFileSizeAllowedMB,
        showUploadList = true,
        type = 'button',
        fileList,
        disabled,
        showDownload,
        showPreview
    } = props;

    const { Title, Text } = Typography;

    const maxFileSizeAllowedMB_ = maxFileSizeAllowedMB ?? 10;
    const [fileList_, setFileList] = useState<UploadFile[]>([]);
    const [error, setError] = useState(false);
    const [displayUploadButton, setDisplayUploadButton] = useState(true);
    const restrictedFileTypes = ['application/x-executable'];

    const isExtensionAllowed = (extension: string) => {
        return (
            allowedExtensions.includes('*') ||
            allowedExtensions.some((a) => a.toLowerCase() === extension.toLowerCase())
        );
    };

    const allowedFileTypes = allowedExtensions.map((key: string) => mime.getType(key));

    useEffect(() => {
        if (fileList) {
            setFileList(fileList as RcFile[]);
            onSelect(fileList as unknown as UploadFile[]);
        }
        setDisplayUploadButton(!fileList?.length);
    }, [fileList]);

    const uploadProps: UploadProps = {
        onRemove: (file) => {
            setDisplayUploadButton(true);
            setFileList((fileList) => fileList.filter((file_) => file_.uid !== file.uid));
        },

        onDownload: (file) => {
            downloadDocumentBlob(file as RcFile, file.name);
        },

        onPreview: (file) => {
            downloadDocumentBlob(file as RcFile, file.name, true);
        },

        beforeUpload: (file) => {
            const fileUploadable =
                (allowedFileTypes.includes(file.type) ||
                    isExtensionAllowed(getExtension(file.name))) &&
                file.size / (1024 * 1024) < maxFileSizeAllowedMB_ &&
                !restrictedFileTypes.includes(mime.getType(file.name) ?? '');

            if (fileUploadable) {
                setError(false);
                if (!multiSelect) {
                    setFileList([file]);
                    setDisplayUploadButton(false);
                } else {
                    setFileList((fileList) => [file, ...fileList]);
                }
            } else {
                setDisplayUploadButton(true);
                setError(true);
            }
            return false;
        },

        onChange: (info) => {
            onSelect(info.fileList.length ? fileList_ : []);
        },

        itemRender: (_, file, __, actions) => {
            return (
                <Row align='middle'>
                    <Col span={1} style={{ color: ThemeColor.SecondaryGrey }}>
                        <FileIcon fileName={file.name} />
                    </Col>
                    <Col span={23}>
                        <Row justify='space-between' align='middle' wrap={false}>
                            <Col span={16}>
                                <Text ellipsis>{file.name}</Text>
                            </Col>
                            <Col span={8}>
                                <Flex justify='flex-end'>
                                    <Space>
                                        <Button
                                            type='text'
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={actions.remove}
                                            style={{ display: 'inline' }}
                                            disabled={disabled}
                                        />
                                        {showDownload && (
                                            <Button
                                                type='text'
                                                icon={<DownloadOutlined />}
                                                title='Download'
                                                onClick={actions.download}
                                                style={{ display: 'inline' }}
                                            />
                                        )}
                                        {showPreview && (
                                            <Button
                                                type='text'
                                                icon={<ExportOutlined />}
                                                title='Preview'
                                                onClick={actions.preview}
                                                style={{ display: 'inline' }}
                                            />
                                        )}
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        },
        fileList: fileList_,
        showUploadList,
        multiple: multiSelect,
        disabled
    };

    const defaultMessage = `Only ${allowedExtensions
        .join(', ')
        .toUpperCase()} file types are allowed with a maximum size of ${maxFileSizeAllowedMB_} MB.`;

    return (
        <div>
            {type === 'dragger' ? (
                <Dragger
                    {...uploadProps}
                    style={{
                        margin: '8px 0',
                        padding: '2px',
                        display: !displayUploadButton ? 'none' : undefined
                    }}
                    prefixCls='upload-dragger__container'
                >
                    {displayUploadButton && (
                        <Space
                            direction='vertical'
                            size={1}
                            className={disabled ? 'upload--disabled' : ''}
                        >
                            <InboxOutlined
                                style={{ fontSize: 36, color: ThemeColor.SecondaryGrey }}
                            />
                            <Title level={5}>{`${
                                title ?? 'Click or drag files here to upload'
                            }`}</Title>
                            <Text type={error ? 'danger' : 'secondary'}>
                                {message ?? defaultMessage}
                            </Text>
                        </Space>
                    )}
                </Dragger>
            ) : (
                <Upload {...uploadProps}>
                    {displayUploadButton && (
                        <>
                            <Button
                                style={{ width: '100%' }}
                                icon={<UploadOutlined />}
                                disabled={disabled}
                            >
                                Upload
                            </Button>
                            <Text
                                type={error ? 'danger' : 'secondary'}
                                className={disabled ? 'upload--disabled' : ''}
                            >
                                {message ?? defaultMessage}
                            </Text>
                        </>
                    )}
                </Upload>
            )}
        </div>
    );
}
